import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { ProdSaleNoteComponent } from '../../plugin/saleNote/prodSaleNote.component';
import { ComponentManagerService } from './../../services/component-manager.service';
import { ContainerMaster } from './../../shared/enums/container.master';
import { SeoMaster } from './../../shared/enums/seo.master';
import { BaseContainer } from './../base.container';


declare var Instamojo: any;
declare var $: any;
declare var window: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent extends BaseContainer implements OnInit, AfterViewInit {

  constructor(@Inject(PLATFORM_ID) private platformId: Object, meta: Meta,
    title: Title,
    componentManagerService: ComponentManagerService, public formBuilder: FormBuilder, @Inject(PLATFORM_ID) private platformID: Object,
  ) {
    super(meta, title, componentManagerService);
    this.offlineSales = { ID: '', prodQty: 1, PAYMENT_MODE: '', DELIVERY_MODE: '' };
    this.validateBillingForm();
    this.createAddForm();

  }
  @Output() instamojoEmit = new EventEmitter();
  itemArr = this.componentManagerService.cartArr;
  itemLength = this.itemArr.length;
  status: any;
  offlineSales: any;
  delivery = false;
  deliveryStatus = 0;
  showMesg = false;
  order = [
    {
      product: 'bag', quantity: '1', total: '100'
      // product: 'bag', quantity: '1', total: '100' , totalquantity: '1', totalprice: '100'
    }
  ];
  isBrowser = isPlatformBrowser(this.platformID);
  stateList = [
    { stateCode: 'Andhra Pradesh', stateName: 'Andhra Pradesh' },
    { stateCode: 'Arunachal Pradesh', stateName: 'Arunachal Pradesh' },
    { stateCode: 'Assam', stateName: 'Assam' },
    { stateCode: 'Bihar', stateName: 'Bihar' },
    { stateCode: 'Chhattisgarh', stateName: 'Chhattisgarh' },
    { stateCode: 'Chandigarh', stateName: 'Chandigarh' },
    { stateCode: 'Dadra and Nagar Haveli', stateName: 'Dadra and Nagar Haveli' },
    { stateCode: 'Daman and Diu', stateName: 'Daman and Diu' },
    { stateCode: 'Delhi', stateName: 'Delhi' },
    { stateCode: 'Goa', stateName: 'Goa' },
    { stateCode: 'Gujarat', stateName: 'Gujarat' },
    { stateCode: 'Haryana', stateName: 'Haryana' },
    { stateCode: 'Himachal Pradesh', stateName: 'Himachal Pradesh' },
    { stateCode: 'Jammu and Kashmir', stateName: 'Jammu and Kashmir' },
    { stateCode: 'Jharkhand', stateName: 'Jharkhand' },
    { stateCode: 'Karnataka', stateName: 'Karnataka' },
    { stateCode: 'Kerala', stateName: 'Kerala' },
    { stateCode: 'Madhya Pradesh', stateName: 'Madhya Pradesh' },
    { stateCode: 'Maharashtra', stateName: 'Maharashtra' },
    { stateCode: 'Manipur', stateName: 'Manipur' },
    { stateCode: 'Meghalaya', stateName: 'Meghalaya' },
    { stateCode: 'Mizoram', stateName: 'Mizoram' },
    { stateCode: 'Nagaland', stateName: 'Nagaland' },
    { stateCode: 'Orissa', stateName: 'Orissa' },
    { stateCode: 'Punjab', stateName: 'Punjab' },
    { stateCode: 'Pondicherry', stateName: 'Pondicherry' },
    { stateCode: 'Rajasthan', stateName: 'Rajasthan' },
    { stateCode: 'Sikkim', stateName: 'Sikkim' },
    { stateCode: 'Tamil Nadu', stateName: 'Tamil Nadu' },
    { stateCode: 'Telangana', stateName: 'Telangana' },
    { stateCode: 'Tripura', stateName: 'Tripura' },
    { stateCode: 'Uttar Pradesh', stateName: 'Uttar Pradesh' },
    { stateCode: 'Uttarakhand', stateName: 'Uttarakhand' },
    { stateCode: 'West Bengal', stateName: 'West Bengal' }
  ];
  CountryList: any[] = [
    { countryName: 'India', countryCode: 'IN' }
  ];

  addressForm: FormGroup;
  bill = {
    ACTIVE: 1, B_ADDRESS1: '', B_ADDRESS2: '', B_CITY: '', B_PINCODE: '', B_SAME_S: '', B_STATE: '', B_COUNTRY: '',
    S_ADDRESS1: '', S_ADDRESS2: '', S_CITY: '', S_PINCODE: '', S_STATE: '', S_COUNTRY: '', COMPANY: '', CONTACT2: '',
    EMAIL: '', FULLNAME: '', GST_NO: '', LOGIN_TYPE: 'EMAIL', MOBILE: '', OTHER_EMAILS: '', PASSCODE: '',
  };
  seal = {
    company: '', address: '', city: ''
  };
  certificate = {
    company: '', CIN: '', address: '', city: '', pincode: '', stateName: '',
    shareType: '', faceValue: '', shareCertiDateStart: '', shareCertiDateEnd: ''
  };

  billingForm: FormGroup;
  register: any;
  isReadOnly: boolean;
  certificateForm: FormGroup;
  sealForm: FormGroup;
  quantity: any;
  price: any;
  showDeliveryBtns = false;
  formArr = [];
  deliveryCharges = 0;
  @ViewChildren(ProdSaleNoteComponent) prodSaleComp !: QueryList<ProdSaleNoteComponent>;
  setMetaTags(): any[] {
    return ([]);
  }

  ngOnInit() {
    this.createAddForm();
    this.validateCertificateForm();
    this.validateSealForm();
    this.showCertificate();
    this.showDelivery();
    for (const t of this.componentManagerService.cartArr) {
      if (t['SUBGROUP'] === 'Share Certificate' || t['SUBGROUP'] === 'Seal' || t['GROUP_TYPE'] === 'AMC' || t['SUB_PROD_CONFIG_DISP'] === 'PROD_SUB_YMM') {
        for (let i = 0; i < t.QTY; i++) {
          this.formArr.push(t);
        }
      }
    }
  }

  ngAfterViewInit() {
    /* Configuring Handlers */
    Instamojo.configure({
      handlers: {
        onOpen: this.onOpenHandler,
        onClose: this.onCloseHandler,
        onSuccess: this.onPaymentSuccessHandler,
        onFailure: this.onPaymentFailureHandler
      }
    });
  }

  // onOpenHandler() {
  //   console.log("Payments Modal is Opened");
  // }

  // onCloseHandler() {
  //   console.log("Payments Modal is Closed");
  // }

  setId() {
    return ContainerMaster.CHECKOUT;
  }
  setMetaTitle() {
    return SeoMaster.CHECKOUT.title;
  }

  actionFromPlugin(params) { }

  validateBillingForm() {
    this.billingForm = this.formBuilder.group({
      fullname: ['', [Validators.required]],
      company: ['', [Validators.required]],
      phoneNo: ['', [Validators.required, Validators.minLength(10)]],
      contact2: ['', [Validators.minLength(10),
      Validators.maxLength(15)]],

      email: ['', [Validators.required, Validators.email, Validators.maxLength(90)]],
      gst: ['', [
        Validators.maxLength(15),
        Validators.minLength(15),
        Validators.pattern('^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$')
      ]]
    });
  }
  copyBillingDetails(events) {
    if (events.target.checked) {
      this.bill.S_ADDRESS1 = this.bill.B_ADDRESS1;
      this.bill.S_ADDRESS2 = this.bill.B_ADDRESS2;
      this.bill.S_CITY = this.bill.B_CITY;
      this.bill.S_COUNTRY = this.bill.B_COUNTRY;
      this.bill.S_PINCODE = this.bill.B_PINCODE;
      this.bill.S_STATE = this.bill.B_STATE;

    }
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  createAddForm() {
    this.addressForm = this.formBuilder.group({
      bAddr1: ['', [Validators.required, Validators.maxLength(250)]],
      bAddr2: ['', [Validators.maxLength(250)]],
      bCity: ['', [Validators.required, Validators.maxLength(100)]],
      bPincode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      bState: ['', [Validators.required, Validators.maxLength(100)]],
      bCntry: ['', [Validators.required, Validators.maxLength(100)]],
      sameAddress: [null],
      deliveryAddr1: ['', [Validators.required, Validators.maxLength(250)]],
      deliveryAddr2: ['', [Validators.maxLength(250)]],
      deliveryCity: ['', [Validators.required, Validators.maxLength(100)]],
      deliveryState: ['', [Validators.required, Validators.maxLength(100)]],
      deliveryCountry: ['', [Validators.required, Validators.maxLength(100)]],
      deliveryPincode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      DeliveryMode: ['', []]
    });
  }
  validateCertificateForm() {
    this.certificateForm = this.formBuilder.group({
      cName: [null, [Validators.required]],
      cin: [null, [Validators.required]],
      offAddr: [null, [Validators.required]],
      addr: [null, [Validators.required]],
      city: [null, [Validators.required]],
      pincode: [null, [Validators.required]],
      state: [null, [Validators.required]],
      share: [null, [Validators.required]],
      faceValue: [null, [Validators.required]],
      certiStartdate: [null, [Validators.required]],
      certiEndDate: [null, [Validators.required]],
    });
  }
  validateSealForm() {
    this.sealForm = this.formBuilder.group({
      cName: ['', [Validators.required]],
      regisAddrs: ['', [Validators.required]],
      offAddr: ['', [Validators.required]],
    });
  }
  showCertificate() {
    let sealexist = false;
    let sharecerificateexist = false;
    let sealsharecerificateexist = false;

    for (let i = 0; i < this.itemLength; i++) {
      if (this.itemArr[i]['SUBGROUPS'] !== null) {
        if (this.itemArr[i]['SUBGROUPS'].toUpperCase() === 'SEAL') {
          sealexist = true;
        }
        if (this.itemArr[i]['SUBGROUPS'].toUpperCase() === 'SHARE CERTIFICATE') {
          sharecerificateexist = true;
        }
        if (this.itemArr[i]['SUBGROUPS'].toUpperCase() === 'PACKAGE') {
          sealsharecerificateexist = true;
        }


        if (sealexist && sharecerificateexist) {
          this.status = 3;
        } else if (sealexist) {
          this.status = 1;
        } else if (sharecerificateexist) {
          this.status = 2;
        } else if (sealsharecerificateexist) {
          this.status = 4;
        } else {
          this.status = 0;
        }
      }
    }
  }

  onSubmit() {
    let isValid = true;
    this.prodSaleComp['_results'].forEach(e => {
      if (!e.prodFormGroup.valid) {
        isValid = false;
        this.validateAllFormFields(e.prodFormGroup);
      }
    });
    if (isValid) {
      if (this.billingForm.valid && this.addressForm.valid) {
        let amcData = [], sealData = [], certificateData = [];
        if (this.prodSaleComp['_results'].length > 0) {
          for (const p of this.prodSaleComp['_results']) {
            if (p.productData.GROUP_TYPE === 'AMC' || p.productData.SUB_PROD_CONFIG_DISP === 'PROD_SUB_YMM') {
              amcData.push(p.amc);
            } else if (p.productData.SUBGROUP === 'Seal') {
              sealData.push(p.seal);
            } else if (p.productData.SUBGROUP === 'Share Certificate') {
              certificateData.push(p.certificate);
            }
          }
        }

        for (let i = 0; i < this.itemArr.length; i++) {
          if (this.itemArr[i].GROUP_TYPE === 'AMC' || this.itemArr[i].SUB_PROD_CONFIG_DISP === 'PROD_SUB_YMM') {
            this.itemArr[i]['prodDetailNote'] = amcData;
          } else if (this.itemArr[i].SUBGROUP === 'Seal') {
            this.itemArr[i]['prodDetailNote'] = sealData;
          } else if (this.itemArr[i].SUBGROUP === 'Share Certificate') {
            this.itemArr[i]['prodDetailNote'] = certificateData;
          }
        }
        const Cart = {
          CART_AMOUNT: this.componentManagerService.subTotal, CUSTOMER_DETAILS: this.bill, CUSTOMER_DISCOUNT: 0,
          DELIVERY_MODE: this.offlineSales.DELIVERY_MODE,
          ITEMS: this.itemArr, ORDER_DATE: null, TRAN_MODE: '', USER_ID: '',
          certificate: this.certificate, seal: this.seal, checkout: 1
        };

        this.callDataJunction('CHECK_OUT', Cart).subscribe(res => {
          if (!!res && res.status) {
            Instamojo.open(res.data.pgURL);
            this.setPayID(res.data);
          } else {
            alert('Error in Checkout. Please try again.1');
          }
        });
      } else {
        this.validateAllFormFields(this.billingForm);
        this.validateAllFormFields(this.addressForm);
      }
    }

  }
  setPayID(event) {
    window['transID'] = event.SALE_ID;
    window['paymentID'] = event.paymentID;
  }
  isFieldValid(field: string) {
    return !this.billingForm.get(field).valid && this.billingForm.get(field).touched;
  }
  isFormFieldValid(field: string) {
    return !this.addressForm.get(field).valid && this.addressForm.get(field).touched;
  }
  isFieldValid2(field: string) {
    return !this.certificateForm.get(field).valid && this.certificateForm.get(field).touched;
  }
  isFieldValid1(field: string) {
    return !this.sealForm.get(field).valid && this.sealForm.get(field).touched;
  }

  onOpenHandler() {
    console.log('Payments Modal is Opened');
  }

  onCloseHandler() {
    console.log('Payments Modal is Closed');
  }

  onPaymentSuccessHandler(response) {
    $.ajax({
      type: 'POST',
      cache: false,
      url: 'api/purchasePaymentResult',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({
        transID: window['transID'], pgPaymentID: response.paymentId, pgPayClientRes: response,
        paymentID: window['paymentID']
      }),
      success: function () {
        // window['transID'] = undefined;
        // window['paymentID'] = undefined;
        // if (window['that'].isBrowser) {
        //   $('.modal-backdrop').css({ 'display': 'none' });
        // }
        // window['that'].navigateTo('thankyou', window['transID']);
        let transID = window['transID'];
        // window['transID'] = undefined;
        // window['paymentID'] = undefined;
        if (window['that'].isBrowser) {
          $('.modal-backdrop').css({ 'display': 'none' });
        }
        window['that'].navigateTo('thankyou', transID);
      }, error: function () {
        alert('There are some error with system. Please contact support team');
      }
    });

    if (response.status.toLowerCase() === 'success') {
    } else {
      alert('payment failed');
    }
    // this.processPayment(response.paymentId||undefined, JSON.stringify(response));
  }

  onPaymentFailureHandler(error) {
    console.log('Payment Failure Response', error);
    alert('Error in payment');
  }
  radioChange(event) {
    // if (item['DELIVERY_MODE'] === 'COURIER') {
    //   if (this.userInfo.B_STATE.toUpperCase() === 'MAHARASHTRA' && this.userInfo.S_CITY.toUpperCase() === 'MUMBAI') {
    //     this.deliveryAmt += 200;
    //   } else {
    //     this.deliveryAmt += 300;
    //   }
    //   this.deliveryGstAmt += this.deliveryAmt * (18 / 100);
    // }
    // if (this.addressForm.valid) {
    if (event.target.value === 'IN_HAND') {
      this.delivery = false;
      this.showMesg = true;
      this.deliveryStatus = -1;
      this.componentManagerService.subTotal -= this.deliveryCharges;
      this.deliveryCharges = 0;
    } else {
      this.delivery = true;
      const stationeryProds = this.componentManagerService.cartArr.filter(x => x.PTYPE === 'STATIONERY');
      if (stationeryProds.length > 0) {
        const deliveryAmt = environment.deliveryamt;
        // if (this.addressForm.value.bState.toUpperCase() === 'MAHARASHTRA' &&
        // this.addressForm.value.deliveryCity.toUpperCase() === 'MUMBAI') {
        //   deliveryAmt = 200 * stationeryProds.length;
        // } else {
        //   deliveryAmt = 300 * stationeryProds.length;
        // }
        this.deliveryCharges = deliveryAmt + (deliveryAmt * (18 / 100));
        console.log('this.deliveryCharges', this.deliveryCharges);
        this.componentManagerService.subTotal += this.deliveryCharges;
      }
    }
    // } else {
    //   this.validateAllFormFields(this.addressForm);
    // }


  }
  showDelivery() {
    if (this.itemArr.filter(x => x.PTYPE === 'STATIONERY').length > 0) {
      this.showDeliveryBtns = true;
    }

  }

}

