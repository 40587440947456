//#region Import Calls
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './container/aboutus/aboutus.component';
import { AdminLoginComponent } from './container/admin/admin-login/admin-login.component';
import { BiometricMachineComponent } from './container/biometricmachine/biometricmachine.component';
import { CheckoutComponent } from './container/checkout/checkout.component';
import { CloudBasedPayrollComponent } from './container/cloud-based-payroll/cloud-based-payroll-component';
import { CompanyActComponent } from './container/companyact/companyact.component';
import { ShareCertificatesComponent } from './container/share-certificate/share-certificate.component';
import { StatutoryRegisterComponent } from './container/statutory-register/statutory-register.component';
import { CompanySealComponent } from './container/company-seal/company-seal.component';
import { MinutesBinderComponent } from './container/minutes-binder/minutes-binder.component';
import { MinutesPaperLedgerComponent } from './container/minutes-paper-ledger/minutes-paper-ledger.component';
import { MinutesPaperWhiteComponent } from './container/minutes-paper-white/minutes-paper-white.component';
import { CompanySecretaryComponent } from './container/companysecretary/companysecretary.component';
import { ContactUsComponent } from './container/contactus/contactus.component';
import { DesktopBasedPayrollComponent } from './container/desktop-based-payroll/desktop-based-payroll.component';
import { DigitalSignatureComponent } from './container/digitalsignature/digitalsignature.component';
import { EtdswizardComponent } from './container/etdswizard/etdswizard.component';
import { FixedAssetComponent } from './container/fixedasset/fixedasset.component';
import { HomeComponent } from './container/home/home.component';
import { HrSlipComponent } from './container/hrslip/hrslip.component';
// import { ItreturnComponent } from './container/itreturn/itreturn.component';
import { PaymentSuccessComponent } from './container/paymentsuccess/paymentsucess.component';
import { PdfSignerComponent } from './container/pdfsigner/pdfsigner.component';
import { PfexpressComponent } from './container/pfexpress/pfexpress.component';
import { ProactlyComponent } from './container/proactly/proactly.component';
import { SharecertificateComponent } from './container/sharecertificate/sharecertificate.component';
import { TdsManagementComponent } from './container/tdsmanagement/tdsmanagement.component';
import { ThankyouComponent } from './container/thankyou/thankyou.component';
import { ViewCartComponent } from './container/viewCart/viewCart.component';
import { WebFixedAssetComponent } from './container/webfixedasset/webfixedasset.component';
import { WhyTaxPrintComponent } from './container/whytaxprint/whytaxprint.component';
import { XbrlComponent } from './container/xbrl/xbrl.component';
import { ChangePasswordComponent } from './plugin/changePassword/changePassword.component';
import { EmailVerifyComponent } from './plugin/email/email-verify.component';
import { ForgetPasswordComponent } from './plugin/forget-password/forget-password.component';
import { EtdsNextComponent } from './container/etdsnext/etdsnext.component';
import { NotfoundComponent } from './container/notfound/notfound.component';
import { SiteComponent } from './container/sitemap/sitemap.component';
import { AsreconcilerComponent } from './container/asreconciler/asreconciler.component';
//#endregion

//#region Route Constants
const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'tdsmanagement', component: TdsManagementComponent },
  { path: 'etdswizard', component: EtdswizardComponent },
  { path: 'proactly', component: ProactlyComponent },
  // { path: 'itreturn', component: ItreturnComponent },
  { path: '26as', component: AsreconcilerComponent },  
  { path: 'xbrl', component: XbrlComponent },
  { path: 'companysecretary', component: CompanySecretaryComponent },
  { path: 'fixedasset', component: FixedAssetComponent },
  { path: 'pdfsigner', component: PdfSignerComponent },
  // { path: 'gstsoftware', redirectTo: 'home' },
  { path: 'webfixedasset', component: WebFixedAssetComponent },
  { path: 'pfexpress', component: PfexpressComponent },
  { path: 'biometricmachine', component: BiometricMachineComponent },
  { path: 'companyact', component: CompanyActComponent },
  { path: 'sharecertificates', component: ShareCertificatesComponent },
  { path: 'statutoryregister', component: StatutoryRegisterComponent },
  { path: 'companyseal', component: CompanySealComponent },
  { path: 'minutesbinder', component: MinutesBinderComponent },
  { path: 'minutespaperledger', component: MinutesPaperLedgerComponent }, 
  { path: 'minutespaperwhite', component: MinutesPaperWhiteComponent }, 
  { path: 'digitalsignature', component: DigitalSignatureComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'thankyou/:saleID', component: ThankyouComponent },
  { path: 'paymentsuccess', component: PaymentSuccessComponent },
  { path: 'adminlogin', component: AdminLoginComponent },
  { path: 'transactional/confirm-your-taxprint-account', component: EmailVerifyComponent },
  { path: 'transactional/forgetPwd', component: ForgetPasswordComponent },
  { path: 'transactional/fp', component: ForgetPasswordComponent },
  { path: 'chgpassword', component: ChangePasswordComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'viewcart', component: ViewCartComponent },
  { path: 'admin', loadChildren: './container/admin/admin.module#AdminModule' },
  { path: 'payroll-hr-software', component: HrSlipComponent },
  { path: 'desktoppayroll', component: DesktopBasedPayrollComponent },
  { path: 'cloudpayroll', component: CloudBasedPayrollComponent },
  { path: 'why-taxprint', component: WhyTaxPrintComponent },
  { path: 'share', component: SharecertificateComponent },
  { path: 'user', loadChildren: './container/user-dashboard/user-dashboard.module#UserDashboardModule' },
  { path: 'etdsnext', component: EtdsNextComponent },
  { path: 'notfound', component: NotfoundComponent},
  { path: 'sitemap', component: SiteComponent},
  { path: '**', redirectTo: '/notfound' },

];
//#endregion

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
