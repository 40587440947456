export let SeoMaster = {
  ABOUT_US: {
    title: "About Us - Tax Print",
    keyword:
      "Tax services online,payroll software,tds software,GST software,PF software,PDF signer software,XBRL filing software,fixed asset management software,Web based payroll software",
    description:
      "Tax Print is a one stop shop of tax-related financial products and services. We provide full range of tax solutions at affordable prices in India. Contact Us.",
    ogtitle: "About Us - Tax Print",
    canonical: "https://www.taxprint.com/about-us",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Tax Print is one-stop shop in the field of providing quality tax-related products and services.We offers  Attendance, TDS, ETDS, Tax, ITR Filling and all online Payroll software services.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "Tax Related Products|Tax Services Online|Taxprint",
    twitterdescription:
      "Tax Print is one-stop shop in the field of providing quality tax-related products and services.We offers  Attendance, TDS, ETDS, Tax, ITR Filling and all online Payroll software services.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  BIOMETRIC: {
    title:
      "Biometric Machine, Biometric Machine Software, Biometric Attendance",
    keyword:
      "Biometric Machine, biometric machine software, Biometric Attendance System, biometric attendance machine software, attendance software for biometric machine, biometric attendance software",
    description:
      "Biometric Machine manages Employees Attendance, Late coming, Early going, Shifts, In/Out time with ease.It is available with access control facility.",
    ogtitle:
      "Biometric Machine, Biometric Machine Software, Biometric Attendance",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Biometric Machine manages Employees Attendance, Late coming, Early going, Shifts, In/Out time with ease.It is available with access control facility.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle:
      "Biometric Machine, Biometric Machine Software, Biometric Attendance",
    twitterdescription:
      "Biometric Machine manages Employees Attendance, Late coming, Early going, Shifts, In/Out time with ease.It is available with access control facility.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  COMPANY_ACT: {
    title: "Tax Print - Companies Act 2013 Stationery ",
    keyword: "Companies Act 2013 Stationery, ",
    description:
      "Tax Print- Directors & Key Managerial Personnel and their Shareholdings Contracts with a related party, Contracts & Bodies, etc. which interest directors.",
    ogtitle: "Companies Act 2013 Stationery",
    canonical: "https://www.taxprint.com/companyact",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "Companies Act 2013 Stationery",
    twitterdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  COMPANY_SEC: {
    title:
      "Company Secretary Automation Software|Cloud based CS Software|Taxprint",
    keyword:
      "Company Secretary Automation Software, best company secretarial software india, company secretary software india, best company secretarial software india, Best Company Secretarial Software",
    description:
      "Cloud-based Company Secretary Automation Software concerning Secretarial functionsand an auto arrangement of Secretarial Documents including Notices, Agenda",
    ogtitle:
      "Company Secretary Automation Software|Cloud based CS Software|Taxprint",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Cloud-based Company Secretary Automation Software concerning Secretarial functionsand an auto arrangement of Secretarial Documents including Notices, Agenda",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle:
      "Company Secretary Automation Software|Cloud based CS Software|Taxprint",
    twitterdescription:
      "Cloud-based Company Secretary Automation Software concerning Secretarial functionsand an auto arrangement of Secretarial Documents including Notices, Agenda",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  CONTACT_US: {
    title: "",
    keyword: "",
    description: "",
    ogtitle: "",
    canonical: "https://www.taxprint.com/contact-us",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription: "",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "",
    twitterdescription: "",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  DSC: {
    title: "Digital Signature Application Form, Digital Signature Online ",
    keyword:
      "Digital Signature Application Form, Digital Signature Online Application Form",
    description:
      "Digital Signature application from contains all the details of the applicant required for applying digital signature.",
    ogtitle: "Digital Signature Application Form, Digital Signature Online ",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Digital Signature application from contains all the details of the applicant required for applying digital signature.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle:
      "Digital Signature Application Form, Digital Signature Online ",
    twitterdescription:
      "Digital Signature application from contains all the details of the applicant required for applying digital signature.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  ETDS: {
    title:
      "Tax Print - Affordable TDS Software in Mumbai | e-TDS Return",
    keyword:
      "etds software,etds payment, etds payment online, etds return software, etds return filing online, etds challan payment, etds software, etds wizard ",
    description:
      "Tax Print : e-TDS Software is specifically designed for filing Quarterly eTDS Returns as per the NSDL format. This software renders easy-to-use Excel sheets.",
    ogtitle:
      "e-TDS Software in Mumbai | e-TDS Return",
    canonical: "https://www.taxprint.com/etdswizard",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "e-TDS Software is specifically designed for filing Quarterly eTDS Returns as per the NSDL format. This software renders easy-to-use Excel sheets.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle:
      "e-TDS Software in Mumbai | e-TDS Return",
    twitterdescription:
      "e-TDS Software is specifically designed for filing Quarterly eTDS Returns as per the NSDL format. This software renders easy-to-use Excel sheets.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  FIXEDASSET: {
    title: "Efficient Fixed Asset Management Software | Tax Print",
    keyword:
      "Fixed Asset Management Software, fixed asset management software India, Best Fixed Asset Software, Asset Management System, Fixed assets software, Fixed assets software Mumbai, Fixed assets software India, Fixed asset software, Fixed asset software Mumbai, Fixed asset software India, Web-based fixed assets software. , Online fixed assets software,  Fixed assets software on cloud",
    description:
      "Optimize asset management with Tax Print. Track, calculate depreciation easily. Streamline fixed asset processes for better financial control, Contact Now !",
    ogtitle: "Fixed Asset Management Software In India",
    canonical: "https://www.taxprint.com/fixedasset",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Fixed Asset Management Software is designed to Track &amp; Manage Fixed Assets",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle:
      "Fixed Asset Management Software In India",
    twitterdescription:
      "Fixed Asset Management Software is designed to Track &amp; Manage Fixed Assets",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  GST_SOFTWARE: {
    title: "GST Software|GST Ready Accounting Software|Taxprint",
    keyword:
      "GST software,GST ready accounting software,best GST software,GST billing software",
    description: `Taxprint's GST software can  highly integrated with any accounting software or ERP is possible with in-built module.or ERP is possible with in-built module.`,
    ogtitle: "GST Software|GST Ready Accounting Software|Taxprint",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription: `Taxprint's GST software can  highly integrated with any accounting software or ERP is possible with in-built module.or ERP is possible with in-built module.`,
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "GST Software|GST Ready Accounting Software|Taxprint",
    twitterdescription: `Taxprint's GST software can  highly integrated with any accounting software or ERP is possible with in-built module.or ERP is possible with in-built module.`,
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  HOME: {
    title:
      "Best HRMS & Payroll Management Software  in India |  Tax Print ",
    keyword:
      "Payroll software,Payroll services online,Payroll services online,TDS management services,ETDS services,GST software,PDF signer,PF software,Digital signature services",
    description:
      "Tax Print : Simplify HR & Payroll system in India!  Discover top-rated  best HRMS and Payroll software for taxes, compliance & employee management. ",
    ogtitle:
      "Tax Print - Best Compliance Management Software",
    ogtype: "website",
    canonical: "https://www.taxprint.com/",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Taxprint is one of the leading online payroll software company .It provides services like,TDS,ETDS,ITR,PF,attendance and other payroll services online.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "Payroll Software and Services Online in India|Taxprint",
    twitterdescription:
      "Taxprint is one of the leading online payroll software company .It provides services like,TDS,ETDS,ITR,PF,attendance and other payroll services online.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  ITRETURN: {
    title: "Tax Print :  Income Tax Return e-filing software  in Mumbai",
    keyword: `IT Return e-Filing Software, IT return e-filing, e-filing of income tax, India's top ITR software, e-Filing Income Tax Software, 26as reconciler,26as reconciler software`,
    description:
      "Tax Print :  Simple, flexible & user-friendly Income Tax Return e-filing software that takes care of all your requirements relating to Income Tax Return e-Filing.",
    ogtitle:
      "E-filing of Income Tax  in Mumbai",
    canonical: "https://www.taxprint.com/itreturn",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Very simple, flexible and user-friendly Income Tax Return efiling software that takes care of all your requirements relating to Income Tax Return eFiling.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle:
      "E-filing of Income Tax  in Mumbai",
    twitterdescription:
      "Very simple, flexible and user-friendly Income Tax Return efiling software that takes care of all your requirements relating to Income Tax Return eFiling.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  PAYROLL: {
    title:
      "Payroll Management Software|Payroll Software|Payroll Management|Taxprint",
    keyword:
      "Payroll Management Software, payroll software, Payroll Management, Payroll management system, income tax management, income tax management india, payroll calculator, payroll software india",
    description:
      "EasyPAY is easy, adaptable and easy to use Payroll Management programming that deals with all your requirements relating to accounting and management..",
    ogtitle:
      "Payroll Management Software|Payroll Software|Payroll Management|Taxprint",
    canonical: "https://www.taxprint.com/payroll-hr-software",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "EasyPAY is easy, adaptable and easy to use Payroll Management programming that deals with all your requirements relating to accounting and management..",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle:
      "Payroll Management Software|Payroll Software|Payroll Management|Taxprint",
    twitterdescription:
      "EasyPAY is easy, adaptable and easy to use Payroll Management programming that deals with all your requirements relating to accounting and management..",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  PDF_SIGNER: {
    title: "Tax Print | Affordable PDF Signer Software In India",
    keyword:
      "PDF Signer, pdf signer software, PDF Digital Signature, pdf signer software India, Pdf signer, Pdf signer Mumbai, Pdf signer India, Pdf signer Software, Digital signer, Digital signer software, Pdf digital signer",
    description:
      "Tax Print-PDF Signer Software inserts digital signatures in PDF Form 16, Form 16A, and other PDF documents.it is user-friendly, reliable and legally compliant.",
    ogtitle: "PDF Signer Software In India",
    canonical: "https://www.taxprint.com/pdfsigner",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "PDF Signer Software inserts digital signatures in PDF Form 16, Form 16A, and other PDF documents.it is user-friendly, reliable and legally compliant.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "PDF Signer Software In India",
    twitterdescription:
      "PDF Signer Software inserts digital signatures in PDF Form 16, Form 16A, and other PDF documents.it is user-friendly, reliable and legally compliant.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  PFEXPRESS: {
    title: "PF Express|Provident Fund Software|PF Management Software|Taxprint",
    keyword:
      "PF Express, provident fund software, Provident Fund Management Software,pf software in india, pf software",
    description:
      "PF Express is very easy, flexible and user-friendly PF Electronic Challan cum Return filing software that takes care of all your requirements relating to EPF.",
    ogtitle:
      "PF Express|Provident Fund Software|PF Management Software|Taxprint",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "PF Express is very easy, flexible and user-friendly PF Electronic Challan cum Return filing software that takes care of all your requirements relating to EPF.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle:
      "PF Express|Provident Fund Software|PF Management Software|Taxprint",
    twitterdescription:
      "PF Express is very easy, flexible and user-friendly PF Electronic Challan cum Return filing software that takes care of all your requirements relating to EPF.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  TDS_MNGMNT: {
    title:
      "Tax Print : Best TDS Management Software In India",
    keyword:
      "tds software, tds software in India, TDS payment, TDS payment online, TDS payment challan, easy tds software, easy tds payment, TDS management software, TDS management, TDS management services",
    description:
      "Tax Print : Simplify TDS calculation with our software: Auto updates, multi-head computation. Manage Tax, TDS on Salary & more effortlessly. Contact us Now!",
    ogtitle:
      "Best TDS Management Software In India",
    canonical: "https://www.taxprint.com/tdsmanagement",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "TDS Management software assists in computing TDS under different heads of the Income-tax Act. Auto update and calculation for Tax, TDS on Salary & Others",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle:
      "Best TDS Management Software In India",
    twitterdescription:
      "TDS Management software assists in computing TDS under different heads of the Income-tax Act. Auto update and calculation for Tax, TDS on Salary & Others",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  WEB_FIXEDASSET: {
    title:
      "Best WEB Fixed Asset Management Software in India | Tax Print",
    keyword:
      "WEB Fixed Asset Management Software, Fixed Asset Management Software, Fixed Asset Management Software India, Best Fixed Asset Accounting Software, Best Fixed Asset Software, Fixed Asset Management System",
    description:
      "WEB Fixed Asset Management Software manages unlimited Fixed Assets- Block & Category wise classification and Cost Breakup of Asset Component.",
    ogtitle:
      "WEB Fixed Asset Management Software|Fixed Asset Management System|Taxprint",
    canonical: "https://www.taxprint.com/webfixedasset",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "WEB Fixed Asset Management Softare manages unlimited Fixed Assets.It contains asset Block & Category wise classification and Cost Breakup of Asset Component.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle:
      "WEB Fixed Asset Management Software|Fixed Asset Management System|Taxprint",
    twitterdescription:
      "WEB Fixed Asset Management Softare manages unlimited Fixed Assets.It contains asset Block & Category wise classification and Cost Breakup of Asset Component.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  XBRL: {
    title: "Tax Print : Best XBRL filing  Software In India | Easy to Use",
    keyword:
      "XBRL Filing Software, Best XBRL Conversion Software, XBRL Software, best xbrl software, xbrl software, XBRL Tool India, Xbrl software, Xbrl software Mumbai, Xbrl software India, Xbrl processing",
    description:
      "Tax Print : XBRL Software in India is flexible and user-friendly. Easily handle all your XBRL filing requirements. Download the brochure to know more",
    ogtitle:
      "Best XBRL Software In India",
    canonical: "https://www.taxprint.com/xbrl",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Tax Print XBRL Software in India is flexible and user-friendly. Easily handle all your XBRL filing requirements. Download the brochure to know more",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle:
      "Best XBRL Software In India",
    twitterdescription:
      "Tax Print XBRL Software in India is flexible and user-friendly. Easily handle all your XBRL filing requirements. Download the brochure to know more",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  THANKYOU: {
    title: "Tax Print | Thank You",
  },
  ADMIN: {
    title: "Tax Print | Admin",
  },
  USER_DASHBOARD: {
    title: "Tax Print | UserDashboard",
  },
  PROACTLY: {
    title: "All in One Compliance Management System In India | Tax Print",
    keyword:
      "Compliance Management System In India, Compliance Management system, Compliance, Governance, Compliance Report, Compliance software, Compliance Management, Compliance technology, CMS software Solution in India, CMS",
    description: "Tax Print : All-in-one compliance system with timely reminders for Income-tax, GST, MCA, PTax, PF & ESIC, Click & learn more.",
    ogtitle: "Compliance Management System In India",
    canonical: "https://www.taxprint.com/proactly",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription: "The Compliance Management system provides a Built-in compliance list for Income-tax, GST, MCA, PTax, PF and ESIC. Timely reminders about data extensions",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "Compliance Management System In India",
    twitterdescription: "The Compliance Management system provides a Built-in compliance list for Income-tax, GST, MCA, PTax, PF and ESIC. Timely reminders about data extensions",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  VIEWCART: {
    title: "Tax Print | Cart",
    canonical: "https://www.taxprint.com/viewcart",
  },
  CHECKOUT: {
    title: "Tax Print | Checkout",
    canonical: "https://www.taxprint.com/checkout",
  },
  HRSLIP: {
    title: "Tax Print :HR Software And Payroll Management System",
    description:
      "Tax Print : HR Software & Management System that takes care of all your requirements relating to accounting and management of employees’ Payroll.",
    keyword:
      "best payroll software, hr software, HRMS, online hr software, online payroll software, payroll & hr software, payroll management system, payroll processing service, Payroll management software",
  },
  ETDS_NEXT: {
    title: "ETDS_NEXT | ETDS_NEXT",
  },
  DESKTOPBASEDPAYROLL: {
    title: "Tax Print | DEsktop based payroll",
  },
  CLOUDBASEDPAYROLL: {
    title: "Tax Print | Cloud based payroll",
  },
  WHYTAXPRINT: {
    title: "Why Tax Print - Tax Print",
    description:
      "With over 50 years of experience, Tax Print offers complete after-sales service support and training for software. Quality Services. 100% Money Back Guarantee!",
    ogtitle: "Why Tax Print - Tax Print",
    canonical: "https://www.taxprint.com/why-taxprint",
  },
  SHARECERT: {
    title: "Tax Print | Generate Share certificate",
    keyword: "Generate Share certificate, ",
    description:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    ogtitle: "Tax Print | Generate Share certificate",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "Tax Print | Generate Share certificate",
    twitterdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },
  ETDSNEXT: {
    title: "eTDSNEXT Software | eTDSNEXT Software Online -Tax Print",
    keyword: "5 minutes on eTdsNext Saves 5 hours later ",
    description:
      "5 minutes on eTdsNext will save 5 hours of efforts. Stop wasting time in filing correction returns, answering to default notices and queries from dedicatees.",
    ogtitle: "eTDSNEXT Software | eTDSNEXT Software Online - Tax Print ",
    ogtype: "website",
    ogurl: "https://www.taxprint.com/etdsnext",
    ogimage: "",
    ogdescription: "5 minutes on eTdsNext will save 5 hours of efforts. Stop wasting time in filing correction returns, answering to default notices and queires from deductees.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "eTDSNEXT Software | eTDSNEXT Software Online - Tax Print",
    twitterdescription: "5 minutes on eTdsNext will save 5 hours of efforts. Stop wasting time in filing correction returns, answering to default notices and queires from deductees.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
  },

  companyseal: {
    title: "Online Stamp maker | Company Seal - Common Seal | Tax Print",
    keyword: "Company Seal, Common Seal, Sleek Steel Common Seal ",
    description:
      "Tax Print : Create company seals & stamps online@ Rs. 900.00 only. Fast, secure & affordable stamp maker online.  Order your common seal stamp today!",
    ogtitle: "Companies Act 2013 Stationery",
    canonical: "https://www.taxprint.com/companyseal",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "Companies Act 2013 Stationery",
    twitterdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
    facebooktitle: "Company Seal - Sleek Steel Common Seal",
    facebookdescription: "Company Seal - Sleek Steel Common Seal @ Rs. 900.00. Inclusive of 18% GST as applicable. Weight=125 - 150 gm. Buy Now.",
    linkedintitle: "Company Seal - Sleek Steel Common Seal",
    linkedindescription: "Company Seal - Sleek Steel Common Seal @ Rs. 900.00. Inclusive of 18% GST as applicable. Weight=125 - 150 gm. Buy Now.",
  },

  sharecertificates: {
    title: "Share Certificate as per Companies ACT 2013 | Tax Print",
    keyword: "Share certificates, Printing",
    description:
      "Tax Print : Share Certificate as per Companies ACT 2013 Printing for 100 copies. Rs. 800 (Inclusive of 18% GST as applicable), Click & Buy Now !!",
    ogtitle: "Companies Act 2013 Stationery",
    canonical: "https://www.taxprint.com/sharecertificates",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "Companies Act 2013 Stationery",
    twitterdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
    facebooktitle: "Share Certificate as per Companies ACT 2013",
    facebookdescription: "Share Certificate as per Companies ACT 2013 Printing for 100 copies. Rs. 800 (Inclusive of 18% GST as applicable).",
    linkedintitle: "Share Certificate as per Companies ACT 2013",
    linkedindescription: "Share Certificate as per Companies ACT 2013 Printing for 100 copies. Rs. 800 (Inclusive of 18% GST as applicable).",
  },

  statutoryregister: {
    title: "Tax Print : Best Statutory Register (Index Register)  ",
    keyword: "Index Register, Statutory Combined Register, Statutory Register",
    description:"Tax Print : Statutory Combined Register (Index Register) As Per Companies Act, 2013 & Companies Rule, 2014. Price @ Rs. 470 Inclusive 18% GST, Buy Now !",
    ogtitle: "Statutory Register (Index Register)",
    canonical: "https://www.taxprint.com/statutoryregister",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "Statutory Register (Index Register)",
    twitterdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
    facebooktitle: "Statutory Register (Index Register)",
    facebookdescription: "Statutory Combined Register (Index Register) As Per Companies Act, 2013 & Companies Rule, 2014. Price @ Rs. 470 Inclusive of 18% GST as applicable.",
    linkedintitle: "Statutory Register (Index Register)",
    linkedindescription: "Statutory Combined Register (Index Register) As Per Companies Act, 2013 & Companies Rule, 2014. Price @ Rs. 470 Inclusive of 18% GST as applicable.",
  },

  minutespaperwhite: {
    title: "Minutes Paper (White) - Best Quality | Tax Print",
    keyword: "Minutes Paper, White Minute Paper, Minutes Paper White",
    description:
      "Tax Print - Minutes Paper (White)- Rs. 300.00 Inclusive of 18% GST as applicable. Weight=125 - 150 gm. Click and Buy Now !!",
    ogtitle: "Companies Act 2013 Stationery",
    canonical: "https://www.taxprint.com/minutespaperwhite",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "Companies Act 2013 Stationery",
    twitterdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
    facebooktitle: "Minutes Paper (White) - Tax Print",
    facebookdescription: "Minutes Paper (White)- Rs. 300.00 Inclusive of 18% GST as applicable. Weight=125 - 150 gm. Buy Now. ",
    linkedintitle: "Minutes Paper (White) - Tax Print",
    linkedindescription: "Minutes Paper (White)- Rs. 300.00 Inclusive of 18% GST as applicable. Weight=125 - 150 gm. Buy Now. ",
  },

  minutespaperledger: {
    title: "Minutes Paper (Green - Ledger) | Tax Print",
    keyword: "Minutes Paper, Green Ledger, Minutes Paper Green Ledger, minutes paper ledger",
    description:
      "Tax Print : Minutes Paper (Green - Ledger) Rs. 250.00. Inclusive of 18% GST as applicable. Weight=125 - 150 gm. Click here & Buy Now !!",
    ogtitle: "Companies Act 2013 Stationery",
    canonical: "https://www.taxprint.com/minutespaperledger",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "Companies Act 2013 Stationery",
    twitterdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
    facebooktitle: "Minutes Paper (Green - Ledger)",
    facebookdescription: "Minutes Paper (Green - Ledger) Rs. 250.00. Inclusive of 18% GST as applicable. Weight=125 - 150 gm. Buy Now.",
    linkedintitle: "Minutes Paper (Green - Ledger)",
    linkedindescription: "Minutes Paper (Green - Ledger) Rs. 250.00. Inclusive of 18% GST as applicable. Weight=125 - 150 gm. Buy Now.",
  },

  minutesbinder: {
    title: "Minutes Binder - Companies Act 2013 Stationery | Tax Print",
    keyword: "Minutes Binder",
    description:
      "Tax Print : Affordable Minutes Binder @ Rs. 550.00. Inclusive of 18% GST as applicable. Weight=125 - 150 gm. Click here & Buy Now !!",
    ogtitle: "Companies Act 2013 Stationery",
    canonical: "https://www.taxprint.com/minutesbinder",
    ogtype: "website",
    ogurl: "",
    ogimage: "",
    ogdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    ogsitename: "Tax Print",
    twittercard: "",
    twittertitle: "Companies Act 2013 Stationery",
    twitterdescription:
      "Directors & Key Managerial Personnel and their ShareholdingsContracts with a related party, Contracts & Bodies, etc. which interest directors.",
    twittersite: "",
    twitterimage: "",
    twittercreator: "",
    facebooktitle: "Minutes Binder - Companies Act 2013 Stationery",
    facebookdescription: "Minutes Binder @ Rs. 550.00. Inclusive of 18% GST as applicable. Weight=125 - 150 gm. Buy Now. Add to Cart.",
    linkedintitle: "Minutes Binder - Companies Act 2013 Stationery",
    linkedindescription: "Minutes Binder @ Rs. 550.00. Inclusive of 18% GST as applicable. Weight=125 - 150 gm. Buy Now. Add to Cart.",
  },
};
