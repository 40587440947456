import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { isPlatformBrowser, Location } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatChipInputEvent } from '@angular/material';
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { Cart } from "../../models/cart.model";
import { Register } from "../../models/register.model";
import { CartService } from "../../services/component-services/cart.service";
import { BasePlugin } from "../base.plugin";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { PluginMaster } from "./../../shared/enums/plugin.master";


declare var $: any;
declare var Instamojo: any;
declare var window: any;
export interface mail {
  name: string;
}
/**
 * @title Chips with input
 */

@Component({
  selector: "app-signup-form",
  templateUrl: "./signup-form.component.html",
  styleUrls: ["signup-form.component.scss"]
})
export class SignupFormComponent extends BasePlugin implements OnInit, OnChanges {
  @Input() prodDetails: any;
  @Input() offlineData: any;
  offlineSales: any;
  checked: boolean;
  showMesg = false;
  stateList = [
    { stateCode: "Andhra Pradesh", stateName: "Andhra Pradesh" },
    { stateCode: "Arunachal Pradesh", stateName: "Arunachal Pradesh" },
    { stateCode: "Assam", stateName: "Assam" },
    { stateCode: "Bihar", stateName: "Bihar" },
    { stateCode: "Chhattisgarh", stateName: "Chhattisgarh" },
    { stateCode: "Chandigarh", stateName: "Chandigarh" },
    { stateCode: "Dadra and Nagar Haveli", stateName: "Dadra and Nagar Haveli" },
    { stateCode: "Daman and Diu", stateName: "Daman and Diu" },
    { stateCode: "Delhi", stateName: "Delhi" },
    { stateCode: "Goa", stateName: "Goa" },
    { stateCode: "Gujarat", stateName: "Gujarat" },
    { stateCode: "Haryana", stateName: "Haryana" },
    { stateCode: "Himachal Pradesh", stateName: "Himachal Pradesh" },
    { stateCode: "Jammu and Kashmir", stateName: "Jammu and Kashmir" },
    { stateCode: "Jharkhand", stateName: "Jharkhand" },
    { stateCode: "Karnataka", stateName: "Karnataka" },
    { stateCode: "Kerala", stateName: "Kerala" },
    { stateCode: "Madhya Pradesh", stateName: "Madhya Pradesh" },
    { stateCode: "Maharashtra", stateName: "Maharashtra" },
    { stateCode: "Manipur", stateName: "Manipur" },
    { stateCode: "Meghalaya", stateName: "Meghalaya" },
    { stateCode: "Mizoram", stateName: "Mizoram" },
    { stateCode: "Nagaland", stateName: "Nagaland" },
    { stateCode: "Orissa", stateName: "Orissa" },
    { stateCode: "Punjab", stateName: "Punjab" },
    { stateCode: "Pondicherry", stateName: "Pondicherry" },
    { stateCode: "Rajasthan", stateName: "Rajasthan" },
    { stateCode: "Sikkim", stateName: "Sikkim" },
    { stateCode: "Tamil Nadu", stateName: "Tamil Nadu" },
    { stateCode: "Telangana", stateName: "Telangana" },
    { stateCode: "Tripura", stateName: "Tripura" },
    { stateCode: "Uttar Pradesh", stateName: "Uttar Pradesh" },
    { stateCode: "Uttarakhand", stateName: "Uttarakhand" },
    { stateCode: "West Bengal", stateName: "West Bengal" }
  ];

  CountryList: any[] = [
    { countryName: 'India', countryCode: 'IN' }
  ];

  pluginMaster = PluginMaster;
  register: Register = new Register();
  signUpForm: FormGroup;
  addressForm: FormGroup;
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  show = true;
  isReadOnly = false;
  bAddCtrl: AbstractControl;
  sameAddCtrl: AbstractControl;
  sAddCtrl: AbstractControl;
  //DelMumCtrl: AbstractControl;
  showContu = true;
  @Output() instamojoEmit = new EventEmitter();
  @Output() offlineEmit = new EventEmitter();
  cartService: CartService = new CartService();
  cart: Cart = new Cart();
  mode: string;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  //chips variable
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  delivery = false;
  deliveryStatus = 0;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  optData = [];
  userID: any;
  isRead = true;
  status: any;
  seal = {
    company: '',
    address: '',
    city: ''
  };
  certificate = {
    company: '',
    CIN: '',
    address: '',
    city: '',
    pincode: '',
    stateName: '',
    shareType: '',
    faceValue: '',
    shareCertiDateStart: 0,
    shareCertiDateEnd: 0
  };
  sealForm: FormGroup;
  certificateForm: FormGroup;
  formArr = [];
  //chips variable end
  prodCheckoutObj = {
    QTY: null,
    PID: null,
    SPID: null,
    VID: '',
    VAMT: null,
    Total: null
  };
  fruits = [
    { name: 'Lemon' },
    { name: 'Lime' },
    { name: 'Apple' },
  ];
  toDisable = true;
  constructor(public location: Location, public router: Router, public formBuilder: FormBuilder, @Inject(PLATFORM_ID) private platformId: Object, componentManagerService: ComponentManagerService
  ) {
    super(componentManagerService);
    this.register.OTHER_EMAILS = [];
    this.offlineSales = { ID: '', prodQty: 1, PAYMENT_MODE: '', DELIVERY_MODE: '' };


  }

  ngOnInit() {

    this.createForm();
    this.createAddForm();
    this.validateSealForm();
    this.validateCertificateForm();
    for (const t of this.componentManagerService.cartArr) {
      if (t['SUBGROUP'] === 'Share Certificate' || t['SUBGROUP'] === 'Seal' || t['GROUP_TYPE'] === 'AMC' || t['SUB_PROD_CONFIG_DISP'] === 'PROD_SUB_YMM') {
        for (let i = 0; i < t.QTY; i++) {
          this.formArr.push(t);
        }
      }
    }
    // this.createAddForm();
    if (this.signUpForm.valid) {
      this.showContu = false;
    }
    else {
      // this.validateAllFormFields(this.signUpForm)
    }
    if (this.isBrowser) {
      this.cart.LANDING_URL = window.location.href;
    }

    this.prefillDetails();
  }

  ngOnChanges() {
    if (!!this.prodDetails && this.prodDetails.PTYPE) {
      if (this.prodDetails.PTYPE.toUpperCase() == 'STATIONERY') {
        this.addressForm.get('deliveryAddr1').setValidators([Validators.required, Validators.maxLength(250)]);
        this.addressForm.get('deliveryCity').setValidators([Validators.required, Validators.maxLength(100)]);
        this.addressForm.get('deliveryPincode').setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
        this.addressForm.get('deliveryState').setValidators([Validators.required, Validators.maxLength(100)]);
        this.addressForm.get('deliveryCountry').setValidators([Validators.required, Validators.maxLength(100)]);
        //  this.addressForm.get('DeliveryMode').setValidators([Validators.required, Validators.maxLength(100)]);
        this.mode = 'COURIER';
      }
    }
    this.prefillDetails();
  }

  setId() {
    return PluginMaster.SIGNUP;
  }

  actionFromSubPlugin(params) { }

  //*************chip keys section **********************

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.register.OTHER_EMAILS.push(value.trim());
    }
    if (input) {
      input.value = '';
    }

  }

  remove(mail): void {
    const index = this.register.OTHER_EMAILS.indexOf(mail);

    if (index >= 0) {
      this.register.OTHER_EMAILS.splice(index, 1);
    }
  }

  //*************chip keys section End ******************

  createForm() {
    this.signUpForm = this.formBuilder.group({
      fullname: ['', [
        Validators.required,
        Validators.maxLength(240)]],
      company: ['', [
        Validators.required,
        Validators.maxLength(150)
      ]],
      mobile: ['', [
        Validators.required,
        Validators.minLength(10)]],
      email: ['', [
        Validators.required,
        Validators.maxLength(90)
      ]],
      contact2: ['', [
        Validators.minLength(10),
        Validators.maxLength(15)
      ]],
      gst: ['', [
        Validators.maxLength(15),
        Validators.minLength(15),
        Validators.pattern('^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$')
      ]]
    });

  }

  createAddForm() {
    this.addressForm = this.formBuilder.group({
      bAddr1: ['', [Validators.required, Validators.maxLength(250)]],
      bAddr2: ['', [Validators.maxLength(250)]],
      bCity: ['', [Validators.required, Validators.maxLength(100)]],
      bState: ['', [Validators.required, Validators.maxLength(100)]],
      bCntry: ['', [Validators.required, Validators.maxLength(100)]],
      bPincode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      sameAddress: [null],
      deliveryAddr1: ['', [Validators.maxLength(250)]],
      deliveryAddr2: ['', [Validators.maxLength(250)]],
      deliveryCity: ['', [Validators.maxLength(100)]],
      deliveryState: ['', [Validators.maxLength(100)]],
      deliveryCountry: ['', [Validators.maxLength(100)]],
      deliveryPincode: ['', [Validators.minLength(6), Validators.maxLength(6)]],
      DeliveryMode: ['', []],
    });
  }

  isFieldValid(field: string) {
    return !this.signUpForm.get(field).valid && this.signUpForm.get(field).touched;
  }
  isFormFieldValid(field: string) {
    return !this.addressForm.get(field).valid && this.addressForm.get(field).touched;
  }

  //#region Form Validation check
  // Highlights all the validations errors in a form
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSubmit() {

    // user side login and buy product
    if (this.signUpForm.valid && this.addressForm.valid) {
      if (this.signUpForm.valid && !!this.prodDetails) {
        this.cartService.flushCart();
        this.cart = new Cart();
        // this.cart.ITEMS['PRODUCTOBJ'] = this.prodDetails;
        let prodSaleNote = {};
        if (this.prodDetails.SUBGROUPS === 'Seal') {
          if (this.sealForm.valid) {
            prodSaleNote = { company: this.sealForm.value.cName, address: this.sealForm.value.regisAddrs, city: this.sealForm.value.offAddr };
          } else {
            return;
          }
        }
        if (this.prodDetails.SUBGROUPS === 'Share Certificate') {
          if (this.certificateForm.valid) {
            prodSaleNote = { company: this.certificateForm.value.cName, CIN: this.certificateForm.value.cin, address: this.certificateForm.value.addr, city: this.certificateForm.value.city, pincode: this.certificateForm.value.pincode, stateName: this.certificateForm.value.state, shareType: this.certificateForm.value.share, faceValue: this.certificateForm.value.faceValue, shareCertiDateStart: this.certificateForm.value.certiStartDate, shareCertiDateEnd: this.certificateForm.value.certiEndDate }
          } else {
            return;
          }
        }
        this.prodDetails['prodDetailNote'] = [prodSaleNote];
        this.cart.ITEMS.push(this.prodDetails);

        this.cart.CART_AMOUNT = this.cartService.cartTotal;
        this.cart.CUSTOMER_DETAILS = { ...this.register, OTHER_EMAILS: this.register.OTHER_EMAILS.join() };
        //  this.cart.DELIVERY_MODE = this.mode;
        this.cart.DELIVERY_MODE = this.offlineSales.DELIVERY_MODE;

        this.cart.seal = this.seal;
        this.cart.certificate = this.certificate;
        this.cart.TRAN_MODE = 'ONLINE';
        this.cart['checkout'] = 1;
        console.log('this.cart', this.cart);
        this.callDataJunction('CHECK_OUT', this.cart).subscribe(res => {
          if (res.status) {
            Instamojo.open(res.data.pgURL);
            this.instamojoEmit.emit(res.data);
          } else {
            alert('Error in Checkout. Please try again.1');
          }
        });
      } // user side login and buy product End
      else if (this.addressForm.valid && this.offlineData == 0) {
        // ************offline sales call ***********
        this.callDataJunction('CHECK_OUT', { ...this.register, checkout: this.offlineData, OTHER_EMAILS: this.register.OTHER_EMAILS.join() }).subscribe(res => {
          if (res.status) {
            if (this.isBrowser) {
              $('#signUp').modal('hide');
              this.offlineEmit.emit('refresh');
            }
          } else {
            alert('Error in Checkout. Please try again.2');
          }
        });
      }
    }
    // ************offline sales call End ************
    else {
      this.validateAllFormFields(this.signUpForm);
      this.validateAllFormFields(this.addressForm);
    }
  }

  continueForm(params) {
    if (params == 'continue') {
      this.show = false;
    } else if (params == 'back') {
      this.show = true;
    }
    else {
      this.onSubmit();
    }
  }


  isSameAdd(event, params) {
    if (params) {
      this.register.B_SAME_S = 1;
      this.register.S_ADDRESS = this.register.B_ADDRESS;
      this.isReadOnly = true;
      this.validateAllFormFields(this.signUpForm);
    } else {
      this.register.B_SAME_S = 0;
      this.register.S_ADDRESS = '';
      this.isReadOnly = false;
    }
  }


  prefillDetails() {
    if (this.componentManagerService.loggedIn && this.componentManagerService.user) {
      let temp;
      if (typeof this.componentManagerService.user === 'string') {
        temp = JSON.parse(this.componentManagerService.user);
      } else {
        temp = Object.assign({}, this.componentManagerService.user);
      }
      //logout user if temp is undefined
      if (!temp || !temp.ID) {
        this.callDataJunction('LOGOUT', undefined);
        return;
      }

      if (!!this.register.FULLNAME) {
        // form already contains data, do not override it
        return;
      }
      this.userID = temp.ID;
      this.register.FULLNAME = `${temp.FIRST_NAME} ${temp.LAST_NAME}`;
      this.register.COMPANY = temp.COMPANY;
      this.register.MOBILE = temp.MOBILE;
      this.register.CONTACT2 = temp.CONTACT2;
      this.register.EMAIL = temp.EMAIL;
      this.register.GST_NO = temp.GST_NO;
      //this.register.B_ADDRESS = temp.B_ADDRESS;
      this.register.B_SAME_S = temp.B_SAME_S;
      //this.register.S_ADDRESS = temp.S_ADDRESS;
      this.register.B_ADDRESS1 = temp.B_ADDRESS1;
      this.register.B_ADDRESS2 = temp.B_ADDRESS2;
      this.register.B_CITY = temp.B_CITY;
      this.register.B_PINCODE = temp.B_PINCODE;
      this.register.B_STATE = temp.B_STATE;
      this.register.B_COUNTRY = temp.B_COUNTRY;
      //this.register.S_ADDRESS = temp.S_ADDRESS;
      if (!!this.register.B_SAME_S) {
        this.register.S_ADDRESS1 = this.register.B_ADDRESS1;
        this.register.S_ADDRESS2 = this.register.B_ADDRESS2;
        this.register.S_CITY = this.register.B_CITY;
        this.register.S_PINCODE = this.register.B_PINCODE;
        this.register.S_STATE = this.register.B_STATE;
        this.register.S_COUNTRY = this.register.B_COUNTRY;
      } else {
        this.register.S_ADDRESS1 = temp.S_ADDRESS1;
        this.register.S_ADDRESS2 = temp.S_ADDRESS2;
        this.register.S_CITY = temp.S_CITY;
        this.register.S_PINCODE = temp.S_PINCODE;
        this.register.S_STATE = temp.S_STATE;
        this.register.S_COUNTRY = temp.S_COUNTRY;
      }

    }
  }
  copyBillingDetails(event) {
    if (event.target.checked) {
      this.register.S_ADDRESS1 = this.register.B_ADDRESS1
      this.register.S_ADDRESS2 = this.register.B_ADDRESS2;
      this.register.S_CITY = this.register.B_CITY;
      this.register.S_STATE = this.register.B_STATE;
      this.register.S_PINCODE = this.register.B_PINCODE;
    }
  }

  validateSealForm() {
    this.sealForm = this.formBuilder.group({
      cName: [null, [Validators.required]],
      // regisAddrs: [null, [Validators.required]],
      offAddr: [null, [Validators.required]],
    })

  }

  isFieldValid1(field: string) {
    return !this.sealForm.get(field).valid && this.sealForm.get(field).touched;
  }
  validateCertificateForm() {
    this.certificateForm = this.formBuilder.group({
      cName: [null, [Validators.required]],
      cin: [null, [Validators.required]],
      addr: [null, [Validators.required]],
      city: [null, [Validators.required]],
      pincode: [null, [Validators.required]],
      state: [null, [Validators.required]],
      share: [null, [Validators.required]],
      faceValue: [null, [Validators.required]],
      certiStartDate: [null, [Validators.required]],
      certiEndDate: [null, [Validators.required]],
    })

  }
  isFieldValid2(field: string) {
    return !this.certificateForm.get(field).valid && this.certificateForm.get(field).touched;
  }

  add1(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.fruits.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove1(fruit): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  radioChange(event) {
    if (event.target.value === 'IN_HAND') {
      this.delivery = false;
      this.showMesg = true;
      this.deliveryStatus = -1;
      if (this.prodDetails.PTYPE === 'STATIONERY') {
        const deliveryAmt = environment.deliveryamt;
        const totalDelivery = deliveryAmt + (deliveryAmt * (18 / 100));
        this.prodDetails.VAMT -= this.convertToFloat(totalDelivery);
        this.prodDetails.DELIVERY_MODE = 'IN_HAND';
      }
    } else {
      this.delivery = true;
      if (this.prodDetails.PTYPE === 'STATIONERY') {
        const deliveryAmt = environment.deliveryamt;
        const totalDelivery = (deliveryAmt + (deliveryAmt * (18 / 100)));
        this.prodDetails.VAMT += this.convertToFloat(totalDelivery);
        this.prodDetails.DELIVERY_MODE = 'COURIER';
      }
    }
  }

  convertToFloat(num) {
    return parseFloat(parseFloat(num).toFixed(2))
  }
  setToNumber(event) {
    this.certificate.shareCertiDateEnd = parseInt(event.target.value) + 99;
  }
}
