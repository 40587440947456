import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ComponentManagerService } from './../../services/component-manager.service';
import { ContainerMaster } from './../../shared/enums/container.master';
import { SeoMaster } from './../../shared/enums/seo.master';
import { BaseContainer } from './../base.container';

declare var Instamojo: any;
declare var $: any;


@Component({
  selector: 'app-viewcart',
  templateUrl: './viewcart.component.html',
  styleUrls: ['./viewcart.component.scss']
})
export class ViewCartComponent extends BaseContainer implements OnInit {

  // products = [{
  //   product: 'Hoodie', price: '100' , totalprice: '100', subtotal: '120' , total: '100'

  // }];
  quantity: any;
  price: any;
  setMetaTags(): any[] {
    return ([]);
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    meta: Meta,
    title: Title,
    componentManagerService: ComponentManagerService, private router: Router,
  ) {
    super(meta, title, componentManagerService);

  }

  ngOnInit() {
  }

  setId() {
    return ContainerMaster.VIEWCART;
  }
  setMetaTitle() {
    return SeoMaster.VIEWCART.title;
  }

  actionFromPlugin(params) { }

  onChange(i) {
    this.componentManagerService.cartArr[i]['TOTAL'] = parseInt(this.componentManagerService.cartArr[i]['QTY']) * parseInt(this.componentManagerService.cartArr[i]['VAMT']);
    this.componentManagerService.subTotalPrice();

  }
  goCartNCheckout(params) {
    if (params === 'checkout') {
      this.router.navigate(['/checkout']);
      $('#cartModal').modal('hide');
    }
  }

  removeItem(params) {
    this.componentManagerService.cartArr.splice(params, 1);
    this.componentManagerService.subTotalPrice();
  }


}

