import { PluginMaster } from "./../../shared/enums/plugin.master";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID } from "@angular/core";
import { BasePlugin } from "../base.plugin";
import { Router, ActivatedRoute } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { ToasterService } from "../../services/utility-services/toaster.service";
import { ContainerMaster } from "../../shared/enums/container.master";
import { CustomValidators } from "../../shared/directives/customvalidator";
import { ForgetPassword } from "../../models/forgetPassword.model";
declare var $: any;

@Component({
  selector: "app-change-password",
  templateUrl: "./changepassword.component.html",
  styleUrls: ["changepassword.component.scss"]
})

export class ChangePasswordComponent extends BasePlugin implements OnInit {
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  containerMaster = ContainerMaster;
  changeUserPasswordForm: FormGroup;
  forgetPassword: ForgetPassword = new ForgetPassword();
  userEmail = '';
  id: any;
  pageAccess: any;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, componentManagerService: ComponentManagerService,
    private router: Router, private toasterService: ToasterService
  ) {
    super(componentManagerService);
    this.pageAccess = this.componentManagerService.admin.SCREENS[8]['SCREENACCESS'];
  }

  ngOnInit() {
    this.createForm();
  }

  setId() {
    return PluginMaster.CHANGE_PASSWORD;
  }

  actionFromSubPlugin(params) { }

  createForm() {
    this.changeUserPasswordForm = this.formBuilder.group({
      newpasscode: ['', [Validators.required, Validators.minLength(8)]],
      confirmPasscode: ['', Validators.required,Validators.minLength(8)]
    },
      { validator: CustomValidators.childrenEqual });
  }

  // isFieldValid(field: string) {
  //   return (
  //     !this.changeUserPasswordForm.get(field).valid && this.changeUserPasswordForm.get(field).touched
  //   );
  // }

  //#region Form Validation check
  // Highlights all the validations errors in a form
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  //#endregion

  onSubmit() {
    if (this.changeUserPasswordForm.valid) {
      console.log(this.forgetPassword);
      this.callDataJunction('FORGET_PASSWORD', { ...this.forgetPassword, ID: this.id, }).subscribe(res => {
        if (res.status) {
          this.toasterService.showToaster(res.message, 'toast-success');
          this.navigateTo('home');
        } else {
          this.toasterService.showToaster(res.message, 'toast-error');
        }
      });
    } else {
      this.validateAllFormFields(this.changeUserPasswordForm);
    }
  }
}
