import { Observable } from 'rxjs';
import { PluginModel } from '../models/plugin.model';
import { ComponentManagerService } from '../services/component-manager.service';
import { RouteManagerService } from '../services/route-manager.service';
import { Meta, Title } from '@angular/platform-browser';

export abstract class BaseContainer {
  isMobile :boolean;
  data: any = {};
  containerId: any;
  pluginModel: PluginModel = new PluginModel;
  containerData: any = {};
  constructor(meta: Meta, title: Title, public componentManagerService: ComponentManagerService) {
    this.isMobile = this.componentManagerService.isMobile;
    var titledata = this.setMetaTitle();
    var metadata = this.setMetaTags();
    title.setTitle(titledata);
    meta.addTags(metadata);
    this.set();
  }

  set() {
    this.containerId = this.setId();
  }

  get() {
    return this.containerId;
  }
  showdataFromPlugin(params) {
    switch (params.action) {
      case 'ROUTE_TO_PAGE':
        if (!!params.queryParams) {
          const paramdata = {
            data: params.data,
            queryParams: params.queryParams
          };
          this.componentManagerService.dataJunction(this.containerId, params.action, paramdata);
        } else {
          const paramdata = {
            data: params.data
          };
          this.componentManagerService.dataJunction(this.containerId, params.action, paramdata);
        }
        break;
      case 'SET_VALUE':
        break;
      case 'SET_OTHER_VALUE':
        break;
      case 'SCROLL_TO':
        break;
      default:
        this.actionFromPlugin(params);
        break;
    }
  }

  callDataJunction(action: string, data?: any): Observable<any> {
    return this.componentManagerService.dataJunction(this.containerId, action, data);
  }

  navigateTo(data: any, queryParams?: any) {
    let result;
    if (!!queryParams) {
      result = { data: data, queryParams: queryParams };
    } else {
      result = { data: data };
    }
    this.componentManagerService.dataJunction(this.containerId, 'NAVIGATION_ROUTE', result);  
  }

  abstract setId(): string;
  abstract actionFromPlugin(params);
  abstract setMetaTitle(): string;
  abstract setMetaTags(): Array<any>;
}
