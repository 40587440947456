import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class CRMService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private createLeadUrl = 'api/createLeadv2';
  private readEnquiryUrl = 'api/readEnquiry';
  
  createLead(val: any) {
    return this.http.post(this.createLeadUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('register'))
    );
  }

  readEnquiry(val: any) {
    return this.http.post(this.readEnquiryUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('register'))
    );
  }
}
