export class Register {
  ID: number;
  LOGIN_TYPE: string;
  EMAIL: string;
  PASSCODE: string;
  TOKEN: string;
  COMPANY: string;
  MOBILE: number;
  CONTACT2: number;
  ACTIVE: number;
 
  B_ADDRESS: string;
  S_ADDRESS: string;
  B_ADDRESS1 : string;
  B_ADDRESS2: string; 
  B_CITY : string; 
  B_PINCODE: string; 
  B_STATE : string;
  B_COUNTRY : string;
  S_ADDRESS1 : string; 
  S_ADDRESS2 : string; 
  S_CITY : string; 
  S_PINCODE : string;
  S_STATE : string;
  S_COUNTRY: string;
  B_SAME_S: number;
  //SHIPPING_IN_MUMBAI: string;
  //SHIPPING_OUT_MUMBAI: string;
  GST_NO: string;
  OTHER_EMAILS: Array<any>;
  FULLNAME: string;
  description: string;
  PRODUCT: string;
  constructor() {
    this.LOGIN_TYPE = 'EMAIL';
    this.ACTIVE = 1;
    this.B_SAME_S = 0;
    this.PASSCODE = '';
    this.B_COUNTRY = 'India';
    this.S_COUNTRY = 'India';
    this.B_STATE = 'Andhra Pradesh';
    this.S_STATE = 'Andhra Pradesh';
  }
}

