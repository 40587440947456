import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { tap, catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class ProductService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private getProductUrl = 'api/getProduct';
  private getAllUrl = 'api/product/getAll';
  private getsubproductUrl = 'api/product/getsubproduct';
  private createsubproductUrl = 'api/product/createsubproduct';
  private updatesubproductUrl = 'api/product/updatesubproduct';
  private addProductUrl = 'api/product/add';
  private updateProductUrl = 'api/product/update';
  private getsubproductvariantsUrl = 'api/product/getsubproductvariants';
  private createsubproductvairantUrl = 'api/product/createsubproductvariants';
  private updatesubproductvairantUrl = 'api/product/updatessubproductvariants';
  private getProdDetails = 'api/product/getProdDetails';
  private getStationaryDetailsUrl = 'api/product/getStationaryDetails';
  private getAllProductVariantsUrl = 'api/product/getAllProductVariants';


  getProducts(val: any) {
    return this.http.post(this.getProductUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getProduct'))
    );
  }

  getAllProduct(val: any) {
    return this.http.post(this.getAllUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getAll'))
    );
  }

  getsubproduct(val: any) {
    return this.http.post(this.getsubproductUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getsubproduct'))
    );
  }

  updatesubproduct(val: any) {
    return this.http.post(this.updatesubproductUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('updatesubproductUrl'))
    );
  }

  createsubproduct(val: any) {
    return this.http.post(this.createsubproductUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('createsubproduct'))
    );
  }

  addProduct(val: any) {
    return this.http.post(this.addProductUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('addProduct'))
    );
  }
  updateProduct(val: any) {
    return this.http.post(this.updateProductUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('updateProduct'))
    );
  }

  getsubproductvariants(val: any) {
    return this.http.post(this.getsubproductvariantsUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getsubproduct'))
    );
  }
  createsubproductvariants(val: any) {
    return this.http.post(this.createsubproductvairantUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('createsubproductvariants'))
    );
  }
  updatesubproductvariants(val: any) {
    return this.http.post(this.updatesubproductvairantUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('updatesubproductvariants'))
    );
  }

  getProductDetails(val: any) {
    return this.http.post(this.getProdDetails, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getProdDetails'))
    );
  }
  getStationaryDetails(val: any) {
    return this.http.post(this.getStationaryDetailsUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('updatesgetStationaryDetailsUrlubproductvariants'))
    );
  }

  getAllProductVariants(val: any) {
    return this.http.post(this.getAllProductVariantsUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getAllProductVariants'))
    );
  }

}
