import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class OperatorService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private updateUserAccess = 'api/updateUserAccess';  
  updateScreensForUser(val: any) {
    return this.http.post(this.updateUserAccess, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getUserData'))
    );
  }
}
