import { PluginMaster } from './../../shared/enums/plugin.master';
import { ComponentManagerService } from './../../services/component-manager.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BasePlugin } from '../base.plugin';
import { Router } from '@angular/router';
import { Register } from "../../models/register.model";
//import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
declare var grecaptcha: any;
var widgetId2: any;
@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['crm.component.scss']
})

export class CRMFormComponent extends BasePlugin implements OnInit {
  routerUrl: string;
  show = false;
  register: Register;
  @Input() status: boolean;
  saveInProgress: boolean = false;


  @Output() statusEvent = new EventEmitter<boolean>();

  constructor(componentManagerService: ComponentManagerService, public router: Router) {
    super(componentManagerService);
    this.register = new Register();
    this.register.PRODUCT = '0';
  }

  ngOnInit() {
    this.routerUrl = this.router.url;
    if (this.routerUrl.charAt(0) == '/' && (!this.routerUrl.charAt(1))) {
      this.show = true;
    }
  }

  setId() {
    return PluginMaster.CRM;
  }

  actionFromSubPlugin(params) {

  }
  submit(formData) {
    this.saveInProgress = true;
    if (!formData.fullName || !formData.mobile || !formData.email || formData.enquiryFor === '0') {
      alert('Please input required fields.');
      this.saveInProgress = false;
      return;
    }
    
    let leadDesc: string = "";

    if (this.routerUrl.charAt(0) == '/') {
      // home page 
      if (!this.routerUrl.charAt(1)) {
        leadDesc = 'HOME PAGE LEAD';
      } else {
        leadDesc = `${this.routerUrl.replace('/', '')} LEAD`;
      }
      if (!!formData.description && formData.description.length > 0) {
        leadDesc += `|| ${formData.description}`;
      }
    }
 
    // const pageSrc = this.router.url === '/' ? 'home' : this.router.url.slice(1); 
    const leads = [Object.assign(formData, { leadSource: 'Website Visit', Description: leadDesc })];
    this.callDataJunction('CREATE_LEAD', leads).subscribe(res => {
      this.saveInProgress = false;
      if (res.status) {
        this.register = new Register();
        this.register.PRODUCT = '0';
        this.status = false;
        this.statusEvent.emit(this.status);
        this.navigateTo('thankyou', 'leadSuccess');
      } else {
        alert('Issue with capturing your details. Please try again.');
      }
    });

  }

  onSuccess() {
    // alert('onSuccess');
  }

  onExpired() {
    // alert('onExpired') ;
  }
  clickEvent() {
    this.status = !this.status;
    this.statusEvent.emit(this.status);
  }

  toggleForm() {
    this.status = !this.status;
    this.statusEvent.emit(this.status);
  }
}
