import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import {
  DetailRowService, FilterService,
  GridAllModule, GridModule, GroupService, PageService, ReorderService,
  ResizeService, SortService
} from '@syncfusion/ej2-angular-grids';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { CookieModule } from 'ngx-cookie';
import { AddressPdfComponent } from '../../container/address-pdf/address-pdf.component';
import { InvoicePdfComponent } from "../../container/invoice-pdf/invoice-pdf.component";
import { FieldErrorDisplayComponent } from '../../plugin/field-error-display.component/field-error-display.component';
import { PayModeComponent } from '../../plugin/paymodes/paymodes.component';
import { ProdSaleNoteComponent } from '../../plugin/saleNote/prodSaleNote.component';
import { ProdSubComponent } from '../../plugin/saleNote/prodSubYMM.component';
import { SignupFormComponent } from '../../plugin/signup-form/signup-form.component';
import { CartService } from '../../services/component-services/cart.service';
import { ToasterService } from '../../services/utility-services/toaster.service';
import { DateFormatPipe } from '../pipes/dateFormat.pipe';
import { MaterialModule } from './material.module';
//import { RefundPayModeComponent } from '../../plugin/refundpaymodes/refundpaymodes.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [FieldErrorDisplayComponent, SignupFormComponent, DateFormatPipe, PayModeComponent,
    ProdSaleNoteComponent, InvoicePdfComponent, AddressPdfComponent, ProdSubComponent],
  imports: [MaterialModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule,
    CookieModule.forRoot(), BsDatepickerModule.forRoot(), GridModule],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, FieldErrorDisplayComponent, MaterialModule,
    CookieModule, GridAllModule, SignupFormComponent, BsDatepickerModule, DateFormatPipe, PayModeComponent,
    ProdSaleNoteComponent, InvoicePdfComponent, AddressPdfComponent, GridModule, ProdSubComponent],
  providers: [PageService, SortService, FilterService, GroupService, ReorderService, ResizeService, DetailRowService],
})
export class SharedModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [CartService, ToasterService]
    };
  }
}
