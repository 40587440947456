import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { tap, catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class GstlockService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private updateGstlockUrl = 'api/updateGstLock';
  private getGstlockUrl = 'api/getGSTLock';
 

  updateGstlock(val: any) {
   
    return this.http.post(this.updateGstlockUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('gstlock'))
    );
  }
  getGstlock(val: any) {
   
    return this.http.post(this.getGstlockUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('gstlock'))
    );
  }

}
