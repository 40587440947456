import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class ShipRocketService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }
    private getShipRocketRequest = 'api/getShipRocketRequest';

    shipRocketRequest(val: any) {
        return this.http.post(this.getShipRocketRequest, val, httpOptions).pipe(
            tap((val)),
            catchError(this.handleError('getShipRocketRequest'))
        );
    }

}
