import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class UserService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private getUserDataUrl = 'api/getUserData';
  private updateUserUrl = 'api/updateUser';
  private getOrderByIDUrl = 'api/getOrderByID';
  private getLastTransDateUrl = 'api/getLastTransDate';
  private getNotiByIDUrl = 'api/notifiById';
  private getAllOperators = 'api/getallOperators';
  private updateOperatorsScreensUrl = 'api/updateOperatorsScreens';
  private registerOperatorUrl = 'api/registerOperator';
  private getOperatorScreenDetailsByIdUrl = 'api/getOperatorScreenDetailsById';
  private updateOperatorScreenDetailsByIdUrl = 'api/updateOperatorScreenDetailsById'
  getUserData(val: any) {
    return this.http.post(this.getUserDataUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getUserData'))
    );
  }

  updateUser(val: any) {
    return this.http.post(this.updateUserUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('updateUser'))
    );
  }

  getOrderByID(val: any) {
    return this.http.post(this.getOrderByIDUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getOrderByID'))
    );
  }

  getLastTransDate(val: any) {
    return this.http.post(this.getLastTransDateUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getLastTransDate'))
    );
  }


  getNotiByID(val: any) {
    return this.http.post(this.getNotiByIDUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getNotiByID'))
    );
  }

  getAllOperator(val: any) {
    return this.http.post(this.getAllOperators, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getAllOperators'))
    );
  }
  getOperatorScreenByID(val: any) {
    return this.http.post(this.getOperatorScreenDetailsByIdUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getOperatorScreenDetailsByIdUrl'))
    );
  }
  updateOperatorScreenByID(val: any) {
    return this.http.post(this.updateOperatorScreenDetailsByIdUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('updateOperatorScreenDetailsByIdUrl'))
    );
  }
  updateOperatorsScreens(val: any) {
    return this.http.post(this.updateOperatorsScreensUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('updateOperatorsScreensUrl'))
    );
  }
  registerOperator(val: any) {
    return this.http.post(this.registerOperatorUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('registerOperatorUrl'))
    );
  }

}
