import { Title, Meta} from '@angular/platform-browser';
import { ComponentManagerService } from '../../services/component-manager.service';
import { SeoMaster } from '../../shared/enums/seo.master';
import { ContainerMaster } from '../../shared/enums/container.master';
import { Component, Inject, OnInit } from '@angular/core';
import { BaseContainer } from '../base.container';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-whytaxprint',
  templateUrl: './whytaxprint.component.html',
  styleUrls: ["./whytaxprint.component.scss"]
  
})

export class WhyTaxPrintComponent extends BaseContainer implements OnInit {
  status = true;
  constructor(@Inject(DOCUMENT) private doc: any,
  meta: Meta, title: Title, componentManagerService: ComponentManagerService) {
      super(meta, title, componentManagerService);
  }

  ngOnInit() { 
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',this.doc.URL)
  }

  setId() {
    return ContainerMaster.WHYTAXPRINT;
  }
  setMetaTitle() {
    return SeoMaster.WHYTAXPRINT.title;
  }
  setMetaTags() {
    return ([
  
    ]);
  }
  actionFromPlugin(params) {
  }
  clickEvent(){
    this.status = !this.status;
 }


 reciveStatus($event)
{
 this.status=$event;
}
}