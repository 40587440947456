import { PluginMaster } from "./../../shared/enums/plugin.master";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { Component, OnInit, Inject, PLATFORM_ID, Output, EventEmitter, Input, OnChanges, ViewChild } from "@angular/core";
import { BasePlugin } from "../base.plugin";
import { isPlatformBrowser, PathLocationStrategy } from "@angular/common";
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
export interface mail {
  name: string;
}
/**
 * @title Chips with input
 */

@Component({
  selector: "app-prodsub",
  templateUrl: "./prodSubYMM.component.html"
})
export class ProdSubComponent extends BasePlugin implements OnInit, OnChanges {

  pluginMaster = PluginMaster;
  prodFormGroup: FormGroup;
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  //@Input() product;
  @Output() periodChange = new EventEmitter();
  @Output() assignLicenseEmitter = new EventEmitter();

  @Input() subType: string;
  @Input() prodName: string;
  duration:string ;
  financialYears = [
    /*{label:"16-17", value:"2016-2017"},
    {label:"17-18", value:"2017-2018"},
    {label:"18-19", value:"2018-2019"}*/
  ];
 
  constructor(public location: Location, public router: Router, public formBuilder: FormBuilder, @Inject(PLATFORM_ID) private platformId: Object, componentManagerService: ComponentManagerService
  ) {
    super(componentManagerService);   
  }

  ngOnInit() {
    this.createForm();
    if(this.prodName.search(/proactly/i) > -1) 
    {this.duration = 'For 1 Year';}
    else if(this.prodName.search(/it return|fil|xbrl/i) > -1)
    {this.duration = 'AY 2019-2020';}
    else
    {this.duration = 'FY 2019-2020';}

    this.assignLicense('NEW');
  }

  ngOnChanges() {
    if(this.prodName.search(/proactly/i) > -1)  {
      this.financialYears = [
        {label:"For 1 Year", value:"2019-2020"}
      ] 
    } else  if(this.prodName.search(/it return|fil|xbrl/i) > -1){
      this.financialYears = [
        {label:"AY 19-20", value:"AY 2019-2020"}
      ] 
    }
    else {
      this.financialYears = [
        {label:"FY 19-20", value:"FY 2019-2020"},
        {label:"FY 18-19", value:"FY 2018-2019"}
      ] 
    }
    
  
    
  }

  setId() {
    return PluginMaster.PROD_SUB_YMM;
  }

  actionFromSubPlugin(params) { }

  createForm() {
    this.prodFormGroup = this.formBuilder.group({
      durationSelector: ['', []]
      //licenseSelector: ['', []]
    });
  }

  onPeriodChange(period: any) {
    this.periodChange.emit(period);
  }
  assignLicense(licenseType: string) {
    this.assignLicenseEmitter.emit(licenseType);
  }

}
