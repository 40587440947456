import { Title, Meta } from "@angular/platform-browser";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { SeoMaster } from "./../../shared/enums/seo.master";
import { ContainerMaster } from "./../../shared/enums/container.master";
import { Component, OnInit, Inject, PLATFORM_ID, ViewChild } from "@angular/core";
import { BaseContainer } from "./../base.container";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
declare var Instamojo: any;
declare var $: any;
declare var window: any;

@Component({
  selector: "app-hrslip",
  templateUrl: "./hrslip.component.html",
  styleUrls: ["hrslip.component.scss"]
})
export class HrSlipComponent extends BaseContainer implements OnInit {
  status: boolean = true;
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: any,
    meta: Meta,
    title: Title,
    componentManagerService: ComponentManagerService
  ) {
    super(meta, title, componentManagerService);
    //default selected buyOption
   
  }

  ngOnInit() { 
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',this.doc.URL)
  }

  setId() {
    return ContainerMaster.HRSLIP;
  }
  setMetaTitle() {
    return SeoMaster.HRSLIP.title;
  }
 
  setMetaTags() {
    return [
      { name: 'description', content: SeoMaster.HRSLIP.description },
      { name: 'keywords', content: SeoMaster.HRSLIP.keyword },
    ];
  }
  actionFromPlugin(params) { }
  ngAfterViewInit() {
    if (!this.componentManagerService.isMobile) {
      $("#social-float").addClass("top-146");
      $("#social-float").addClass("fix-pos");
      $(document).scroll(function () {
        if ( $("#social-float").offset() && $("#social-float").offset().top + $("#social-float").height() >=
          $("#footer").offset().top
        ) {
          $("#social-float").addClass("positionabs");
          $("#social-float").removeClass("fix-pos");
        }
        if ( $(document).scrollTop() + window.innerHeight <
          $("#footer").offset().top
        ) {
          $("#social-float").addClass("fix-pos");
          $("#social-float").removeClass("positionabs");
          if ($(window).scrollTop() > 150) {
            $("#social-float").addClass("top-73");
            $("#social-float").removeClass("top-146");
          } else {
            $("#social-float").addClass("top-146");
            $("#social-float").removeClass("top-73");
          }
        }
      });
    }
  }
  
  reciveStatus($event)
  {
    this.status=$event;
  }
}
