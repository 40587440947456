import { Title, Meta } from "@angular/platform-browser";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { SeoMaster } from "./../../shared/enums/seo.master";
import { ContainerMaster } from "./../../shared/enums/container.master";
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  PLATFORM_ID,
  AfterViewInit
} from "@angular/core";
import { BaseContainer } from "./../base.container";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { from } from "rxjs";
declare var $: any;

@Component({
  selector: "app-home",
  templateUrl: "home.component.html",
  styleUrls: ["home.component.scss"]
})
export class HomeComponent extends BaseContainer
  implements OnInit, AfterViewInit {
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  private carouselToken: string;
  url: string = '';
  status = true;
  slides = [
    { img: "assets/images/clients/1.jpg" },
    { img: "assets/images/clients/2.jpg" },
    { img: "assets/images/clients/3.jpg" },
    { img: "assets/images/clients/4.jpg" },
    { img: "assets/images/clients/5.jpg" },
    { img: "assets/images/clients/6.jpg" },
    { img: "assets/images/clients/7.jpg" },
    { img: "assets/images/clients/8.jpg" },
    { img: "assets/images/clients/9.jpg" },
    { img: "assets/images/clients/10.jpg" },
    { img: "assets/images/clients/11.jpg" },
    { img: "assets/images/clients/12.jpg" },
    { img: "assets/images/clients/13.jpg" },
    { img: "assets/images/clients/14.jpg" },
    { img: "assets/images/clients/15.jpg" },
    { img: "assets/images/clients/16.jpg" },
    { img: "assets/images/clients/17.jpg" },
    { img: "assets/images/clients/18.jpg" }
  ];
  serviceData = [
    {
      // icon: "fa fa-times",
      text: "Companies Act 2013 Stationery",
      redirect: "companyact",
      para: "Share Certificate,Common seal and Combined register for Companies",
      img1: "assets/images/product-icon/companies-act-orange.png",
      img2: "assets/images/product-icon/companies-act-white.png"
    },
    {
      // icon: "fa fa-times",
      text: "Payroll & HR Software",
      redirect: "hrslip",
      para: "All-in-One online payroll software",
      img1: "assets/images/product-icon/service_icon_03.png",
      img2: "assets/images/product-icon/service_icon_03-w.png"
    },
    {
      // icon: "fa fa-times",
      text: "TDS Management Software",
      redirect: "tdsmanagement",
      para: "Complete & user friendly TDS software",
      img1: "assets/images/product-icon/service_icon_05.png",
      img2: "assets/images/product-icon/service_icon_05-w.png"
    },
    {
      // icon: "fa fa-times",
      text: "eTDS Wizard",
      redirect: "etdswizard",
      para: "Software for filing Quarterly eTDS Returns as per the NSDL format",
      img1: "assets/images/product-icon/e-tds.png",
      img2: "assets/images/product-icon/e-tds-w.png"
    },
    // {
    //   // icon: "fa fa-times",
    //   text: "IT Return e-Filing Software",
    //   redirect: "itreturn",
    //   para: "File Income Tax Returns online",
    //   img1: "assets/images/product-icon/service_icon_07.png",
    //   img2: "assets/images/product-icon/service_icon_07-w.png"
    // },
    {
      // icon: "fa fa-times",
      text: "26AS RECONCILER",
      redirect: "26as",
      para: "26AS RECONCILER",
      img1: "assets/images/product-icon/service_icon_07.png",
      img2: "assets/images/product-icon/service_icon_07-w.png"
    },
    {
      // icon: "fa fa-times",
      text: "XBRL Filing Software",
      redirect: "xbrl",
      para: "Generate XBRL Instance document at click of a button",
      img1: "assets/images/product-icon/service_icon_02.png",
      img2: "assets/images/product-icon/service_icon_02-w.png"
    },
    // {
    //   text: "Company Secretary Automation Software",
    //   redirect: "companysecretary",
    //   para: "Storage of Secretarial Documents at a single place",
    //   img1: "assets/images/product-icon/company-secretory-automate-software-Orange.png",
    //   img2: "assets/images/product-icon/company-secretory-automate-software-white.png"
    // },
    {
      // icon: "fa fa-times",
      text: "Fixed Asset Management Software",
      redirect: "fixedasset",
      para: "Track & Manage Fixed Assets",
      img1: "assets/images/product-icon/service_icon_06.png",
      img2: "assets/images/product-icon/service_icon_06-w.png"
    },
    {
      // icon: "fa fa-times",
      text: "PDF Signer",
      redirect: "pdfsigner",
      para: "Hassle free digital signing of certificates",
      img1: "assets/images/product-icon/service_icon_08.png",
      img2: "assets/images/product-icon/service_icon_08-w.png"
    },
    {
      // icon: "fa fa-times",
      text: "Proactly - Web Based Compliance Management Software",
      redirect: "proactly",
      para: " Compliance Management Software",
      img1: "assets/images/product-icon/web-assets-orange.png",
      img2: "assets/images/product-icon/web-assets-white.png"
    },


    
  ];
  slideConfig = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    "autoplay-speed": 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 600,
        prevArrow: false,
        nextArrow: false,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: any,
    meta: Meta,
    title: Title,
    componentManagerService: ComponentManagerService,
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    super(meta, title, componentManagerService);
  }

  ngOnInit() {
    this.url = this.activatedRoute.snapshot.routeConfig.path;
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href','https://www.taxprint.com')
  }

  setId() {
    return ContainerMaster.HOME;
  }
  setMetaTitle() {
    //return SeoMaster.HOME.title;
    return 'Best HRMS & Payroll Management Software in Mumbai India |  Tax Print';
  }
  setMetaTags() {
    return [
      { name: "keywords", content: SeoMaster.HOME.keyword },
      { name: "description", content: "Tax Print : Simplify HR & Payroll system in Mumbai India!  Discover top-rated  best HRMS and Payroll software for taxes, compliance & employee management." },
      { name: "og:title", content: SeoMaster.HOME.ogtitle },
      { name: "og:type", content: SeoMaster.HOME.ogtype },
      { name: "og_url", content: SeoMaster.HOME.ogurl },
      { name: "og:image:secure_url", content: SeoMaster.HOME.ogimage },
      { name: "og:description", content: SeoMaster.HOME.ogdescription },
      { name: "og:site_name", content: SeoMaster.HOME.ogsitename }
    ];
  }
  actionFromPlugin(params) { }

  ngAfterViewInit() {
    if (this.isBrowser) {
      $(".hover").mouseleave(function () {
        $(this).removeClass("hover");
      });
    }
  }

  clickEvent() {
    this.status = !this.status;
  }

  scrolltopage() {
    $(".m-40")[0].scrollIntoView();
  }

  reciveStatus($event) {
    this.status = $event;
  }

}


