import { Title, Meta } from "@angular/platform-browser";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { SeoMaster } from "./../../shared/enums/seo.master";
import { ContainerMaster } from "./../../shared/enums/container.master";
import { Component, OnInit, Inject, PLATFORM_ID, ViewChild } from "@angular/core";
import { BaseContainer } from "./../base.container";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { SignupFormComponent } from "../../plugin/signup-form/signup-form.component";
import { ProdSubComponent } from "../../plugin/saleNote/prodSubYMM.component";
import { ToasterService } from "../../services/utility-services/toaster.service";
declare var Instamojo: any;
declare var $: any;
declare var window: any;
const prod = {
  multiple: {
    ID: 'PROD62',
    QTY: 1,
    productType: 'SOFTWARE',
    period: undefined,
    PROD_CONFIG_DISP: '#PROD_SUB_YMM',
    licenseType: 'NEW'
  }
}
@Component({
  selector: "app-etdsnext",
  templateUrl: "./etdsnext.component.html",
  styleUrls: ["./etdsnext.component.scss"]
})
export class EtdsNextComponent extends BaseContainer implements OnInit {
  //payLink: string;

  tdsDetails: any = [];
  finalPrice: any = [];
  variantName: any = [];
  subprodName: any = [];
  mprice: any;
  sprice: any;
  tdsProductid: any = [];
  prodObj = {};
  buyOption: string;

  dropDownText = 'Select';

  //single: string = "single";
  multiple: string = "multiple";
  //rates: any;
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  prodDetails: any;
  @ViewChild(SignupFormComponent) public customerForm: SignupFormComponent;
  @ViewChild(ProdSubComponent) public prodPeriodForm: ProdSubComponent;
  etdsprice: any;
  newsprice: any;
  newmprice: any;
  renewsprice: any;
  renewmprice: any;
  proactlyDetails: any;
  prodCheckoutArr = [];
  prodCheckoutObj = {
    QTY: 1,
    PID: null,
    SPID: null,
    VID: '',
    VAMT: null,
    PTYPE: null,
    VAR_NAME: null,
  };
  prodObjCheckout: any;
  productPrice: any;
  licenseType: string;
  status: boolean = false;
  proactprice: any;
  total: any;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: any,
    meta: Meta,
    title: Title,
    componentManagerService: ComponentManagerService, private toasterService: ToasterService
  ) {
    super(meta, title, componentManagerService);
    //default selected buyOption
    this.buyOption = this.multiple;
    window['that'] = this;
  }

  ngOnInit() {
    this.prodDetails = prod.multiple;
    this.getProductDetails();
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',this.doc.URL)
  }

  setId() {
    return ContainerMaster.PROACTLY;
  }
  setMetaTitle() {
    return SeoMaster.ETDSNEXT.title;
  }
  setMetaTags() {
    return [
      { name: "keywords", content: SeoMaster.ETDSNEXT.keyword },
      { name: "description", content: SeoMaster.ETDSNEXT.description },
      { name: "og:title", content: SeoMaster.ETDSNEXT.ogtitle },
      { name: "og:type", content: SeoMaster.ETDSNEXT.ogtype },
      { name: "og_url", content: SeoMaster.ETDSNEXT.ogurl },
      { name: "og:image:secure_url", content: SeoMaster.ETDSNEXT.ogimage },
      { name: "og:description", content: SeoMaster.ETDSNEXT.ogdescription },
      { name: "og:site_name", content: SeoMaster.ETDSNEXT.ogsitename }
    ];
  }
  actionFromPlugin(params) { }
  ngAfterViewInit() {
    /* Configuring Handlers */
    Instamojo.configure({
      handlers: {
        onOpen: this.onOpenHandler,
        onClose: this.onCloseHandler,
        onSuccess: this.onPaymentSuccessHandler,
        onFailure: this.onPaymentFailureHandler
      }
    });
    if (this.isBrowser) {
      const that = this;
      $(window).bind("scroll", function () {
        if ($(window).scrollTop() > 150) {
          $("#proactly").addClass(
            "fixed-heading navbar-fixed-section slideInDown");
          $('.fixed-heading h1').addClass('text-right');
          $('#proactly img').removeClass("d-none");
          $("#proactly img").css("height", "50px");
          $('#proactly').css({ "display": "flex", "padding": "0 120px" });
        } else {
          $("#proactly").removeClass(
            "fixed-heading navbar-fixed-section slideInDown");
          $('#proactly h1').removeClass('text-right');
          $('#proactly img').addClass("d-none");
          $("#proactly img").css("height", "auto");
          $('#proactly').css({ "display": "block", "padding": "0" });
        }
      });

      if (!this.componentManagerService.isMobile) {
        $("#social-float").addClass("top-146");
        $("#social-float").addClass("fix-pos");
        $(document).scroll(function () {
          if (
            $("#social-float").offset().top + $("#social-float").height() >=
            $("#footer").offset().top
          ) {
            $("#social-float").addClass("positionabs");
            $("#social-float").removeClass("fix-pos");
          }
          if (
            $(document).scrollTop() + window.innerHeight <
            $("#footer").offset().top
          ) {
            $("#social-float").addClass("fix-pos");
            $("#social-float").removeClass("positionabs");
            if ($(window).scrollTop() > 150) {
              $("#social-float").addClass("top-73");
              $("#social-float").removeClass("top-146");
            } else {
              $("#social-float").addClass("top-146");
              $("#social-float").removeClass("top-73");
            }
          }
        });
      }
    }
  }

  onOpenHandler() {
    //console.log("Payments Modal is Opened");
  }

  onCloseHandler() {
    //console.log("Payments Modal is Closed");
  }

  onPaymentSuccessHandler(response) {
    $.ajax({
      type: "POST",
      cache: false,
      url: "api/purchasePaymentResult",
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({
        transID: window['transID'], pgPaymentID: response.paymentId, pgPayClientRes: response,
        paymentID: window['paymentID']
      }),
      success: function () {
        let transID = window['transID'];
        // window['transID'] = undefined;
        // window['paymentID'] = undefined;
        if (window['that'].isBrowser) {
          $('.modal-backdrop').css({ 'display': 'none' });
        }
        window['that'].navigateTo('thankyou', transID);
      }
    });


    if (response.status.toLowerCase() == "success") {
    } else {
      alert("payment failed");
    }
  }

  onPaymentFailureHandler(error) {
    alert("Error in payment. Please try again");
  }

  /*initPayment() {
    this.payLink = this.rates[this.buyOption];
    if (!this.payLink) {
      alert("Please contact on support number");
    } else {
      Instamojo.open(this.payLink);
    }
  }*/

  buyNow(params, qtyID: string) {
    if (this.isBrowser) {
      let qty = $(`#${qtyID}`).val();
      if (!!qty && parseInt(qty) > 0) {
        $('#signUp').modal('show');
        this.prodDetails = prod.multiple;
        this.prodCheckoutObj['DELIVERY_MODE'] = 'IN_HAND';
        this.prodCheckoutObj['DISCOUNT'] = 0;
        this.prodDetails.period = this.prodPeriodForm.duration;//this.period;
        this.prodDetails.licenseType = this.licenseType;

        this.prodDetails.QTY = qty;
        this.customerForm.ngOnChanges();

      }

    }
  }


  setPayID(event) {
    window['transID'] = event.SALE_ID;
    window['paymentID'] = event.paymentID;
  }
  setLicenseType(licenseType) {
    this.licenseType = licenseType
  }
  reciveStatus($event) {
    this.status = $event;
  }
  getProductDetails() {
    this.callDataJunction('GET_PRODUCT_DETAILS', { ID: 27 }).subscribe(res => {
      this.tdsDetails = res.data;
      console.log('res', res);
      this.proactlyDetails = res.data;
      const tdsObj = { new: [], renew: [], amc: [], none: [] };
      this.tdsDetails.forEach(e => {
        const dName = `${e['PRODUCT_NAME']} - ${e['SUB_PROD_NAME']} - (${e['VARIANT_NAME']})`

        let obj: any;
        if (e['GROUP_TYPE'] === 'NEW') {
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: dName, TYPE: e['GROUP_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.new.push(obj);
        } else if (e['GROUP_TYPE'] === 'RENEW') {
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: dName, TYPE: e['GROUP_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.renew.push(obj);
        } else if (e['GROUP_TYPE'] === 'AMC') {
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: dName, TYPE: e['GROUP_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.amc.push(obj);
        } else if (e['GROUP_TYPE'] === null) {
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: dName, TYPE: e['GROUP_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.none.push(obj);
        }
      });
      for (let t in tdsObj) {
        tdsObj[t].sort((a, b) => {
          return a.PROD_SEQ - b.PROD_SEQ;
        });
      }
      this.tdsDetails = tdsObj;
      if (!!this.tdsDetails['new'] && this.tdsDetails['new'].length > 0) {
        this.productPrice = this.tdsDetails['new'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['new'][0]['VID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['new'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.PID = this.tdsDetails['new'][0]['PID'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['new'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['new'][0]['SPID'];

      } else if (!!this.tdsDetails['renew'] && this.tdsDetails['renew'].length > 0) {
        this.productPrice = this.tdsDetails['renew'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['renew'][0]['VID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['renew'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.PID = this.tdsDetails['renew'][0]['PID'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['renew'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['renew'][0]['SPID'];

      } else if (!!this.tdsDetails['amc'] && this.tdsDetails['amc'].length > 0) {
        this.productPrice = this.tdsDetails['amc'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['amc'][0]['VID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['amc'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.PID = this.tdsDetails['amc'][0]['PID'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['amc'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['amc'][0]['SPID'];

      } else if (!!this.tdsDetails['none'] && this.tdsDetails['none'].length > 0) {
        this.productPrice = this.tdsDetails['none'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['none'][0]['VID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['none'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.PID = this.tdsDetails['none'][0]['PID'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['none'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['none'][0]['SPID'];

      }

    }, err => {
      console.log(err);
    });

  }
  getObjectKeys(obj) {
    return Object.keys(obj);
  }
  onChangeVariant(event) {
    this.productPrice = this.prodObj[event.target.value]['Proactly'];
  }
  getProductValues() {
    const arr = this.proactlyDetails.filter(x => x.VARIANT_ID === parseInt(this.prodCheckoutObj.VID));
    this.productPrice = arr[0]['FINAL_PRICE'].toFixed(2);
    this.productPrice = Math.round(this.productPrice);
    this.prodCheckoutObj.PID = arr[0]['PROD_ID'];
    this.prodCheckoutObj.SPID = arr[0]['SUB_PROD_ID'];
    this.prodCheckoutObj.VAMT = arr[0]['FINAL_PRICE'];
    this.prodCheckoutObj.PTYPE = arr[0]['PRODUCT_TYPE'];
    this.prodCheckoutObj.VAR_NAME = arr[0]['VARIANT_NAME'];
  }
  addCart() {
    this.prodCheckoutObj['SUBGROUPS'] = null;
    this.prodCheckoutObj['DELIVERY_MODE'] = 'IN_HAND';
    this.prodCheckoutObj['DISCOUNT'] = 0;
    this.prodCheckoutObj['TOTAL'] = (this.prodCheckoutObj.VAMT * this.prodCheckoutObj.QTY);
    const proObj = { ...this.prodCheckoutObj };
    this.componentManagerService.cartArr.push(proObj);
    this.componentManagerService.subTotalPrice();
    this.toasterService.showToaster(`${this.prodCheckoutObj.VAR_NAME} added to cart`, 'toast-success');
  }
}
