import { Title, Meta } from "@angular/platform-browser";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { SeoMaster } from "./../../shared/enums/seo.master";
import { ContainerMaster } from "./../../shared/enums/container.master";
import { Component, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { BaseContainer } from "./../base.container";
import { isPlatformBrowser } from "@angular/common";
declare var Instamojo: any;
declare var $: any;
declare var window: any;
@Component({
  selector: "app-companysecretary",
  templateUrl: "./companysecretary.component.html",
  styleUrls: ["./companysecretary.component.scss"]
})
export class CompanySecretaryComponent extends BaseContainer implements OnInit {
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  payLink: string;
  buyOption: string;
  single: string = "single";
  multiple: string = "multiple";
  rates: any;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    meta: Meta,
    title: Title,
    componentManagerService: ComponentManagerService
  ) {
    super(meta, title, componentManagerService);
    //default selected buyOption
    this.buyOption = this.single;
    this.rates = {
      mnb:
        "https://www.instamojo.com/@taxprint/l181b03e0d3074dc1b59cc33aa3a88200/",
      mpgl:
        "https://www.instamojo.com/@taxprint/lfa9d31aa52324ad9af95ff0ee056a258/",
      mpw:
        "https://www.instamojo.com/@taxprint/l0fa920380e20462ba5726795e34f253c/",
      sc2013:
        "https://www.instamojo.com/@taxprint/l0531501442474f6993e124754a746912/",
      sscs:
        "https://www.instamojo.com/@taxprint/laea3ec5fc5c7465f9b330f0873ba94a8/",
      scr1314:
        "https://www.instamojo.com/@taxprint/l82804dad442f4f12bc1d2696b4603c7a/",
      multiple: ""
    };
  }

  ngOnInit() {}

  setId() {
    return ContainerMaster.COMPANY_SEC;
  }
  setMetaTitle() {
    return SeoMaster.COMPANY_SEC.title;
  }
  setMetaTags() {
    return [
      { name: "keywords", content: SeoMaster.COMPANY_SEC.keyword },
      { name: "description", content: SeoMaster.COMPANY_SEC.description },
      { name: "og:title", content: SeoMaster.COMPANY_SEC.ogtitle },
      { name: "og:type", content: SeoMaster.COMPANY_SEC.ogtype },
      { name: "og_url", content: SeoMaster.COMPANY_SEC.ogurl },
      { name: "og:image:secure_url", content: SeoMaster.COMPANY_SEC.ogimage },
      { name: "og:description", content: SeoMaster.COMPANY_SEC.ogdescription },
      { name: "og:site_name", content: SeoMaster.COMPANY_SEC.ogsitename }
    ];
  }
  actionFromPlugin(params) {}
  ngAfterViewInit() {
    /* Configuring Handlers */
    Instamojo.configure({
      handlers: {
        onOpen: this.onOpenHandler,
        onClose: this.onCloseHandler,
        onSuccess: this.onPaymentSuccessHandler,
        onFailure: this.onPaymentFailureHandler
      }
    });
    if (this.isBrowser) {
      const that = this;
      $(window).bind("scroll", function() {
        if ($(window).scrollTop() > 150) {
          $("#compsec").addClass(
            "fixed-heading navbar-fixed-section slideInDown"
          );
          // $('.navbar-brand img').addClass('smaller');
        } else {
          $("#compsec").removeClass(
            "fixed-heading navbar-fixed-section slideInDown"
          );
          // $('.navbar-brand img').removeClass('smaller');
        }
      });

      if (!this.componentManagerService.isMobile) {
        $("#social-float").addClass("top-146");
        $("#social-float").addClass("fix-pos");
        $(document).scroll(function() {
          if (
            $("#social-float").offset().top + $("#social-float").height() >=
            $("#footer").offset().top
          ) {
            $("#social-float").addClass("positionabs");
            $("#social-float").removeClass("fix-pos");
          }
          if (
            $(document).scrollTop() + window.innerHeight <
            $("#footer").offset().top
          ) {
            $("#social-float").addClass("fix-pos");
            $("#social-float").removeClass("positionabs");
            if ($(window).scrollTop() > 150) {
              $("#social-float").addClass("top-73");
              $("#social-float").removeClass("top-146");
            } else {
              $("#social-float").addClass("top-146");
              $("#social-float").removeClass("top-73");
            }
          }
        });
      }
    }
  }

  onOpenHandler() {
    console.log("Payments Modal is Opened");
  }

  onCloseHandler() {
    console.log("Payments Modal is Closed");
  }

  onPaymentSuccessHandler(response) {
    console.log("Payment Success Response", response);
    /*paymentId: "MOJO8a03005N48931427"
status: "success"*/
    if (response.status.toLowerCase() == "success") {
    } else {
      alert("payment failed");
    }
    //this.processPayment(response.paymentId||undefined, JSON.stringify(response));
  }

  onPaymentFailureHandler(error) {
    console.log("Payment Failure Response", error);
    alert("Error in payment");
    //alert(error);
    //this.processPayment(undefined , JSON.stringify(error));
  }

  processPayment(pgPayID, paymentDetailsJSONStr) {
    // call route of api/purchasePaymentResult
    /*pgPaymentID: pgPayID,
          pgPayClientRes: paymentDetailsJSONStr, cpsf:1, tc: 'PG', payLink: this.payLink*/
  }

  initPayment() {
    this.payLink = this.rates[this.buyOption];
    if (!this.payLink) {
      alert("Please contact on support number");
    } else {
      Instamojo.open(this.payLink);
    }
  }
}
