export const ContainerMaster = {
  ABOUT_US: 'C01',
  BIOMETRIC: 'C02',
  COMPANY_ACT: 'C03',
  COMPANY_SEC: 'C04',
  CONTACT_US: 'C05',
  DSC: 'C06',
  ETDS: 'C07',
  FIXEDASSET: 'C08',
  GST_SOFTWARE: 'C09',
  HOME: 'C10',
  ITRETURN: 'C11',
  PAYROLL: 'C12',
  PDF_SIGNER: 'C13',
  PFEXPRESS: 'C14',
  TDS_MNGMNT: 'C15',
  WEB_FIXEDASSET: 'C16',
  XBRL: 'C17',
  THANKYOU: 'C18',
  ADMIN: 'C19',
  ADMIN_DASHBOARD: 'C20',
  REPORT: 'C21',
  OFFLINE_SALES: 'C22',
  DELIVERY_STATUS: 'C23',
  MANAGE_KEYS: 'C24',
  NOTIFIACTION: 'C25',
  USERS: 'C26',
  CHANGE_PASSWORD: 'C27',
  PROFILE: 'C28',
  ADMIN_LOGIN: 'C29',
  USER_DASHBOARD: 'C30',
  USER_NOTIFICATION: 'C31',
  ORDERS: 'C32',
  CREATE_PRODUCT_KEYS: 'C33',
  USER_CHANGE_PASSWORD: 'C34',
  WELCOME_ADMIN: 'C35',
  PAYMENTS: 'C36',
  OPERATORS: 'C37',
  REPORT_EXPORT: 'C38',
  PROACTLY: 'C39',
  VIEWCART: 'C40',
  CHECKOUT: 'C41',
  HRSLIP: 'C42',
  DESKTOPBASEDPAYROLL: 'C43',
  CLOUDBASEDPAYROLL: 'C44',
  WHYTAXPRINT: 'C45',
  GENERATEPDF: 'C46',
  SHARECERT: 'C47',
  SHARE_CERTIFICATES: 'C48',
  STATUTORY_REGISTER: 'C49',
  MINUTES_BINDER: 'C50', 
  MINUTES_PAPER_LEDGER: 'C50', 
  MINUTES_PAPER_WHITE: 'C51', 
  COMPANY_SEAL: 'C52',
  ETDS_NEXT: 'C53' ,
  NOTFOUND:'C54'
  
};
