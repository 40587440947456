import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class AuthenticationService extends BaseService {
  // adminForgetPassword(componentManagerModel: import("../../models/container.model").ComponentManagerModel): import("rxjs").Observable<any> {
  //   throw new Error("Method not implemented.");
  // }
  constructor(private http: HttpClient) {
    super();
  }
  
  private userLoginUrl = 'api/login';
  private adminLoginUrl = 'api/adminLogin';
  private userRegisterUrl = 'api/userRegister';
  private changeAdminPasswordUrl = 'api/admin/cp';
  private changeUserPasswordUrl = 'api/changePassword';
  private sendTokenUrl = 'api/verifyEmail';
  //private sendTokenUrl = 'api/sendToken';
  private forgetPasswordTokenURL = 'api/passwordToken';
  private forgetPasswordURL = 'api/setPassword';
  private adminForgetPasswordURL = 'api/admin/fp' ; 
  private adminSetPwdUrl = 'api/admin/sp' ; 
  private UserForgetPassword = 'api/userForgetPassword';

  adminLogin(val: any) {
    return this.http.post(this.adminLoginUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('adminLogin'))
    );
  }

  userLogin(val: any) {
    return this.http.post(this.userLoginUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('userLogin'))
    );
  }

  changeAdminPassword(val: any) {
    return this.http.post(this.changeAdminPasswordUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('changeAdminPassword'))
    );
  }
  changeUserPassword(val: any) {
    return this.http.post(this.changeUserPasswordUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('changeUserPassword'))
    );
  }

  userRegister(val: any) {
    return this.http.post(this.userRegisterUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('userRegister'))
    );
  }

  sendToken(val: any) {
    return this.http.post(this.sendTokenUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('sendToken'))
    );
  }

  forgetPasswordToken(val: any) {
    return this.http.post(this.forgetPasswordTokenURL, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('forgetPasswordToken'))
    );
  }
  forgetPassword(val: any) {
    return this.http.post(this.forgetPasswordURL, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('forgetPassword'))
    );
  }
  adminForgetPassword(val: any) {
    return this.http.post(this.adminForgetPasswordURL, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('adminForgetPassword'))
    );
  }
  adminsSetPassword(val: any) {
    return this.http.post(this.adminSetPwdUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('adminForgetPassword'))
    );
  }

  
  userForgetPassword(val: any) {
    return this.http.post(this.UserForgetPassword, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('userForgetPassword'))
    );
  }
}

