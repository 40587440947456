export class Cart {
  ITEMS: Array<any>;
  CART_AMOUNT: number;
  ORDER_DATE: Date;
  LANDING_URL: string;
  USER_ID: number;
  CUSTOMER_DISCOUNT: number;
  CUSTOMER_DETAILS: any;
  PAYMENT_MODE: string;
  seal:any;
  certificate:any;
  DELIVERY_MODE: string;
  TRAN_MODE: string;
  constructor() {
    this.ORDER_DATE = new Date();
    this.ITEMS = new Array<any>();
    this.CART_AMOUNT = 0;
    this.CUSTOMER_DISCOUNT = 0;
    this.USER_ID = 0;
    this.seal={
      company: '',
      address:'',
      city: ''
    },
    this.certificate = {
      company: '',
      CIN: '',
      address:'',
      city: '',
      pincode: '',
      stateName: '',
      shareType: '',
      faceValue:'',
      shareCertiDateStart: '',
      shareCertiDateEnd: ''
    }
    
  }
}