import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
// import { ComponentManagerService } from '../../services/component-manager.service';

@Injectable()

export class CartService {
  products: any[] = [];
  cartTotal: number = 0;
  private productAddedSource = new Subject<any>()
  productAdded$ = this.productAddedSource.asObservable()
  customerInvoiceWithTax = { baseAmt: 0.00, CGST: 0.00, IGST: 0.00, SGST: 0.00, totalAmt: 0.00, CUSTOMER_DISCOUNT: 0.00, OFFER_APPLIED: undefined };

  constructor() { }

  /***
 * func is used to check if product can be added into cart 
 * calculate array products with list of products added into cart
 */
  addProductToCart(product) {
    let exists = false;
    let parsedPrice = product.SELLING_PRICE;
    this.cartTotal += parsedPrice;
    //Search this product on the cart and increment the QTY
    this.products = this.products.map(_product => {
      if (_product.product.ID == product.ID) {
        _product.QTY++;
        _product.product.QTY++;
        exists = true;
      }
      return _product;
    })
    //Add a new product to the cart if it's a new product
    if (!exists) {
      this.products.push({
        product: product,
        QTY: 1
      })

    }
    this.productAddedSource.next({ products: this.products, cartTotal: this.cartTotal })

  }

  reduceProductQtyFromCart(product, reduceBy: number = 1) {
    this.products = this.products.filter(_product => {
      if (_product.product.ID == product.ID) {
        this.cartTotal -= _product.product.SELLING_PRICE * reduceBy;
        _product.QTY -= reduceBy;
        _product.product.QTY -= reduceBy; 
        return (_product.QTY > 0);
      }
      return true;
    })
    this.cartTotal = +(this.cartTotal.toFixed(2));
    this.productAddedSource.next({ products: this.products, cartTotal: this.cartTotal })
  }

  deleteProductFromCart(product) {
    this.products = this.products.filter(_product => {
      if (_product.product.ID == product.ID) {
        this.cartTotal -= _product.product.SELLING_PRICE * _product.QTY;
        // this.componentManagerService.cartTotal -= _product.product.SELLING_PRICE * _product.QTY;
        _product.QTY = 0;
        _product.product.QTY = 0;
        return false;
      }
      return true;
    })
    // this.componentManagerService.products = this.products;
    this.cartTotal = +(this.cartTotal.toFixed(2));
    // this.componentManagerService.cartTotal = +(this.cartTotal.toFixed(2));;
    this.productAddedSource.next({ products: this.products, cartTotal: this.cartTotal })
    /*if(!!product.prodGrp) {
      let index = this.prodGrps.indexOf(product.prodGrp);
      if (index > -1) {
        this.prodGrps.splice(index, 1);
      }
    }*/
  }


  flushCart() {
    this.products = [];
    this.cartTotal = 0;
    this.productAddedSource.next({ products: this.products, cartTotal: this.cartTotal })
    this.customerInvoiceWithTax = { baseAmt: 0.00, CGST: 0.00, IGST: 0.00, SGST: 0.00, totalAmt: 0.00, CUSTOMER_DISCOUNT: 0.00, OFFER_APPLIED: undefined };
  }

  checkExistenceWithPropValue(prop: string, value: any) {
    for (let i = 0; i < this.products.length; i++) {
      let _productObj = this.products[i];
      if (_productObj && _productObj.product && _productObj.product.hasOwnProperty(prop)) {
        return (_productObj.product[prop] === value);
      } else {
        return false;
      }
    }
  }

}