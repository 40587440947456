import { Title, Meta } from '@angular/platform-browser';
import { ComponentManagerService } from './../../services/component-manager.service';
import { SeoMaster } from './../../shared/enums/seo.master';
import { ContainerMaster } from './../../shared/enums/container.master';
import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID  } from '@angular/core';

import { BaseContainer } from './../base.container';
import { LeadService } from '../../services/component-services/leadService.service';
import * as AWS from 'aws-sdk';
import * as moment from 'moment';
import { ActivatedRoute, Router  } from "@angular/router";
import { isPlatformBrowser, Location, LocationStrategy, PathLocationStrategy, DOCUMENT } from "@angular/common";
// import * as jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
declare var $: any;
declare var jsPDF: any;
declare let html2canvas: any;
const BUCKET: string = 'taxprintprod';
@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ["thankyou.component.scss"],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})

export class ThankyouComponent extends BaseContainer implements OnInit {
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  globalConfig:any;  
  invoiceDetails: any;
  discAmt: any;
  netAmt: any;
  netDiscAmt: any;
  taxAmt: any;
  taxVal: any;
  deliveryTax: any;
  totalDelivery: any;
  totalAmt: any;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,meta: Meta, title: Title, componentManagerService: ComponentManagerService, 
  private leadService: LeadService, private activatedRoute: ActivatedRoute, private location: Location, @Inject(DOCUMENT) private document: Document) {
    super(meta, title, componentManagerService);
  }

  ngOnInit() { 
    this.activatedRoute.params.subscribe(params => {
      // in case of thankyou for lead form success do not generate invoice
      if(params.saleID &&  params.saleID.toLowerCase() != 'leadsuccess') {
        let promiseArr = [];
        promiseArr.push(this.readS3());
        promiseArr.push(this.generateSaleInvoice(params.saleID));
        Promise.all(promiseArr).then(res => {
          let self = this;
          setTimeout(() => {
            self.invoiceDetails.title = '(ORIGINAL FOR RECIPIENT)';
            self.createAndUploadPDF();
          }, 2000)
        }).catch();
      } 
    });
  }

  setId() {
    return ContainerMaster.THANKYOU;
  }
  setMetaTitle() {
    return SeoMaster.THANKYOU.title;
  }
  setMetaTags() {
    return ([]);
  }
  actionFromPlugin(params) {
  }
  generateSaleInvoice(transID:any) {
    return new Promise((resolve, reject) => {
      const params = { ID: transID, withCompany: true };
      const payModesAcr = {
        'PG': 'Online',
        'STORE_CASH': 'Cash',
        'STORE_CHQ': 'Cheque',
        'NEFT': 'NEFT',
        'RTGS': 'RTGS',
        'CASH_DIR_BANK': 'Cash',
        'CHQ_DIR_BANK': 'Cheque'
      }
      this.callDataJunction('GET_INVOICE_DETAILS', params).subscribe(res => {
        this.invoiceDetails = res.data;
        if(!!this.invoiceDetails) {
          resolve();
        } else {
          reject();
          alert('There are some system error. Please try again or contact our support team.');
        }
      }, err => {
        reject();
        alert('There are some system error. Please try again or contact our support team.');
      });  
    })
    
  }

  // createAndUploadPDF() {
  //   if (this.isBrowser) {
  //     $('#invoice-page').removeClass('d-none');
  //     let self  = this;
  //     let l = {
  //       orientation: 'l',
  //       unit: 'pt',
  //       format: 'a4'
  //     };
  //     let pdf = new jsPDF('p', 'pt', 'a4', true);
  //     pdf.setFontSize(1);
  //     pdf.setPage(1080, 1920);
  //     pdf.setPage(1);
      
  //     pdf.html(document.getElementById('invoice-bill'), {
  //       html2canvas: {
  //         scale: 1 // default is window.devicePixelRatio
  //       },
  //       callback: function () {
  //         var pdfBase64 = pdf.output('arraybuffer');
  //         const fileparams = {
  //           basestring: pdfBase64, 
  //           fileName: `INV-${new Date().getFullYear()}-${self.invoiceDetails.saleDetail.ID}-proforma.pdf`
  //         }
  //         //$('#invoice-page').addClass('d-none');
  //         self.readS3().then(res => {
  //           const bucket = new AWS.S3(
  //             {
  //               accessKeyId: self.globalConfig.a,
  //               secretAccessKey: self.globalConfig.b,
  //               region: self.globalConfig.c
  //             });
  //           const params = {
  //             Bucket: `${BUCKET}/Invoice/${parseInt(moment(new Date()).format('YYYY'))}/${moment(new Date()).format('MMM')}`,
  //             Key: fileparams.fileName,
  //             Body: fileparams.basestring,
  //             ACL: 'public-read',
  //             ContentType: 'application/pdf',
  //             ContentDisposition: 'inline'
  //           };

  //           bucket.upload(params, function (err, data) {
  //             if (err) {
  //               alert('There are some system error in Generating Invoice. Please try again or contact our support team.');
  //               return false;
  //             }
  //             //update invoice url in SALE table
  //             if (!!data.Location) {
  //               self.callDataJunction('UPDATE_INVOICE_URL', {
  //                 ID: self.invoiceDetails.saleDetail.ID,
  //                 INVOICE_URL: data.Location
  //               }).subscribe(res => { });
  //             }
  //             return true;
  //           });
  //         }).catch()
  //       }
  //     });
  //   }
    
  // }

  createAndUploadPDF() {
    const self = this;
    const l = {
      orientation: 'l',
      unit: 'pt',
      format: 'a4'
    };

    
    if (this.isBrowser) {
      html2canvas(document.getElementById("invoice-bill"), { scale: 4}).then(canvas => {  

      var img=canvas.toDataURL("image/jpeg");
      var doc=new jsPDF();
      doc.addImage(img, "JPEG", 5, 5, 200, 250);
      //doc.save('myinvoice.pdf');
      const pdfBase64 = doc.output('arraybuffer');
      const fileparams = {
        basestring: pdfBase64,
        fileName: `INV-${new Date().getFullYear()}-${self.invoiceDetails.saleDetail.ID}-original.pdf`
      };
      $('#invoice-page').addClass('d-none');
      self.readS3().then(res => {
        const bucket = new AWS.S3(
          {
            accessKeyId: self.globalConfig.a,
            secretAccessKey: self.globalConfig.b,
            region: self.globalConfig.c
          });
        const params = {
          Bucket: `${BUCKET}/Invoice/${parseInt(moment(new Date()).format('YYYY'))}/${moment(new Date()).format('MMM')}`,
          Key: fileparams.fileName,
          Body: fileparams.basestring,
          ACL: 'public-read',
          ContentType: 'application/pdf',
          ContentDisposition: 'inline'
        };

        bucket.upload(params, function (err, data) {
          if (err) {
            alert('There are some system error in Generating Invoice. Please try again or contact our support team.');
            return false;
          }
          // update invoice url in SALE table
          if (!!data.Location) {
            self.callDataJunction('UPDATE_INVOICE_URL', {
              ID: self.invoiceDetails.saleDetail.ID,
              INVOICE_URL: data.Location
            }).subscribe(res => { });
          }
          return true;
        });
      }).catch();
      });
      
    }

  }

  

  readS3() {
    return new Promise((resolve, reject) => {
      if(this.globalConfig && this.globalConfig.a) {
        resolve();
      } else {
        this.callDataJunction('GET_S3_DETAILS', { ACTIVE: 1 }).subscribe(res => {
          if (res.data && res.data['a']) {
            this.globalConfig = res.data;
            resolve();
          } else {
            reject();
          }
        });    
      }  
    })        
  }
}