import { CookieService } from 'ngx-cookie';
import { Title, Meta } from '@angular/platform-browser';
import { ComponentManagerService } from '../../../services/component-manager.service';
import { SeoMaster } from '../../../shared/enums/seo.master';
import { ContainerMaster } from '../../../shared/enums/container.master';
import { Component, Inject, OnInit } from '@angular/core';
import { BaseContainer } from '../../base.container';
import { AdminLogin } from '../../../models/adminLogin.model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToasterService } from '../../../services/utility-services/toaster.service';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['admin-login.component.scss']
})

export class AdminLoginComponent extends BaseContainer implements OnInit {
  containerMaster = ContainerMaster;
  adminLogin: AdminLogin = new AdminLogin();
  LoginForm: FormGroup;
  ForgetPwd: FormGroup;
  show = true;
  constructor(@Inject(DOCUMENT) private doc: any,
  private formBuilder: FormBuilder, meta: Meta, title: Title, componentManagerService: ComponentManagerService, private toasterService: ToasterService, private cookieService: CookieService) {
    super(meta, title, componentManagerService);
  }

  ngOnInit() {
    this.createForm();
    this.createForgetPwd();
    if (this.componentManagerService.adminLoggedIn) {
      this.navigateTo('admin');


    }
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',this.doc.URL)
  }


  setId() {
    return ContainerMaster.ADMIN_LOGIN;
  }
  setMetaTitle() {
    return SeoMaster.ADMIN.title;
  }
  setMetaTags() {
    return ([]);
  }
  actionFromPlugin(params) {
  }

  createForm() {
    this.LoginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(60)]],
      password: ['', [
        Validators.required,
        Validators.minLength(5)
      ]]
    });
  }

  isFieldValid(field: string) {
    return !this.LoginForm.get(field).valid && this.LoginForm.get(field).touched;
  }
  createForgetPwd() {
    this.ForgetPwd = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(60),
        Validators.email
      ]]
    });

  }


  //#region Form Validation check
  // Highlights all the validations errors in a form
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  //#endregion

  onSubmit() {

    if (this.LoginForm.valid) {
      this.callDataJunction('ADMIN_LOGIN', this.adminLogin).subscribe(res => {
        // console.log(res.data.SCREENS);
        if (res.status) {
          this.componentManagerService.adminLoggedIn = true;
          this.componentManagerService.admin = res.data;
          this.cookieService.put('adminId', res.data.ID);
          const screenArr = res.data.SCREENS;
          let goToDashboard = true;
          
          for (let i = 0; i < screenArr.length; i++) {
            if (screenArr[i].DISPLAY === 1) {
              this.componentManagerService.displayScreen[screenArr[i]['SCREEN_ID'].toLowerCase().replace(/ /g, '')] = 1;
            }
            screenArr[i]['SCREENACCESS'] = JSON.parse(screenArr[i]['SCREENACCESS']);
          }

          if (goToDashboard) {
            this.callDataJunction('SET_ADMIN_LOGIN', this.componentManagerService.admin);
            this.navigateTo('admin');
            this.toasterService.showToaster(res.message, 'toast-success');
          } else {
            this.toasterService.showToaster('You have no screen access', 'toast-error');
          }

        } else {
          this.toasterService.showToaster(res.message, 'toast-error');
        }
      });

    } else {
      this.validateAllFormFields(this.LoginForm);
    }
  }
  onForget() {

    if (this.ForgetPwd.valid) {
      this.callDataJunction('ADMIN_FORGET_PASSWORD', this.adminLogin).subscribe(res => {
        if (res.status) {
          console.log('Done');
        } else {
          console.log('Sorry');
        }
      });

    } else {
      alert('kindly fill proper details');
      this.validateAllFormFields(this.ForgetPwd.value);
    }

  }

  forget(field: string) {
    return !this.ForgetPwd.get(field).valid && this.ForgetPwd.get(field).touched;
  }

  get f() { return this.ForgetPwd.controls; }
}
