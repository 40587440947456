import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class PaymentService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private paymentProcessUrl = 'api/paymentprocess';
  private addPaymentUrl = 'api/addPayment';
  private queryPaymentsUrl = 'api/paymentQuery';
  private queryGetPayByID = 'api/getPayByID';
  private queryPendingPaymentsUrl = 'api/paymentQuery';
  private offlinePaymentsUrl = 'api/addEditOfflinePayment';
  private getPaymentDetailUrl = 'api/getPaymentDetail';
  private getPaymentSaleDetailUrl = 'api/getPaymentSaleDetail';
  private refundPaymentProcessUrl = 'api/refundPaymentProcess';
  private updateChqDepUrl = 'api/updateChqDep';
  private cancelPayment = 'api/cancelPayment';
  private editPaymentUrl = 'api/editPayment';


  paymentProcess(val: any) {
    return this.http.post(this.paymentProcessUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('paymentProcess'))
    );
  }

  addPayment(val: any) {
    return this.http.post(this.addPaymentUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('addPayment'))
    );
  }

  loadPayments(val: any) {
    return this.http.post(this.queryPaymentsUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('loadPayments'))
    );
  }

  pendingPayments(val: any) {
    return this.http.post(this.queryPendingPaymentsUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('pendingPayments'))
    );
  }

  getPayByID(val: any) {
    return this.http.post(this.queryGetPayByID, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getPayByID'))
    );
  }
  getOfflinePayment(val: any) {
    return this.http.post(this.offlinePaymentsUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('offlinePayment'))
    );
  }
  getPaymentDetails(val: any) {
    return this.http.post(this.getPaymentDetailUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('Payment'))
    );
  }
  getPaymentSaleDetails(val: any) {
    return this.http.post(this.getPaymentSaleDetailUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('Payment'))
    );
  }

  refundPaymentProcess(val: any) {
    return this.http.post(this.refundPaymentProcessUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('Payment'))
    );
  }

  updateChqDep(val: any) {
    return this.http.post(this.updateChqDepUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('Payment'))
    );
  }
  paymentCancellation(val: any) {
    return this.http.post(this.cancelPayment, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('Payment'))
    );
  }

  editPayment(val: any) {
    return this.http.post(this.editPaymentUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('Payment'))
    );
  }

}
