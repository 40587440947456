import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class ReportService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private getLeadsUrl = 'api/leadQuery';
  private getLeadsByDateUrl = 'api/reports/date';
  private getSalesUrl = 'api/salesQuery';
  private getSalesByDateUrl = 'api/reports/date';
  private getSalesByIDUrl = 'api/getSalesByID';
  private getLeadByIDUrl = 'api/getLeadByID';
  private editSaleUrl = 'api/editSale';
  private getAllSalesUrl = 'api/getAllSales'

  getLeads(val: any) {
    return this.http.post(this.getLeadsUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getLeads'))
    );
  }

  getLeadsByDate(val: any) {
    return this.http.post(this.getLeadsByDateUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getLeadssByDate'))
    );
  }
  getSales(val: any) {
    return this.http.post(this.getSalesUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getSales'))
    );
  }

  editSale(val: any) {
    return this.http.post(this.editSaleUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('editSales'))
    );
  }
  getSalesByDate(val: any) {
    return this.http.post(this.getSalesByDateUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getSalesByDate'))
    );
  }

  getSalesByID(val: any) {
    return this.http.post(this.getSalesByIDUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getSalesByID'))
    );
  }

  getLeadByID(val: any) {
    return this.http.post(this.getLeadByIDUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getLeadByID'))
    );
  }

  getAllSales(val: any) {
    return this.http.post(this.getAllSalesUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getLeadByID'))
    );
  }
  

}
