import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class OfflineSalesService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private createOfflineSalesUrl = 'api/create/offline';
  private getUsersUrl = 'api/getUsers';
  private offlinesalesUrl = 'api/offlineSales';
  private getPendingSales = 'api/getPendingSales';
  private getTransferedSales = 'api/getTransferedSales';
  private leadToSale = 'api/leadToSale';

  createOfflineSales(val: any) {
    return this.http.post(this.createOfflineSalesUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('createOfflineSales'))
    );
  }

  getUsers(val: any) {
    return this.http.post(this.getUsersUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getUsers'))
    );
  }

  offlinesales(val: any) {
    return this.http.post(this.offlinesalesUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('offlinesales'))
    );
  }
  fetchPendingSales(val: any) {
    return this.http.post(this.getPendingSales, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('fetchPendingSales'))
    );
  }

  fetchTransferedSales(val: any) {
    return this.http.post(this.getTransferedSales, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('fetchPendingSales'))
    );
  }

  convertLeadToSale(val: any) {
    return this.http.post(this.leadToSale, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('fetchPendingSales'))
    );
  }

}
