import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { tap, catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class InventoryService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private addInventoryUrl = 'api/inventory/addInventory';
  private getInventoryUrl = 'api/inventory/getInventory';
  private updateInventoryUrl = 'api/inventory/updateInventory';
  private deleteInventoryUrl = 'api/inventory/deleteInventory';

  addinventory(val: any) {
    return this.http.post(this.addInventoryUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('addInventory'))
    );
  }
  getinventory(val: any) {
    return this.http.post(this.getInventoryUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('addInventory'))
    );
  }
  updateinventory(val: any) {
    return this.http.post(this.updateInventoryUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('updateInventory'))
    );
  }

  deleteInventory(val: any) {
    return this.http.post(this.deleteInventoryUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('updateInventory'))
    );
  }

}
