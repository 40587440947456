import { Title, Meta } from '@angular/platform-browser';
import { ComponentManagerService } from './../../services/component-manager.service';
import { SeoMaster } from './../../shared/enums/seo.master';
import { ContainerMaster } from './../../shared/enums/container.master';
import { Component, OnInit, AfterViewInit, Input, OnChanges } from '@angular/core';
import { BaseContainer } from './../base.container';
import { LeadService } from '../../services/component-services/leadService.service';
import * as moment from 'moment';
import { StateCodeMaster } from '../../services/utility-services/stateCodeMaster';
import { Router } from '@angular/router';
var numberToWords = require('number-to-words');

declare var $: any;
const DATE_DISPLAY_FORMAT: string = 'DD MMM YYYY';
const DIGITS_TEXT = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
const TENS_TEXT = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
const PAYMODES_ACR_TEXT = {
  'PG': 'Online',
  'STORE_CASH': 'Cash',
  'STORE_CHQ': 'Cheque',
  'NEFT': 'NEFT',
  'RTGS': 'RTGS',
  'CUST_BANK_DEPOSIT_CASH': 'Cash',
  'CUST_BANK_DEPOSIT_CHQ': 'Cheque',
  'IMPS': 'IMPS'
}
@Component({
  selector: 'app-invoice-pdf',
  templateUrl: './invoice-pdf.component.html',
  styleUrls: ['invoice-pdf.component.scss']
})

export class InvoicePdfComponent extends BaseContainer implements OnInit, OnChanges {
  @Input() invoiceDetails: any;
  discAmt: any;
  netAmt: any;
  netDiscAmt: any;
  taxAmt: any;
  taxVal: any;
  deliveryTax: any;
  totalDelivery: any;
  totalAmt: any;
  totalInWords;
  deliveryMode: string;
  courierName: string;
  dateOfDispatch: string;
  transportReceiptNO: string;
  transportAddr: string;
  transportContactPerson: string;
  transportContactNo: string;
  igstTaxAmt: any;
  sgstTaxAmt: any;
  cgstTaxAmt: any;
  totalTaxAmt: any;
  matchStr: any;
  pkgProducts: any;
  baseAmount: any;
  taxAmtInWords;
  showInvoice = false;
  stateCodeMaster = StateCodeMaster;
  rateTotal = 0;
  amtTotal = 0;
  // igstObj = {};
  // cgstObj = {};
  // sgstObj = {};
  taxObj: any = {};
  totalTaxVal: any = 0;
  totalCgstTaxAmt: any = 0;
  totalSgstTaxAmt: any = 0;
  totalIgstTaxAmt: any = 0;
  totalTaxAmtInWords: any;
  showDeliveryCharges = false;
  orderTotalBaseAmt: any = 0;
  orderTotalCgst = 0;
  orderTotalSgst = 0;
  orderTotalIgst = 0;
  objectKeys = Object.keys;
  totalQty = 0;
  constructor(meta: Meta, title: Title, componentManagerService: ComponentManagerService, private leadService: LeadService, public router: Router) {
    super(meta, title, componentManagerService);
    this.sgstTaxAmt = 0;
    this.igstTaxAmt = 0;
    this.cgstTaxAmt = 0;
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!!this.invoiceDetails) {
      this.showInvoice = true;
      let obj = {};
      let obj1 = {};
      let obj2 = {};
      let count = 0;
      this.invoiceDetails.orderDetails.forEach(e => {
        count++;
        if (e['SUB_PRODUCT_VARIANT_NAME'].search(/seal/i) > -1) {
          e['matchStr'] = 'seal';
        } else if (e['SUB_PRODUCT_VARIANT_NAME'].search(/certificate/i) > -1) {
          e['matchStr'] = 'certificate';
        } else if (e['SUB_PRODUCT_VARIANT_NAME'].search(/package/i) > -1) {
          e['matchStr'] = 'package';
        } else {
          e['matchStr'] = 'stationery';
        }
        e['netAmt'] = parseFloat(e['RATE']) * e['QTY'];

        if (!!e['IGST_PERC']) {
          const key = `${e['IGST_PERC']}g`
          if (this.taxObj.hasOwnProperty(key)) {
            this.taxObj[key]['taxAmt'] = this.convertToFloat(this.taxObj[key]['taxAmt']) + this.convertToFloat(e['IGST']);
          } else {
            obj = { taxTxt: `IGST (${e['IGST_PERC']})%`, taxAmt: e['IGST'], taxPerc: e['IGST_PERC'] };
            this.taxObj[key] = obj;
          }

          if (!!this.invoiceDetails.saleDetail.DELIVERY_CHARGE_BASE_AMT && count === this.invoiceDetails.orderDetails.length) {
            if (this.taxObj.hasOwnProperty('18g')) {
              this.taxObj['18g']['taxAmt'] = this.convertToFloat(this.convertToFloat(this.taxObj['18g']['taxAmt']) + (this.invoiceDetails.saleDetail.DELIVERY_CHARGE_CGST + this.invoiceDetails.saleDetail.DELIVERY_CHARGE_SGST));
            } else {
              const delTaxAmt =
                obj1 = { taxTxt: `IGST (18)%`, taxAmt: (this.invoiceDetails.saleDetail.DELIVERY_CHARGE_CGST + this.invoiceDetails.saleDetail.DELIVERY_CHARGE_SGST), taxPerc: '18' };
              this.taxObj['18g'] = obj1;
            }
          }
          this.orderTotalIgst = this.convertToFloat(this.orderTotalIgst) + this.convertToFloat(e['IGST']);
        } else {
          //for cgst
          const key1 = `${e['CGST_PERC']}c`
          if (this.taxObj.hasOwnProperty(key1)) {
            this.taxObj[key1]['taxAmt'] = this.convertToFloat(this.convertToFloat(this.taxObj[key1]['taxAmt']) + this.convertToFloat(e['CGST']));
          } else {
            obj1 = { taxTxt: `CGST (${e['CGST_PERC']})%`, taxAmt: e['CGST'], taxPerc: e['CGST_PERC'] };
            this.taxObj[key1] = obj1;
          }

          if (!!this.invoiceDetails.saleDetail.DELIVERY_CHARGE_BASE_AMT && count === this.invoiceDetails.orderDetails.length) {
            if (this.taxObj.hasOwnProperty('9c')) {
              this.taxObj['9c']['taxAmt'] = this.convertToFloat(this.convertToFloat(this.taxObj['9c']['taxAmt']) + this.invoiceDetails.saleDetail.DELIVERY_CHARGE_CGST);
            } else {
              const delTaxAmt =
                obj1 = { taxTxt: `CGST (9)%`, taxAmt: this.invoiceDetails.saleDetail.DELIVERY_CHARGE_CGST, taxPerc: '9' };
              this.taxObj['9c'] = obj1;
            }
          }
          this.orderTotalCgst = this.convertToFloat(this.orderTotalCgst) + this.convertToFloat(e['CGST']);


          // for sgst
          const key2 = `${e['SGST_PERC']}s`
          if (this.taxObj.hasOwnProperty(key2)) {
            this.taxObj[key2]['taxAmt'] = this.convertToFloat(this.convertToFloat(this.taxObj[key2]['taxAmt']) + this.convertToFloat(e['SGST']));
          } else {
            obj2 = { taxTxt: `SGST (${e['SGST_PERC']})%`, taxAmt: e['SGST'], taxPerc: e['SGST_PERC'] };
            this.taxObj[key2] = obj2;
          }

          if (!!this.invoiceDetails.saleDetail.DELIVERY_CHARGE_BASE_AMT && count === this.invoiceDetails.orderDetails.length) {

            if (this.taxObj.hasOwnProperty('9s')) {
              this.taxObj['9s']['taxAmt'] = this.convertToFloat(this.convertToFloat(this.taxObj['9s']['taxAmt']) + this.invoiceDetails.saleDetail.DELIVERY_CHARGE_SGST);
            } else {
              const delTaxAmt =
                obj1 = { taxTxt: `SGST (9)%`, taxAmt: this.invoiceDetails.saleDetail.DELIVERY_CHARGE_SGST, taxPerc: '9' };
              this.taxObj['9s'] = obj1;
            }
          }
          this.orderTotalSgst = this.convertToFloat(this.orderTotalSgst) + this.convertToFloat(e['SGST']);
        }
        this.orderTotalBaseAmt = this.convertToFloat(this.orderTotalBaseAmt) + this.convertToFloat(e['BASE_AMT']);
        if (e['PRODUCT_TYPE'] === 'STATIONERY' && e['DELIVERY_MODE'] === 'COURIER') {
          this.showDeliveryCharges = true;
        }
        if (!!e['PROD_DETAILED_NOTE']) {
          e['PROD_DETAILED_NOTE'] = JSON.parse(e['PROD_DETAILED_NOTE']);
        }
      });
      this.totalTaxVal = this.orderTotalBaseAmt + this.invoiceDetails.saleDetail.DELIVERY_CHARGE_BASE_AMT;
      this.totalCgstTaxAmt = this.orderTotalCgst + this.invoiceDetails.saleDetail.DELIVERY_CHARGE_CGST;
      this.totalSgstTaxAmt = this.orderTotalSgst + this.invoiceDetails.saleDetail.DELIVERY_CHARGE_SGST;
      this.totalIgstTaxAmt = this.orderTotalIgst + this.invoiceDetails.saleDetail.DELIVERY_CHARGE_IGST;
      this.invoiceDetails.saleDetail.EOE = true;
      this.invoiceDetails.saleDetail.NOTE = this.invoiceDetails.saleDetail.NOTE.replace(/\n/, '').replace(/\r/, '').replace(/\t/, '');
      this.invoiceDetails.saleDetail.NOTE = JSON.parse(this.invoiceDetails.saleDetail.NOTE);

      this.invoiceDetails.saleDetail.DELIVERY_CHARGE_IGST_PERC = parseFloat(this.invoiceDetails.saleDetail.DELIVERY_CHARGE_IGST_PERC);
      this.invoiceDetails.saleDetail.DELIVERY_CHARGE_SGST_PERC = parseFloat(this.invoiceDetails.saleDetail.DELIVERY_CHARGE_SGST_PERC);
      this.invoiceDetails.saleDetail.DELIVERY_CHARGE_CGST_PERC = parseFloat(this.invoiceDetails.saleDetail.DELIVERY_CHARGE_CGST_PERC);
      this.invoiceDetails.saleDetail.TRAN_DATE = moment(new Date(this.invoiceDetails.saleDetail.TRAN_DATE)).format(DATE_DISPLAY_FORMAT);

      this.invoiceDetails.customerDetail.stateCode = this.invoiceDetails.customerDetail.GST_NO ? this.invoiceDetails.customerDetail.GST_NO.substring(0, 2) : this.stateCodeMaster[this.invoiceDetails.saleDetail.B_STATE];
      this.invoiceDetails.customerDetail.GST_NO = this.invoiceDetails.customerDetail.GST_NO || 'Not Provided';

      this.netAmt = this.invoiceDetails.saleDetail.BASE_AMT;
      this.discAmt = (this.netAmt * (this.invoiceDetails.saleDetail.DISCOUNT / 100));
      this.netDiscAmt = (this.netAmt - this.discAmt);
      this.baseAmount = this.netDiscAmt + this.invoiceDetails.saleDetail.DELIVERY_CHARGE_BASE_AMT;
      this.totalTaxAmtInWords = this.inWords(this.invoiceDetails.saleDetail.GROSS_AMT_ROUND);
      let totalIgst = 0;
      let totalCgst = 0;
      let totalSgst = 0;
      let hsnCgObj = {};
      let hsnIgObj = {};
      this.invoiceDetails.orderDetails.forEach(e => {
        if (e['IGST_PERC'] > 0) {
          totalIgst += this.convertToFloat(e['IGST']);
        } else {
          totalCgst += this.convertToFloat(e['CGST']);
          totalSgst += this.convertToFloat(e['SGST']);
        }
        /// hsn grouping for cgst and sgst
        if (hsnCgObj.hasOwnProperty(e['HSN_CODE'])) {
          hsnCgObj[e['HSN_CODE']]['taxVal'] += this.convertToFloat(e['BASE_AMT']);
          hsnCgObj[e['HSN_CODE']]['cgAmt'] += this.convertToFloat(e['CGST']);
          hsnCgObj[e['HSN_CODE']]['sgAmt'] += this.convertToFloat(e['SGST']);
          hsnCgObj[e['HSN_CODE']]['totalTax'] += (this.convertToFloat(e['CGST']) + this.convertToFloat(e['SGST']))
        } else {
          hsnCgObj[e['HSN_CODE']] = {
            code: e['HSN_CODE'], taxVal: this.convertToFloat(e['BASE_AMT']),
            cgRate: e['CGST_PERC'], sgRate: e['SGST_PERC'], cgAmt: this.convertToFloat(e['CGST']), sgAmt: this.convertToFloat(e['SGST']),
            totalTax: (this.convertToFloat(e['CGST']) + this.convertToFloat(e['SGST']))
          }
        }
        /// hsn grouping for cgst and sgst end
        /// hsn grouping for igst
        if (hsnIgObj.hasOwnProperty(e['HSN_CODE'])) {
          hsnIgObj[e['HSN_CODE']]['taxVal'] += this.convertToFloat(e['BASE_AMT']);
          hsnIgObj[e['HSN_CODE']]['igAmt'] += this.convertToFloat(e['IGST']);
          hsnIgObj[e['HSN_CODE']]['totalTax'] += (this.convertToFloat(e['IGST']))
        } else {
          hsnIgObj[e['HSN_CODE']] = {
            code: e['HSN_CODE'], taxVal: this.convertToFloat(e['BASE_AMT']),
            igRate: e['IGST_PERC'], igAmt: this.convertToFloat(e['IGST']),
            totalTax: (this.convertToFloat(e['IGST']))
          }
        }
        /// hsn grouping for igst end
        this.totalQty += e['QTY'];
      });
      this.invoiceDetails['HSN_CGST_OBJ'] = hsnCgObj;
      this.invoiceDetails['HSN_IGST_OBJ'] = hsnIgObj;
      if (totalIgst > 0) {
        this.totalTaxAmtInWords = this.inWords(parseInt(totalIgst.toString()));
      } else {
        const csTax = this.convertToFloat(this.totalCgstTaxAmt) + this.convertToFloat(this.totalSgstTaxAmt);
        this.totalTaxAmtInWords = this.inWords(parseInt(csTax.toString()));
      }
      if (this.invoiceDetails.saleDetail.IGST > 0) {
      } else {
        this.taxVal = (this.invoiceDetails.saleDetail.SGST + this.invoiceDetails.saleDetail.CGST);
        this.taxAmt = parseFloat(this.netDiscAmt + this.invoiceDetails.saleDetail.SGST + this.invoiceDetails.saleDetail.CGST);
      }
      this.totalAmt = (parseFloat(this.taxAmt) + parseFloat(this.totalDelivery));
      this.totalInWords = this.inWords(this.invoiceDetails.saleDetail.GROSS_AMT_ROUND);
    }

    if (this.invoiceDetails.isPayment) {
      this.invoiceDetails.paymentDetail.PAY_MODE = PAYMODES_ACR_TEXT[this.invoiceDetails.paymentDetail.PAY_MODE];

      if (this.invoiceDetails.paymentDetail.STORE_CASH_JSON && typeof this.invoiceDetails.paymentDetail.STORE_CASH_JSON === 'string') {
        this.invoiceDetails.paymentDetail.STORE_CASH_JSON = JSON.parse(this.invoiceDetails.paymentDetail.STORE_CASH_JSON);
      }
      if (this.invoiceDetails.paymentDetail.SELF_CHQ_JSON && typeof this.invoiceDetails.paymentDetail.SELF_CHQ_JSON === 'string') {
        this.invoiceDetails.paymentDetail.SELF_CHQ_JSON = JSON.parse(this.invoiceDetails.paymentDetail.SELF_CHQ_JSON);
        this.invoiceDetails.paymentDetail.SELF_CHQ_JSON.instrumentDate = moment(new Date(this.invoiceDetails.paymentDetail.SELF_CHQ_JSON.instrumentDate)).format(DATE_DISPLAY_FORMAT);
      }

      if (this.invoiceDetails.paymentDetail.NEFT_JSON && typeof this.invoiceDetails.paymentDetail.NEFT_JSON === 'string') {
        this.invoiceDetails.paymentDetail.NEFT_JSON = JSON.parse(this.invoiceDetails.paymentDetail.NEFT_JSON);
        this.invoiceDetails.paymentDetail.NEFT_JSON.payReceDate = moment(new Date(this.invoiceDetails.paymentDetail.NEFT_JSON.payReceDate)).format(DATE_DISPLAY_FORMAT);
      }

      if (this.invoiceDetails.paymentDetail.RTGS_JSON && typeof this.invoiceDetails.paymentDetail.RTGS_JSON === 'string') {
        this.invoiceDetails.paymentDetail.RTGS_JSON = JSON.parse(this.invoiceDetails.paymentDetail.RTGS_JSON);
        this.invoiceDetails.paymentDetail.RTGS_JSON.payReceDate = moment(new Date(this.invoiceDetails.paymentDetail.RTGS_JSON.payReceDate)).format(DATE_DISPLAY_FORMAT);
      }

      if (this.invoiceDetails.paymentDetail.IMPS_JSON && typeof this.invoiceDetails.paymentDetail.IMPS_JSON === 'string') {
        this.invoiceDetails.paymentDetail.IMPS_JSON = JSON.parse(this.invoiceDetails.paymentDetail.IMPS_JSON);
      }

      if (this.invoiceDetails.paymentDetail.CUST_BANK_DEPOSIT_CASH_JSON && typeof this.invoiceDetails.paymentDetail.CUST_BANK_DEPOSIT_CASH_JSON === 'string') {
        this.invoiceDetails.paymentDetail.CUST_BANK_DEPOSIT_CASH_JSON = JSON.parse(this.invoiceDetails.paymentDetail.CUST_BANK_DEPOSIT_CASH_JSON);
      }

      if (this.invoiceDetails.paymentDetail.CUST_BANK_DEPOSIT_CHQ_JSON && typeof this.invoiceDetails.paymentDetail.CUST_BANK_DEPOSIT_CHQ_JSON === 'string') {
        this.invoiceDetails.paymentDetail.CUST_BANK_DEPOSIT_CHQ_JSON = JSON.parse(this.invoiceDetails.paymentDetail.CUST_BANK_DEPOSIT_CHQ_JSON);
        this.invoiceDetails.paymentDetail.CUST_BANK_DEPOSIT_CHQ_JSON.instrumentDate = moment(new Date(this.invoiceDetails.paymentDetail.CUST_BANK_DEPOSIT_CHQ_JSON.instrumentDate)).format(DATE_DISPLAY_FORMAT);
      }
      if (this.invoiceDetails.saleDetail.SALE_MODE == 'ONLINE' && typeof this.invoiceDetails.paymentDetail.PG_RESPONSE === 'string') {
        this.invoiceDetails.paymentDetail.PG_RESPONSE = JSON.parse(this.invoiceDetails.paymentDetail.PG_RESPONSE);
        this.invoiceDetails.paymentDetail.TOTAL_AMOUNT = (+this.invoiceDetails.paymentDetail.TOTAL_AMOUNT).toFixed(2);
      }

    }

    if (this.invoiceDetails.isTransport) {
      let transportObj: any = this.invoiceDetails.transportDetail;
      this.courierName = transportObj.TRANS_NAME || '';
      this.dateOfDispatch = moment(new Date(transportObj.TRANS_DISP_DATE_IN)).format(DATE_DISPLAY_FORMAT);
      this.transportReceiptNO = transportObj.TRANS_RECEIPT_NO || '';
      this.transportAddr = transportObj.TRANS_ADDR || '';
      this.transportContactPerson = transportObj.TRANS_CONTACT_PERSON_FULLNAME || '';
      this.transportContactNo = transportObj.TRANS_CONTACT1 || '';
    }
  }
  getObjArr(obj) {
    return Object.values(obj);
  }
  setId() {
    return ContainerMaster.THANKYOU;
  }
  setMetaTitle() {
    return SeoMaster.THANKYOU.title;
  }
  setMetaTags() {
    return ([]);
  }
  actionFromPlugin(params) {
  }

  inWords(num) {
    if ((num = num.toString()).length > 9) {
      return 'overflow';
    }
    const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) {
      return;
    }
    let str = '';

    str += (+(n[1]) !== 0) ? (DIGITS_TEXT[Number(n[1])] || TENS_TEXT[n[1][0]] + ' ' + DIGITS_TEXT[n[1][1]]) + 'Crore ' : '';
    str += (+(n[2]) !== 0) ? (DIGITS_TEXT[Number(n[2])] || TENS_TEXT[n[2][0]] + ' ' + DIGITS_TEXT[n[2][1]]) + 'Lakh ' : '';
    str += (+(n[3]) !== 0) ? (DIGITS_TEXT[Number(n[3])] || TENS_TEXT[n[3][0]] + ' ' + DIGITS_TEXT[n[3][1]]) + 'Thousand ' : '';
    str += (+(n[4]) !== 0) ? (DIGITS_TEXT[Number(n[4])] || TENS_TEXT[n[4][0]] + ' ' + DIGITS_TEXT[n[4][1]]) + 'Hundred ' : '';
    str += (+(n[5]) !== 0) ? ((str !== '') ? '' : '') + (DIGITS_TEXT[Number(n[5])] || TENS_TEXT[n[5][0]] + ' ' + DIGITS_TEXT[n[5][1]]) + '' : '';
    return str;
  }
  convertToFloat(val) {
    return parseFloat(parseFloat(val).toFixed(2));
  }
  roundAmt(num) {
    return Math.round(num);
  }
  parseAmt(num) {
    return parseFloat(num).toFixed(2);
  }
  transformDate(date) {
    return moment(date).format('DD/MM/YYYY');
  }
}