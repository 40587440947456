import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class KeysService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private getAllKeysUrl = 'api/getAllKeys';
  private getActiveKeysUrl = 'api/getActiveKeys';
  private addKeysUrl = 'api/addKeys';
  private blockedKeysUrl = 'api/blockedKey';
  private unblockKeysUrl = 'api/unblockKeysUrl';
  private getProductUrl = 'api/getProduct';
  private availableKeysUrl = 'api/keysStock';
  private distinctKeyNames = 'api/getDistinctKeyNames';

  getAllKeys(val: any) {
    return this.http.post(this.getAllKeysUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getAllKeys'))
    );
  }

  getActiveKeys(val: any) {
    return this.http.post(this.getActiveKeysUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getActiveKeys'))
    );
  }

  addKeys(val: any) {
    return this.http.post(this.addKeysUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('addKeys'))
    );
  }

  blockedKeys(val: any) {
    return this.http.post(this.blockedKeysUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('blockedKeys'))
    );
  }
  unblockKeys(val: any) {
    return this.http.post(this.unblockKeysUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('unblockKeys'))
    );
  }

  getProduct(val: any) {
    return this.http.post(this.getProductUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getProduct'))
    );
  }
  availableKeys(val: any) {
    return this.http.post(this.availableKeysUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('availableKeys'))
    );
  }
  getDistinctKeyNames(val: any) {
    return this.http.post(this.distinctKeyNames, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('availableKeys'))
    );
  }

}
