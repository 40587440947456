import { PluginMaster } from "./../../shared/enums/plugin.master";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { Component, OnInit, Inject, PLATFORM_ID, Output, EventEmitter, Input, OnChanges, ViewChild } from "@angular/core";
import { BasePlugin } from "../base.plugin";
import { isPlatformBrowser, PathLocationStrategy } from "@angular/common";
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
declare var $: any;

/**
 * @title Chips with input
 */

@Component({
  selector: "app-paymodes-form",
  templateUrl: "./paymodes.component.html",
  styleUrls: ["paymodes.component.scss"]
})
export class PayModeComponent extends BasePlugin implements OnInit, OnChanges {
 

  pluginMaster = PluginMaster;
  payMode: string;
  payModeOptionsFormGroup: FormGroup;
  selfCashForm: FormGroup;
  selfChqForm: FormGroup;
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  @Output() onPayModeChange = new EventEmitter();
  @Input() received;
  selfCashObj = {
    payMode: 'STORE_CASH',
    amount: '',
    remark: ''
  };
  chqObj = {
    payMode: '',//'STORE_CHQ',
    amount:'',
    instrumentNO: '',
    bankName: '',
    branchName: '',
    payReceFrom: '',
    instrumentDate: '',
    remark: '',
    TDSAmount: '',
    COMPANY_BANK_ACC_NO: ''
  };
  NEFTObj = {
    payMode: 'NEFT',
    amount: '',
    TDSAmount: '',
    payReceDate: '',
    UTRNo: '',
    COMPANY_BANK_ACC_NO: '',
    remark: ''
  };
  RTGSObj = {
    payMode: 'RTGS',
    amount: '',
    TDSAmount: '',
    payReceDate: '',
    UTRNo: '',
    COMPANY_BANK_ACC_NO: '',
    remark: ''
  };
  IMPSObj = {
    payMode: 'IMPS',
    payReceDate: '',
    amount: '',
    mobile: '',
    IMPSNo: '',
    COMPANY_BANK_ACC_NO: '',
    remark: ''
  };
  CustBankDepositCashObj = {
    payMode: 'CUST_BANK_DEPOSIT_CASH',
    branchName: '',
    amount: '',
    COMPANY_BANK_ACC_NO: '',
    remark: '',
    TDSAmount: ''
  };
  CustBankDepositChqObj = {
    payMode: 'CUST_BANK_DEPOSIT_CHQ',
    amount: '',
    instrumentNO: '',
    bankName: '',
    branchName: '',
    instrumentDate: '',
    payReceFrom: '',
    remark: '',
    COMPANY_BANK_ACC_NO: ''
  };

  selectedCustomer: any;
  companyAcc: string;
  receivedAmount;
  paymentMode = 'STORE_CASH';
  onlyNumberPattern = /^[0-9]*$/;
  constructor(public location: Location, public router: Router, public formBuilder: FormBuilder, @Inject(PLATFORM_ID) private platformId: Object, componentManagerService: ComponentManagerService
  ) {
    super(componentManagerService);
    
    this.payMode = 'STORE_CASH';
  }

  ngOnInit() {
    this.createForm();
   
  }

  ngOnChanges() {
    this.receivedAmount = this.received;
    this.paymentMode = this.payMode;
  }

  setId() {
    return PluginMaster.PAY_MODE;
  }

  actionFromSubPlugin(params) { }

  createForm() {
    this.payModeOptionsFormGroup = this.formBuilder.group({
      selfCashAmount: ['', [Validators.required]],
      payMode: ['', [Validators.required]],
      amount: ['', [Validators.required, Validators.pattern(this.onlyNumberPattern)]],
      instrumentNO: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      bankName: ['', [Validators.required]],
      branchName: ['', [Validators.required]],
      instrumentDate: ['', [Validators.required]],
      payReceFrom: ['', [Validators.required]],
      remark: ['', [Validators.required]],
      UTRNo: ['', [Validators.required]],
      TDSAmount: ['', [Validators.required]],
      payReceDate: ['', [Validators.required]],
      IMPSNo: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      bankDetail: ['', [Validators.required]],
      taxPrintAc: ['', [Validators.required]],
      neftDate: ['', [Validators.required]],
      utr: ['', [Validators.required]],
      recAmt: ['', [Validators.required]],
      tds: ['', [Validators.required]],
      rtgsDate: ['', [Validators.required]],
      rtgsUtr: ['', [Validators.required]],
      impsMob: ['', [Validators.required]],

    });
  }



  onPayModeDetailsUpdate(selectedPayModeDetails: any) {
    selectedPayModeDetails.payMode = this.payMode;
    selectedPayModeDetails.amount = this.received;  
    selectedPayModeDetails.COMPANY_BANK_ACC_NO = this.companyAcc;
    this.receivedAmount = this.received;
    this.onPayModeChange.emit(selectedPayModeDetails);
  
  }

  resetForm() {

    this.payMode = '';
    this.companyAcc = '';
    this.selfCashObj.amount = '';
    this.chqObj.amount = '';
    this.chqObj.instrumentNO = '';
    this.chqObj.bankName = '';
    this.chqObj.branchName = '';
    this.chqObj.instrumentDate = '';
    this.chqObj.payReceFrom = '';
    this.chqObj.remark = '';
    this.chqObj.TDSAmount = '';
    this.NEFTObj.payReceDate = '';
    this.NEFTObj.UTRNo = '';
    this.NEFTObj.amount = '';
    this.NEFTObj.TDSAmount = '';
    this.NEFTObj.remark = '';
    this.RTGSObj.payReceDate = '';
    this.RTGSObj.UTRNo = '';
    this.RTGSObj.amount = '';
    this.RTGSObj.TDSAmount = '';
    this.RTGSObj.remark = '';
    this.IMPSObj.payReceDate = '';
    this.IMPSObj.amount = '';
    this.IMPSObj.IMPSNo = '';
    this.IMPSObj.mobile = '';
    this.IMPSObj.remark = '';
    this.CustBankDepositCashObj.branchName = '';
    this.CustBankDepositCashObj.amount = '';
    this.CustBankDepositCashObj.remark = '';
    
    this.payModeOptionsFormGroup.reset();
  }
  setCustomer(custDetail: any) {
    this.chqObj.payReceFrom = custDetail.COMPANY;
  }
  onAmtChange(amt) {
    this.receivedAmount = parseFloat(parseFloat(amt).toFixed(2));
  }
  onModeChange(mode) {
    this.paymentMode = mode;
    if (mode === 'STORE_CHQ' || mode === 'CUST_BANK_DEPOSIT_CHQ') {
      this.companyAcc = '036100109072';
    } else {
      this.companyAcc = null;
    }
  }
  validateFields() {
  }
}
