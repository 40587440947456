import { Title, Meta } from '@angular/platform-browser';
import { ComponentManagerService } from '../../services/component-manager.service';
import { SeoMaster } from '../../shared/enums/seo.master';
import { ContainerMaster } from '../../shared/enums/container.master';
import { Component, OnInit } from '@angular/core';
import { BaseContainer } from '../base.container';

@Component({
  selector: 'app-site',
  templateUrl: './sitemap.component.html',
  styleUrls: ["./sitemap.component.scss"]

})

export class SiteComponent {

  
}