//#region Import Calls
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
//#endregion

@Injectable()
export class RouteManagerService {
  home = 'home';

  constructor(private router: Router) { }

  callRoute(containerName: string, queryParam?: any) {
    switch (containerName) {
      case 'home':
        this.router.navigate(['/home']);
        break;
      case 'payrollsoftware':
        this.router.navigate(['/payrollsoftware']);
        break;
      case 'tdsmanagement':
        this.router.navigate(['/tdsmanagement']);
        break;
      case 'etdswizard':
        this.router.navigate(['/etdswizard']);
        break;
      // case 'itreturn':
      //   this.router.navigate(['/itreturn']);
      //   break;
      case '26as':
        this.router.navigate(['/26as']);
        break;
      case 'xbrl':
        this.router.navigate(['/xbrl']);
        break;
      case 'proactly':
        this.router.navigate(['/proactly']);
        break;
      case 'companysecretary':
        this.router.navigate(['/companysecretary']);
        break;
      case 'fixedasset':
        this.router.navigate(['/fixedasset']);
        break;
      case 'pdfsigner':
        this.router.navigate(['/pdfsigner']);
        break;
      case 'gstsoftware':
        this.router.navigate(['/gstsoftware']);
        break;
      case 'webfixedasset':
        this.router.navigate(['/webfixedasset']);
        break;
      case 'pfexpress':
        this.router.navigate(['/pfexpress']);
        break;
      case 'hrslip':
        this.router.navigate(['/payroll-hr-software']);
        break;
      case 'biometricmachine':
        this.router.navigate(['/biometricmachine']);
        break;
      case 'companyact':
        this.router.navigate(['/companyact']);
        break;
      case 'sharecertificate':
        this.router.navigate(['/sharecertificate']);
        break;
      case 'statutoryregister':
        this.router.navigate(['/statutoryregister']);
        break;
      case 'minutes-binder':
        this.router.navigate(['/minutes-binder']);
        break;
      case 'minutes-paper-ledger':
        this.router.navigate(['/minutes-paper-ledger']);
        break;
      case 'minutes-paper-white':
        this.router.navigate(['/minutes-paper-white']);
        break;
      case 'company-seal':
        this.router.navigate(['/company-seal']);
        break;
      case 'digitalsignature':
        this.router.navigate(['/digitalsignature']);
        break;
      case 'about-us':
        this.router.navigate(['/about-us']);
        break;
      case 'contact-us':
        this.router.navigate(['/contact-us']);
        break;
      case 'thankyou':
        this.router.navigate(['/thankyou', queryParam]);
        break;
      case 'admin':
        this.router.navigate(['/admin']);
        break;
      case 'dashboard':
        this.router.navigate(['/admin/dashboard']);
      case 'report':
        this.router.navigate(['/admin/report']);
        break;
      case 'offlinesales':
        this.router.navigate(['/admin/offlinesales']);
        break;
      case 'deliverysales':
        this.router.navigate(['/admin/deliverysales']);
        break;
      case 'managekeys':
        this.router.navigate(['/admin/managekeys']);
        break;
      case 'notification':
        this.router.navigate(['/admin/notification']);
        break;
      case 'users':
        this.router.navigate(['/admin/users']);
        break;
      case 'changepassword':
        this.router.navigate(['/admin/changepassword']);
        break;
      case 'createproductkeys':
        this.router.navigate(['/admin/createproductkeys']);
        break;
      case 'adminlogin':
        this.router.navigate(['/adminlogin']);
        break;
      case 'user':
        this.router.navigate(['/user']);
        break;
      case 'profile':
        this.router.navigate(['/user/profile']);
        break;
      case 'user-notification':
        this.router.navigate(['/user/notification']);
        break;
      case 'orders':
        this.router.navigate(['/user/orders']);
        break;
      case 'user-changepassword':
        this.router.navigate(['/user/changepassword']);
        break;
      case 'emailVerify':
        this.router.navigate(['transactional/confirm-your-taxprint-account',
          { queryParams: { token: 'eyJ0eXAiOiJKV1Qi.eyJrZXkiOi.eUiabuiKv' } }]);
        break;
      //  case 'emailVerify':
      //  this.router.navigate(['transactional/confirm-your-taxprint-account', { queryParams: { token: '439fd19d20787471344dc40affe21597fd4c8bb997a08c3120fe0727cfdc2036c901c684' } }]);
      //    break;
      case 'changedpassword':
        this.router.navigate(['chgpassword']);
        break;
      case 'explicitpayments':
        this.router.navigate(['/admin/payments', queryParam]);
        break;
      case 'payments':
        this.router.navigate(['/admin/payments']);
        break;
      case 'operators':
        this.router.navigate(['/admin/operators']);
        break;
      case 'viewcart':
        this.router.navigate(['/viewcart']);
        break;
      case 'checkout':
        this.router.navigate(['/checkout']);
        break;
      case 'productmanagement':
        this.router.navigate(['/admin/productmanagement']);
        break;
      case 'expensemanagement':
        this.router.navigate(['/admin/expensemanagement']);
        break;
      case 'inventorymanagement':
        this.router.navigate(['/admin/inventorymanagement']);
        break;
      case 'gstlock':
        this.router.navigate(['/admin/gstlock']);
        break;
      case 'cloudpayroll':
        this.router.navigate(['cloudpayroll']);
        break;
      case 'desktoppayroll':
        this.router.navigate(['desktoppayroll']);
        break;
      case 'why-taxprint':
        this.router.navigate(['why-taxprint']);
        break;
      case 'share':
        this.router.navigate(['share']);
        break;
      case 'etdsnext':
        this.router.navigate(['/etdsnext']);
        break;
      default:
        console.log('default condition');
        break;
    }
  }
}
