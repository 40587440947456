import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery';
import { Cart } from "../../models/cart.model";
import { SignupFormComponent } from "../../plugin/signup-form/signup-form.component";
import { CartService } from "../../services/component-services/cart.service";
import { ToasterService } from "../../services/utility-services/toaster.service";
import { ComponentManagerService } from "../../services/component-manager.service";
import { ContainerMaster } from "../../shared/enums/container.master";
import { SeoMaster } from "../../shared/enums/seo.master";
import { BaseContainer } from "../base.container";
declare var Instamojo: any;
declare var $: any;
declare var window: any;
const prod = {
  companyact: {
    ID: 'PROD53',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: []
  },
  companyact1: {
    ID: 'PROD53',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: []
  },
  minutespaper: {
    ID: 'PROD27',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: []
  },
  minutesbinder: {
    ID: 'PROD54',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: []
  },
  sleeksteelcommonseal: {
    ID: 'PROD3',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: []
  },
  sharecertificate: {
    ID: 'PROD34',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: []
  },
  minutespaperwhite: {
    ID: 'PROD28',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: []
  },
  package1: {
    ID: 'PROD55',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: [{ 'name': 'STATUTORY REGISTER', 'ID': 'PROD35' }, { 'name': 'MINUTES PAPER GREEN', 'ID': 'PROD27' }, { 'name': 'MINUTES BINDER', 'ID': 'PROD54' }]
  },
  package2: {
    ID: 'PROD56',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: [{ name: 'STATUTORY REGISTER', ID: 'PROD35' }, { 'name': 'MINUTES PAPER GREEN', 'ID': 'PROD27' }, { 'name': 'MINUTES BINDER', 'ID': 'PROD54' }, { 'name': 'COMMON SEAL', 'ID': 'PROD3' }]
  },
  package3: {
    ID: 'PROD57',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: [{ name: 'STATUTORY REGISTER', ID: 'PROD35' }, { 'name': 'MINUTES PAPER GREEN', 'ID': 'PROD27' }, { 'name': 'MINUTES BINDER', 'ID': 'PROD54' }, { 'name': 'SHARE CERTIFICATE', 'ID': 'PROD34' }]
  },
  package4: {
    ID: 'PROD58',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: [{ name: 'STATUTORY REGISTER', ID: 'PROD35' }, { 'name': 'MINUTES PAPER GREEN', 'ID': 'PROD27' }, { 'name': 'MINUTES BINDER', 'ID': 'PROD54' }, { 'name': 'COMMON SEAL', 'ID': 'PROD3' }, { 'name': 'SHARE CERTIFICATE', 'ID': 'PROD34' }]
  },
  package5: {
    ID: 'PROD59',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: [{ name: 'STATUTORY REGISTER', ID: 'PROD35' }]
  },
  package6: {
    ID: 'PROD60',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: [{ name: 'STATUTORY REGISTER', ID: 'PROD35' }, { 'name': 'MINUTES PAPER GREEN', 'ID': 'PROD27' }]
  },
  package7: {
    ID: 'PROD61',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: [{ name: 'STATUTORY REGISTER', ID: 'PROD35' }, { 'name': 'MINUTES BINDER', 'ID': 'PROD54' }]
  },
  indexregister: {
    ID: 'PROD35',
    QTY: 1,
    productType: 'STATIONERY',
    pkgProds: []
  }
};
@Component({
  selector: "app-sharecertificates",
  templateUrl: "./share-certificate.component.html",
  styleUrls: ["./share-certificate.component.scss"]
})
export class ShareCertificatesComponent extends BaseContainer implements OnInit {
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  payLink: string;
  all: string = "all";
  rates: any;
  cartService: CartService = new CartService();
  cart: Cart = new Cart();
  transID: any;
  paymentID: any;
  prodDetails: any;
  stationaryDetails: any = [];
  state: any;
  status: boolean = true;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  galleryImages1: NgxGalleryImage[];
  galleryImages2: NgxGalleryImage[];
  galleryImages3: NgxGalleryImage[];
  galleryImages4: NgxGalleryImage[];
  galleryImages5: NgxGalleryImage[];
  reqExtraProdDetail = false;
  isMobile: boolean;
  dropDownText = 'Select';
  //prodQty: string;
  @ViewChild(SignupFormComponent) public customerForm: SignupFormComponent;
  etdsprice: any;
  newsprice: any;
  newmprice: any;
  renewsprice: any;
  renewmprice: any;
  xbrlDetails: any;
  tdsDetails: any = [];
  prodCheckoutArr = [];
  prodCheckoutObj = {
    QTY: 1,
    PID: null,
    SPID: null,
    VID: '',
    VAMT: null,
    PTYPE: null,
    VAR_NAME: null,
    SUBGROUPS: null,
    SUBGROUP: null,
    SUB_PROD_CONFIG_DISP: null,
    GROUP_TYPE: null
  };
  prodObjCheckout: any;
  productPrice: any;
  licenseType: string;
  // status: boolean = false;
  proactprice: any;
  prodObj: any;
  total: number;
  qty1 = 1;
  qty2 = 1;
  qty3 = 1;
  qty4 = 1;
  qty5 = 1;
  qty6 = 1;
  schema = {
    "@context": "http://www.schema.org",
    "@type": "product",
    "brand": "Tax Print",
    "name": "Share Certificate",
    "image": "/assets/images/comp_act/share_certificate.jpg",
    "description": "Share Certificate as per Companies ACT 2013 Printing for 100 copies. Rs. 800 (Inclusive of 18% GST as applicable).",
    "aggregateRating": {
      "@type": "aggregateRating",
      "ratingValue": "4.0",
      "reviewCount": "40"
    }
  
  }


  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    meta: Meta,
    title: Title,
    componentManagerService: ComponentManagerService, public router: Router, private toasterService: ToasterService
  ) {
    super(meta, title, componentManagerService);
    //this.prodQty = "1";
    //default selected buyOption
    if (this.isBrowser) {
      window['that'] = this;
    }

    this.rates = {
      all_otm:
        "https://www.instamojo.com/@taxprint/lc4dc4e98df70420ba68747d15ce83483/",
      all_m:
        "https://www.instamojo.com/@taxprint/la41380f40ae84caf8d23cf76a332cb52/",
      mb:
        "https://www.instamojo.com/@taxprint/l181b03e0d3074dc1b59cc33aa3a88200/",
      mpgl:
        "https://www.instamojo.com/@taxprint/lfa9d31aa52324ad9af95ff0ee056a258/",
      mpw:
        "https://www.instamojo.com/@taxprint/l0fa920380e20462ba5726795e34f253c/",
      scca2013:
        "https://www.instamojo.com/@taxprint/l0531501442474f6993e124754a746912/",
      sscs:
        "https://www.instamojo.com/@taxprint/laea3ec5fc5c7465f9b330f0873ba94a8/",
      scr:
        "https://www.instamojo.com/@taxprint/l82804dad442f4f12bc1d2696b4603c7a/",
      scr_m:
        "https://www.instamojo.com/@taxprint/l56ef189a329e4c8da41f0ebc4311fe34/",
      scr_otm:
        "https://www.instamojo.com/@taxprint/lf0b07e6c15214b899ac66f06779ade5c/",
      mpgl_m:
        "https://www.instamojo.com/@taxprint/l565439f200594e02b4c422cc944bea46/",
      mpgl_otm:
        "https://www.instamojo.com/@taxprint/l0f77959fceda442ba752b79133c9a326/",
      mb_m:
        "https://www.instamojo.com/@taxprint/lc7259b997c724236b39c6c651e6a1667/",
      mb_otm:
        "https://www.instamojo.com/@taxprint/l2529811663004478aa33af33d78b6a1c/",
      sscs_m:
        "https://www.instamojo.com/@taxprint/lda74ec0460cd485687242a8a110d5d6a/",
      sscs_otm:
        "https://www.instamojo.com/@taxprint/l74e8b1342b32468fb4dd45b05001892a/",
      scca2013_m:
        "https://www.instamojo.com/@taxprint/l984848b3ddde484184663adb23c457c5/",
      scca2013_otm:
        "https://www.instamojo.com/@taxprint/l7dcc8426b7074d33a083d24bbd172f37/",
      mpw_m:
        "https://www.instamojo.com/@taxprint/l548952333e06404f8394ab84ec0150f7/",
      mpw_otm:
        "https://www.instamojo.com/@taxprint/ldd9bf7d9971b40d4ae9a2f2b2d10c778/",
      pkg_7_otm:
        "https://www.instamojo.com/@taxprint/l8ae9cdd54da1477f8d199a745851b5a9/",
      pkg_7_m:
        "https://www.instamojo.com/@taxprint/lfa623fce2d7541c69aab159af078ce9f/",
      pkg_6_otm:
        "https://www.instamojo.com/@taxprint/le46df9339b264a698052a46eaafaf41f/",
      pkg_6_m:
        "https://www.instamojo.com/@taxprint/lec055ba1b8964595b99fafc2ebc60891/",
      pkg_4_otm:
        "https://www.instamojo.com/@taxprint/lb353107de9de427895587575710b9c0a/",
      pkg_4_m:
        "https://www.instamojo.com/@taxprint/l609ae7f0d0a8486fa3bb77587173f764/",
      pkg_3_otm:
        "https://www.instamojo.com/@taxprint/l3ef64ca88642477090acbaa129ef93b5/",
      pkg_3_m:
        "https://www.instamojo.com/@taxprint/le742db07ff08406288ff9dc980fffb4a/",
      pkg_2_otm:
        "https://www.instamojo.com/@taxprint/lad1680b3acfc4f578a937ffd48c89cf1/",
      pkg_2_m:
        "https://www.instamojo.com/@taxprint/l16788434154846fb8ba5a4b7daa253c9/",
      pkg_1_otm:
        "https://www.instamojo.com/@taxprint/lfd5361ac748c4605abd4746501a8ee20/",
      pkg_1_m:
        "https://www.instamojo.com/@taxprint/l4b0684270d3145d0bb7ea9d6d28c0875/"
    };
    //this.that = this;
  }

  ngOnInit() {
    this.galleryOptions = [
      {
        width: '90%',
        height: '90%',
        thumbnailsColumns: 3,
        imageArrows: false,
        imageAnimation: NgxGalleryAnimation.Slide,
        previewCloseOnClick: true,
        previewCloseOnEsc: true,
        thumbnails: false
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '100%',
        imagePercent: 100,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        width: '100%',
        height: '250px',
        imagePercent: 100,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
        // preview: false
      }
    ];

    this.galleryImages = [
      {
        small: '/assets/images/comp_act/Statutory_Combined_Register1.jpg',
        medium: '/assets/images/comp_act/Statutory_Combined_Register1.jpg',
        big: '/assets/images/comp_act/Statutory_Combined_Register1.jpg'
      },
      // {
      //   small: '/assets/images/img2.jpg',
      //   medium: '/assets/images/comp_act/combined-register2.jpg',
      //   big: '/assets/images/comp_act/combined-register2.jpg'
      // },
      {
        small: '/assets/images/comp_act/Statutory_Combined_Register.jpg',
        medium: '/assets/images/comp_act/combined-register.jpg',
        big: '/assets/images/comp_act/combined-register.jpg'
      }
    ];
    this.galleryImages1 = [
      {
        small: '/assets/images/comp_act/minute_pape_green.jpg',
        medium: '/assets/images/comp_act/minute_pape_green.jpg',
        big: '/assets/images/comp_act/Minute Paper ( Green )_Sent on 04-Dec-2018.jpg'
      }
    ];
    this.galleryImages2 = [
      {
        small: '/assets/images/comp_act/minute_binder-new.jpg',
        medium: '/assets/images/comp_act/minute_binder-new.jpg',
        big: '/assets/images/comp_act/minute_binder-new.jpg'
      }
    ];
    this.galleryImages3 = [
      {
        small: '/assets/images/Sleek_Steel_Common_Seal.jpg',
        medium: '/assets/images/comp_act/Sleek_Steel_Common_Seal.jpg',
        big: '/assets/images/comp_act/Sleek_Steel_Common_Seal.jpg'
      }
    ];
    this.galleryImages4 = [
      {
        small: '/assets/images/img1.jpg',
        medium: '/assets/images/comp_act/share_certificate.jpg',
        big: '/assets/images/comp_act/Share Certificate ( Front ) As per Companies Act 2013_Sent on 04-Dec-2018.jpg'
      }
    ];
    this.galleryImages5 = [
      {
        small: '/assets/images/img1.jpg',
        medium: '/assets/images/comp_act/minute_paper_white.jpg',
        big: '/assets/images/comp_act/Minute Paper ( White )_Sent on 04-Dec-2018.jpg'
      }
    ];
    this.getProductDetails();
  }

  setId() {
    return ContainerMaster.SHARE_CERTIFICATES;
  }
  setMetaTitle() {
    return SeoMaster.sharecertificates.title;
  }
  setMetaTags() {
    return [
      { name: "keywords", content: SeoMaster.sharecertificates.keyword },
      { name: "description", content: SeoMaster.sharecertificates.description },
      // { name: "og:title", content: SeoMaster.sharecertificates.ogtitle },
      { name: "og:type", content: SeoMaster.sharecertificates.ogtype },
      { name: "og_url", content: SeoMaster.sharecertificates.ogurl },
      { name: "og:image:secure_url", content: SeoMaster.sharecertificates.ogimage },
      // { name: "og:description", content: SeoMaster.sharecertificates.ogdescription },
      { name: "og:site_name", content: SeoMaster.sharecertificates.ogsitename },
      // { name: "og:title", content: SeoMaster.sharecertificates.facebooktitle },
      // { name: "og:description", content: SeoMaster.sharecertificates.facebookdescription },
      { name: "og:title", content: SeoMaster.sharecertificates.linkedintitle },
      { name: "og:description", content: SeoMaster.sharecertificates.linkedindescription }
    ];
  }
  actionFromPlugin(params) { }

  ngAfterViewInit() {
    /* Configuring Handlers */
    Instamojo.configure({
      handlers: {
        onOpen: this.onOpenHandler,
        onClose: this.onCloseHandler,
        onSuccess: this.onPaymentSuccessHandler,
        onFailure: this.onPaymentFailureHandler
      }
    });
    // if (this.isBrowser) {
    //   const that = this;
    //   $(window).bind("scroll", function () {
    //     if ($(window).scrollTop() > 150) {
    //       $("#companyact").addClass(
    //         "fixed-heading navbar-fixed-section slideInDown");
    //       $('.fixed-heading h1').addClass('text-right');
    //       $('#companyact img').removeClass("d-none");
    //       $("#companyact img").css("height", "50px");
    //       $('#companyact').css({ "display": "flex", "padding": "0 120px" });
    //     } else {
    //       $("#companyact").removeClass(
    //         "fixed-heading navbar-fixed-section slideInDown");
    //       $('#companyact h1').removeClass('text-right');
    //       $('#companyact img').addClass("d-none");
    //       $("#companyact img").css("height", "auto");
    //       $('#companyact').css({ "display": "block", "padding": "0" });
    //     }
    //   });

    //   if (!this.componentManagerService.isMobile) {
    //     $("#social-float").addClass("top-146");
    //     $("#social-float").addClass("fix-pos");
    //     $(document).scroll(function () {
    //       if (
    //         $("#social-float").offset().top + $("#social-float").height() >=
    //         $("#footer").offset().top
    //       ) {
    //         $("#social-float").addClass("positionabs");
    //         $("#social-float").removeClass("fix-pos");
    //       }
    //       if (
    //         $(document).scrollTop() + window.innerHeight <
    //         $("#footer").offset().top
    //       ) {
    //         $("#social-float").addClass("fix-pos");
    //         $("#social-float").removeClass("positionabs");
    //         if ($(window).scrollTop() > 150) {
    //           $("#social-float").addClass("top-73");
    //           $("#social-float").removeClass("top-146");
    //         } else {
    //           $("#social-float").addClass("top-146");
    //           $("#social-float").removeClass("top-73");
    //         }
    //       }
    //     });
    //   }
    // }
  }

  onOpenHandler() {
    console.log("Payments Modal is Opened");
  }

  onCloseHandler() {
    console.log("Payments Modal is Closed");
  }

  onPaymentSuccessHandler(response) {

    console.log("Payment Success Response", response);
    if (response.status.toLowerCase() == "success") {
      $.ajax({
        type: "POST",
        cache: false,
        url: "api/purchasePaymentResult",
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          transID: window['transID'], pgPaymentID: response.paymentId, pgPayClientRes: response,
          paymentID: window['paymentID']
        }),    // multiple data sent using ajax
        success: function () {
          let transID = window['transID'];
          // window['transID'] = undefined;
          // window['paymentID'] = undefined;
          if (window['that'].isBrowser) {
            $('.modal-backdrop').css({ 'display': 'none' });
          }
          window['that'].navigateTo('thankyou', transID);
        }, error: function () {
          alert('There are some error with system. Please contact support team');
        }
      });
    } else {
      alert("Payment failed. Please try again.");
    }
  }

  onPaymentFailureHandler(error) {
    console.log("Payment Failure Response", error);
    alert("Error in payment");
  }

  processPayment(pgPayID, paymentDetailsJSONStr) {
  }

  initPayment(buyOption) {
    if (!buyOption) {
      alert("Invalid Option!");
      return;
    }
    this.payLink = this.rates[buyOption];
    if (!this.payLink) {
      alert("Please contact on support number");
    } else {
      Instamojo.open(this.payLink);
    }
  }

  // buyNow(prodCodeName) {
  // if (this.isBrowser) {
  //    console.log('prodCodeName');
  //    console.log(this.prodDetails);
  //    this.prodDetails = prod[prodCodeName];
  //    this.prodDetails.QTY = $(`#${prodCodeName}-qty`).val();
  //    this.reqExtraProdDetail = ['sleeksteelcommonseal', 'sharecertificate', 'package2', 'package3', 'package5', 'companyact', 'companyact1'].indexOf(prodCodeName) > -1
  //     this.prodDetails['reqExtraProdDetail'] = this.reqExtraProdDetail;
  // this.prodDetails.pkgProds = prodCodeName.pkgProds;
  //    this.customerForm.ngOnChanges();
  //    $('#signUp').modal('show');
  //   }
  // }

  initPaymentData(event) {
    window['transID'] = event.SALE_ID;
    window['paymentID'] = event.paymentID;
  }


  reciveStatus($event) {
    this.status = $event;
  }


  getProductDetails() {
    this.callDataJunction('GET_STATIONARY_DETAILS', '').subscribe(res => {
      this.tdsDetails = res.data;
      this.xbrlDetails = res.data;
      const tdsObj = { new: [], renew: [], amc: [], none: [] };
      this.tdsDetails.forEach(e => {
        const dName = ` ${e['SUB_PROD_NAME']} `
        let obj: any;
        if (e['GROUP_TYPE'] === 'NEW') {
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: dName, TYPE: e['GROUP_TYPE'], SUBGROUP: e['SUB_GROUP'], PTYPE: e['PRODUCT_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.new.push(obj);
        } else if (e['GROUP_TYPE'] === 'RENEW') {
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: dName, TYPE: e['GROUP_TYPE'], SUBGROUP: e['SUB_GROUP'], PTYPE: e['PRODUCT_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.renew.push(obj);
        } else if (e['GROUP_TYPE'] === 'AMC') {
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: dName, TYPE: e['GROUP_TYPE'], SUBGROUP: e['SUB_GROUP'], PTYPE: e['PRODUCT_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.amc.push(obj);
        } else if (e['GROUP_TYPE'] === null) {
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: dName, TYPE: e['GROUP_TYPE'], SUBGROUP: e['SUB_GROUP'], PTYPE: e['PRODUCT_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.none.push(obj);
        }
      });
      for (let t in tdsObj) {
        tdsObj[t].sort((a, b) => {
          return a.PROD_SEQ - b.PROD_SEQ;
        });
      }
      this.tdsDetails = tdsObj;

      if (!!this.tdsDetails['new'] && this.tdsDetails['new'].length > 0) {
        this.productPrice = this.tdsDetails['new'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['new'][0]['VID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['new'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.PID = this.tdsDetails['new'][0]['PID'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['new'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['new'][0]['SPID'];
        this.prodCheckoutObj.SUBGROUPS = this.tdsDetails['none'][0]['SUBGROUP'];
        this.prodCheckoutObj.PTYPE = this.tdsDetails['none'][0]['PTYPE'];
        this.prodCheckoutObj.SUBGROUP = this.tdsDetails['new'][0]['SUB_GROUP'];
        this.prodCheckoutObj.SUB_PROD_CONFIG_DISP = this.tdsDetails['new'][0]['SUB_PROD_CONFIG_DISP'];
        this.prodCheckoutObj.GROUP_TYPE = this.tdsDetails['new'][0]['GROUP_TYPE'];


      } else if (!!this.tdsDetails['renew'] && this.tdsDetails['renew'].length > 0) {
        this.productPrice = this.tdsDetails['renew'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['renew'][0]['VID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['renew'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.PID = this.tdsDetails['renew'][0]['PID'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['renew'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['renew'][0]['SPID'];
        this.prodCheckoutObj.SUBGROUPS = this.tdsDetails['renew'][0]['SUBGROUP'];
        this.prodCheckoutObj.PTYPE = this.tdsDetails['renew'][0]['PTYPE'];
        this.prodCheckoutObj.SUBGROUP = this.tdsDetails['renew'][0]['SUB_GROUP'];
        this.prodCheckoutObj.SUB_PROD_CONFIG_DISP = this.tdsDetails['renew'][0]['SUB_PROD_CONFIG_DISP'];
        this.prodCheckoutObj.GROUP_TYPE = this.tdsDetails['renew'][0]['GROUP_TYPE'];

      } else if (!!this.tdsDetails['amc'] && this.tdsDetails['amc'].length > 0) {
        this.productPrice = this.tdsDetails['amc'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['amc'][0]['VID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['amc'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.PID = this.tdsDetails['amc'][0]['PID'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['amc'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['amc'][0]['SPID'];
        this.prodCheckoutObj.SUBGROUPS = this.tdsDetails['amc'][0]['SUBGROUP'];
        this.prodCheckoutObj.PTYPE = this.tdsDetails['amc'][0]['PTYPE'];
        this.prodCheckoutObj.SUBGROUP = this.tdsDetails['amc'][0]['SUB_GROUP'];
        this.prodCheckoutObj.SUB_PROD_CONFIG_DISP = this.tdsDetails['amc'][0]['SUB_PROD_CONFIG_DISP'];
        this.prodCheckoutObj.GROUP_TYPE = this.tdsDetails['amc'][0]['GROUP_TYPE'];


      } else if (!!this.tdsDetails['none'] && this.tdsDetails['none'].length > 0) {
        this.productPrice = this.tdsDetails['none'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['none'][0]['VID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['none'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.PID = this.tdsDetails['none'][0]['PID'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['none'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['none'][0]['SPID'];
        this.prodCheckoutObj.SUBGROUPS = this.tdsDetails['none'][0]['SUBGROUP'];
        this.prodCheckoutObj.PTYPE = this.tdsDetails['none'][0]['PTYPE'];
        this.prodCheckoutObj.SUBGROUP = this.tdsDetails['none'][0]['SUB_GROUP'];
        this.prodCheckoutObj.SUB_PROD_CONFIG_DISP = this.tdsDetails['none'][0]['SUB_PROD_CONFIG_DISP'];
        this.prodCheckoutObj.GROUP_TYPE = this.tdsDetails['none'][0]['GROUP_TYPE'];

      }
      // this.getProductValues();

    }, err => {
      console.log(err);
    });

  }
  getObjectKeys(obj) {
    return Object.keys(obj);
  }

  getProductValues(params) {
    const arr = this.xbrlDetails.filter(x => x.VARIANT_ID === parseInt(params));
    console.log('arr', arr);
    // this.dropDownText = arr[0]['GROUP_TYPE'].toLowerCase();
    // this.productPrice = arr[0]['FINAL_PRICE'];
    //  this.prodCheckoutObj.PID = arr[0]['PROD_ID'];
    //  this.prodCheckoutObj.SPID = arr[0]['SUB_PROD_ID'];
    //  this.prodCheckoutObj.VAMT = arr[0]['FINAL_PRICE'];
    //  this.prodCheckoutObj.PTYPE = arr[0]['PRODUCT_TYPE'];
    //  console.log(this.prodCheckoutObj);
    //  console.log(this.dropDownText);
    console.log('FINAL_PRICE', arr[0]['FINAL_PRICE']);
    console.log(Math.round(arr[0]['FINAL_PRICE']));
    this.productPrice = arr[0]['FINAL_PRICE'].toFixed(2);

    console.log('this.productPric', this.productPrice);
    this.productPrice = Math.round(this.productPrice);
    console.log('this.productPrice', this.productPrice);
    this.prodCheckoutObj.PID = arr[0]['PROD_ID'];
    this.prodCheckoutObj.VID = arr[0]['VARIANT_ID'];
    this.prodCheckoutObj.SPID = arr[0]['SUB_PROD_ID'];
    this.prodCheckoutObj.VAMT = this.productPrice;
    this.prodCheckoutObj.PTYPE = arr[0]['PRODUCT_TYPE'];
    this.prodCheckoutObj.VAR_NAME = arr[0]['VARIANT_NAME'];
    this.prodCheckoutObj.SUBGROUPS = arr[0]['SUB_GROUP'];
    this.prodCheckoutObj.SUBGROUP = arr[0]['SUB_GROUP'];
    this.prodCheckoutObj.GROUP_TYPE = arr[0]['GROUP_TYPE'];
    this.prodCheckoutObj.SUB_PROD_CONFIG_DISP = arr[0]['SUB_PROD_CONFIG_DISP'];
  }
  addCart() {
    this.prodCheckoutObj['TOTAL'] = (this.prodCheckoutObj.VAMT * this.prodCheckoutObj.QTY);
    this.prodCheckoutObj['DELIVERY_MODE'] = 'COURIER';
    this.prodCheckoutObj['DISCOUNT'] = 0;
    const proObj = { ...this.prodCheckoutObj };
    this.componentManagerService.cartArr.push(proObj);
    this.componentManagerService.subTotalPrice();
    this.toasterService.showToaster(`${this.prodCheckoutObj.VAR_NAME} added to cart`, 'toast-success');
  }
  buyNow() {
    this.prodCheckoutObj['DELIVERY_MODE'] = 'INHAND';
    this.prodCheckoutObj['DISCOUNT'] = 0;
    if (this.isBrowser) {
      $('#signUp').modal('show');
      this.customerForm.ngOnChanges();
    }
  }

  addCartDirect(params, qty) {
    this.getProductValues(params);
    this.prodCheckoutObj.QTY = qty;
    this.addCart();
  }

  buyNowDirect(params, qty) {
    this.getProductValues(params);
    this.prodCheckoutObj.QTY = qty;
    this.buyNow();
  }
}
