import { Observable, of } from 'rxjs';
export abstract class BaseService {
  /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error("Error", error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
