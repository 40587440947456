import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { ColumnChooserService } from '@syncfusion/ej2-angular-grids';
import { NgxGalleryModule } from 'ngx-gallery';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './container/aboutus/aboutus.component';
/// import { FieldErrorDisplayComponent } from "./plugin/field-error-display.component/field-error-display.component";
import { AdminLoginComponent } from './container/admin/admin-login/admin-login.component';
import { BiometricMachineComponent } from './container/biometricmachine/biometricmachine.component';
import { CheckoutComponent } from './container/checkout/checkout.component';
import { CloudBasedPayrollComponent } from './container/cloud-based-payroll/cloud-based-payroll-component';
import { CompanyActComponent } from './container/companyact/companyact.component';
import { ShareCertificatesComponent } from './container/share-certificate/share-certificate.component';
import { StatutoryRegisterComponent } from './container/statutory-register/statutory-register.component';
import { CompanySealComponent } from './container/company-seal/company-seal.component';
import { MinutesBinderComponent } from './container/minutes-binder/minutes-binder.component';
import { MinutesPaperLedgerComponent } from './container/minutes-paper-ledger/minutes-paper-ledger.component';
import { MinutesPaperWhiteComponent } from './container/minutes-paper-white/minutes-paper-white.component';
import { CompanySecretaryComponent } from './container/companysecretary/companysecretary.component';
import { ContactUsComponent } from './container/contactus/contactus.component';
import { DesktopBasedPayrollComponent } from './container/desktop-based-payroll/desktop-based-payroll.component';
import { DigitalSignatureComponent } from './container/digitalsignature/digitalsignature.component';
import { EtdswizardComponent } from './container/etdswizard/etdswizard.component';
import { FixedAssetComponent } from './container/fixedasset/fixedasset.component';
import { GstsoftwareComponent } from './container/gstsoftware/gstsoftware.component';
import { HomeComponent } from './container/home/home.component';
import { HrSlipComponent } from './container/hrslip/hrslip.component';
// import { ItreturnComponent } from './container/itreturn/itreturn.component';
import { PaymentSuccessComponent } from './container/paymentsuccess/paymentsucess.component';
import { PdfSignerComponent } from './container/pdfsigner/pdfsigner.component';
import { PfexpressComponent } from './container/pfexpress/pfexpress.component';
import { ProactlyComponent } from './container/proactly/proactly.component';
import { SharecertificateComponent } from './container/sharecertificate/sharecertificate.component';
import { TdsManagementComponent } from './container/tdsmanagement/tdsmanagement.component';
import { ThankyouComponent } from './container/thankyou/thankyou.component';
import { ViewCartComponent } from './container/viewCart/viewCart.component';
import { WebFixedAssetComponent } from './container/webfixedasset/webfixedasset.component';
import { WhyTaxPrintComponent } from './container/whytaxprint/whytaxprint.component';
import { XbrlComponent } from './container/xbrl/xbrl.component';
import { ChangePasswordComponent } from './plugin/changePassword/changePassword.component';
import { CRMFormComponent } from './plugin/crm-form/crm.component';
import { EmailVerifyComponent } from './plugin/email/email-verify.component';
import { FooterComponent } from './plugin/footer/footer.component';
import { ForgetPasswordComponent } from './plugin/forget-password/forget-password.component';
import { HeaderComponent } from './plugin/header/header.component';
import { StepperContentComponent } from './plugin/stepperContent/stepperContent';
import { ComponentManagerService } from './services/component-manager.service';
import { AuthenticationService } from './services/component-services/authenticationService.service';
import { CancelService } from './services/component-services/cancellation.service';
import { CRMService } from './services/component-services/crm.service';
import { CustomersService } from './services/component-services/customersService.service';
import { DeliveryService } from './services/component-services/deliveryService.service';
import { GstlockService } from './services/component-services/gstlockService.service';
import { InventoryService } from './services/component-services/inventory.service';
import { KeysService } from './services/component-services/keysService.service';
import { LeadService } from './services/component-services/leadService.service';
import { NotificationService } from './services/component-services/notificationService.service';
import { OfflineSalesService } from './services/component-services/offlineSalesService.service';
import { OperatorService } from './services/component-services/operatorService.service';
import { PaymentService } from './services/component-services/paymentService.service';
import { ProductService } from './services/component-services/product.service';
import { ReportService } from './services/component-services/reportService.service';
import { ShipRocketService } from './services/component-services/shipRocket.service';
import { UserService } from './services/component-services/userService.service';
import { RouteManagerService } from './services/route-manager.service';
import { SharedModule } from './shared/modules/shared.module';
import { EtdsNextComponent } from './container/etdsnext/etdsnext.component';
import { NotfoundComponent } from './container/notfound/notfound.component';
import { SiteComponent } from './container/sitemap/sitemap.component';
import { AsreconcilerComponent } from './container/asreconciler/asreconciler.component';
import { NgxJsonLdModule } from 'ngx-json-ld';
// import {CsvService} from 'angular2-json2csv';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TdsManagementComponent,
    GstsoftwareComponent,
    PdfSignerComponent,
    FixedAssetComponent,
    CompanySecretaryComponent,
    XbrlComponent,
    AsreconcilerComponent,
    // ItreturnComponent,
    EtdswizardComponent,
    ContactUsComponent,
    AboutUsComponent,
    DigitalSignatureComponent,
    CompanyActComponent,
    ShareCertificatesComponent,
    StatutoryRegisterComponent,
    CompanySealComponent,
    MinutesBinderComponent,
    MinutesPaperLedgerComponent,
    MinutesPaperWhiteComponent,
    BiometricMachineComponent,
    PfexpressComponent,
    WebFixedAssetComponent,
    FooterComponent,
    HeaderComponent,
    ThankyouComponent,
    CRMFormComponent,
    PaymentSuccessComponent,
    AdminLoginComponent,
    EmailVerifyComponent,
    ChangePasswordComponent,
    ForgetPasswordComponent,
    ProactlyComponent,
    CheckoutComponent,
    ViewCartComponent,
    HrSlipComponent,
    DesktopBasedPayrollComponent,
    CloudBasedPayrollComponent,
    WhyTaxPrintComponent,
    SharecertificateComponent,
    StepperContentComponent,
    EtdsNextComponent,
    NotfoundComponent,
    SiteComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'taxprint' }),
    AppRoutingModule,
    TransferHttpCacheModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule.forRoot(),
    NgxGalleryModule,
    NgxJsonLdModule
  ],
  providers: [
    ComponentManagerService,
    RouteManagerService,
    CRMService,
    AuthenticationService,
    CustomersService,
    ReportService,
    ShipRocketService,
    OfflineSalesService,
    KeysService,
    LeadService,
    PaymentService,
    DeliveryService,
    UserService,
    NotificationService,
    ProductService,
    InventoryService,
    OperatorService,
    ColumnChooserService,
    GstlockService,
    CancelService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
