import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class DeliveryService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private getDeliveryUrl = 'api/getDelivery';
  private setDeliveryUrl = 'api/setDelivery';
  private getTransportUrl='api/updateTransportDetail';

  getDelivery(val: any) {
    return this.http.post(this.getDeliveryUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getDelivery'))
    );
  }
  
  setDelivery(val: any) {
    return this.http.post(this.setDeliveryUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('setDelivery'))
    );
  }
  getTransport(val: any) {
    return this.http.post(this.getTransportUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getTransport'))
    );
  }
}
