export const PluginMaster = {
  HEADER: 'P01',
  FOOTER: 'P02',
  CRM: 'P03',
  SIGNUP: 'P04',
  EMAIL_VERIFY: 'P05',
  CHANGE_PASSWORD: 'P06',
  FORGET_PASSWORD: 'P07',
  PAY_MODE: 'P08',
  PROD_IN_SALE_DETAIL: 'P09',
  CUST_DETAILS: 'P10',
  PROD_SUB_YMM: 'P11',
  PROD_SUB_YDD: 'P12',
  PROD_SUB_YFY: 'P13',
  PROD_SUB_NEW: 'P14',
  PROD_SUB_AMC: 'P15',
  STEPPERCONTENT: 'P16'
};
