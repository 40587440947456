import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";
import { Observable } from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class LeadService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private checkOutUrl = 'api/checkout';
  private uploadPdf = 'api/uploadInvoice';
  private uploadFile = 'api/uploadInvoice';
  private getInvoice = 'api/getSalesByID';
  private S3Details = 'api/s3Details';
  private updateInvoiceURL = 'api/updateInvoiceURL';
  private grtOrderDetailsURL= 'api/readOrderDetail';

  checkOut(val: any) {
    return this.http.post(this.checkOutUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('checkOut'))
    );
  }
  uploadInvoice(val: any) {
    return this.http.post(this.uploadPdf, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('uploadInvoice'))
    );
  }
  getInvoiceDetails(val: any) {
    return this.http.post(this.getInvoice, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getInvoiceDetails'))
    );
  }
  getS3Details(val: any) {
    return this.http.post(this.S3Details, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getS3Details'))
    );
  }
  upload(val: any) {
    // return Observable.create(observer => {
    //   try {
    //     return this.http.post(this.uploadFile, val).subscribe(signRes => {
    //       const params = {
    //         file: val.basestring,
    //         signedRequest: signRes['signedRequest'],
    //         url: signRes['url'],
    //       };
    //       // this.uploadFile1(params).subscribe(
    //       //   uploadRes => {
    //       //     observer.next(params.url);
    //       //     observer.complete();
    //       //   },
    //       //   uploadErr => {
    //       //     // observer.onError(uploadErr);
    //       //   }, () => {
    //       //     // observer.complete();
    //       //   });
    //     }, signErr => {
    //       // console.log('<END>getSignedRequest ERROR');
    //       // console.log(signErr);
    //     });
    //   } catch (err) {
    //     // observer.onError(err);
    //   }
    // });
    return this.http.post(this.uploadFile, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('checkOut'))
    );
  }

  uploadFile1(params): Observable<any> {
    return Observable.create(observer => {
      try {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', params.signedRequest);
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              observer.next();
              observer.complete();
            } else {
              // observer.onError();
            }
          }
        };
        xhr.send(params.file);
      } catch (err) {
        // observer.onError(err);
      }
    });
  }

  updateInvoiceURLHandler(val: any) {
    return this.http.post(this.updateInvoiceURL, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('updateInvoiceURLHandler'))
    );
  }
  grtOrderDetails(val: any) {
    return this.http.post(this.grtOrderDetailsURL, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('updateInvoiceURLHandler'))
    );
  }
}
