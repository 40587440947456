//#region Import Calls
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
// import { ErrorStateMatcher } from '@angular/material';
//#endregion



//#region ControlValues in Form Group are equal
// Validates that child controls in the form group are equal
export class CustomValidators {
  static childrenEqual: ValidatorFn = (formGroup: FormGroup) => {
    const [firstControlName, ...otherControlNames] = Object.keys(formGroup.controls || {});
    const isValid = otherControlNames.every(controlName => formGroup.get(controlName).value === formGroup.get(firstControlName).value);
    return isValid ? null : { childrenNotEqual: true };
  }

  static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}

// Custom ErrorStateMatcher which returns true (error exists) when the parent form group is invalid and the control has been touched
// export class ConfirmValidParentMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     return control.parent.invalid && control.touched;
//   }
// }
//#endregion

//#region Collection of reusable RegExps
export const PasswordRegExps: { [key: string]: RegExp } = {
  password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/
};

export const PanRegExps: { [key: string]: RegExp } = {
  pan: /[A-Za-z]{5}\d{4}[A-Za-z]{1}/
};

export const TanRegExps: { [key: string]: RegExp } = {
  tan: /[A-Za-z]{5}\d{4}[A-Za-z]{1}/
};

export const GstRegExps: { [key: string]: RegExp } = {
  gst: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
};

export const FacebookRegExps: { [key: string]: RegExp } = {
  // tslint:disable-next-line:max-line-length
  facebook: /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/
};

export const InstagramRegExps: { [key: string]: RegExp } = {
  instagram: /^(http(s)?:\/\/)?((w){3}.)?instagr(am.com|.am)?(\.com)?\/.+/
};

export const TwitterRegExps: { [key: string]: RegExp } = {
  twitter: /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/
};

export const LinkedinRegExps: { [key: string]: RegExp } = {
  linkedin: /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/
};

export const YoutubeRegExps: { [key: string]: RegExp } = {
  youtube: /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/
};

export const GoogleRegExps: { [key: string]: RegExp } = {
  google: /\+[^/]+|\d{21}/
};

export const GooglePlusRegExps: { [key: string]: RegExp } = {
  googlePlus: /(?:https?:\/\/)?(?:plus\.google([a-z\.]+)\/)(?:.\/.\/*)?([^\/\s]*)/i
};

//#endregion

//#region Collection of reusable user error messages
export const UserErrorMessages: { [key: string]: string } = {
  fullName: 'Full name must be between 1 and 60 characters',
  email: 'Email must be a valid email address (username@domain)',
  confirmEmail: 'Email addresses must match',
  password: 'Password is required',
  confirmPassword: 'Passwords must match',
  requiredPassword: 'Password is required',
  requiredPhone: 'Mobile No. should be valid',
  OTP: 'OTP required',
  firstName: 'First Name is required',
  LastName: 'Last Name should be valid',
  tellUsAbout: 'Tell us about you'
};
//#endregion

//#region Collection of reusable address error messages
export const AddressErrorMessages: { [key: string]: string } = {
  flatno: 'Flat No/ House No/ Plot is required',
  street: 'Street is required',
  pincode: 'ZipCode should be valid',
  city: 'City should be valid',
  state: 'State should be valid'
};
//#endregion

//#region Collection of reusable address error messages
export const BrandErrorMessages: { [key: string]: string } = {
  brandName: 'Brand Name is required'
};
//#endregion

export const SpaceErrorMessages: { [key: string]: string } = {
  brandName: 'Space Name is required'
};

export const ContactDetailsErrorMessages: { [key: string]: string } = {
  contactFullname: 'Name cannot be more than 60 characters',
  designation: 'Designation cannot be more than 60 characters',
  requiredPhone: 'Mobile No. should be valid',
  email: 'Email must be a valid email address (username@domain)',
};


export const HostProfileErrorMessages: { [key: string]: string } = {
  hostName: 'Host Name is required',
  description: 'Description is required',
  hostingType: 'Hosting Type is required'
};

export const LegalDocErrorMessages: { [key: string]: string } = {
  Tan: 'TAN should be valid',
  Pan: 'PAN should be valid',
  Gst: 'GST should be valid'
};

export const CommunicationErrorMessages: { [key: string]: string } = {
  communicationPhone: 'Communication Phone is required',
  communicationemail: 'Communication Email is required',
};

export const SocialErrorMessages: { [key: string]: string } = {
  facebook: 'Facebook link is required',
  instagram: 'Instagram link is required',
  linkedin: 'Linkedin link is required',
  google: 'Google link is required',
  youtube: 'Youtube link is required',
  twitter: 'Twitter link is required',
};

export const BrandDetailsErrorMessages: { [key: string]: string } = {
  offeringType: 'offeringName is required',
  offeringName: 'offeringName is required',
  offerings: 'offerings is required',
  interest: 'interest is required',
  brandType: 'brandType is required',
};

export const BrandHeaderErrorMessages: { [key: string]: string } = {
  brandName: 'Brand Name is required',
  brandDetails: 'Brand Details is required',
  communicationPhone: 'Communication Phone is required',
  communicationemail: 'Communication Email is required',
};

export const SpaceHeaderErrorMessages: { [key: string]: string } = {
  spaceName: 'Space Name is required',
  spaceDetails: 'Space Details is required',
};

export const PriceErrorMessages: { [key: string]: string } = {
  weekdayPrice: 'WeekdayPrice is required',
  weekdayHrsPrice: 'Hrs Price is required',
  weekendPrice: 'Weekend Price is required',
  weekendHrsPrice: 'Hrs Price is required',
  spaceOwner: 'Space Owner is required',
  commercial: 'Commerical/Residential is required',
  spaceCategory: 'Space Category is required',
  interest: 'Can be used for is required',
  spaceUsage: 'Type of Space is required'
};

export const BusinessProfileErrorMessages: { [key: string]: string } = {
  businessName: 'Business Name is required',
  description: 'Description is required',
  businessType: 'Business Type is required'
};

export const HostAccountErrorMessages: { [key: string]: string } = {
  sellerName: 'Name is required',
  seller_add1: 'Flat No. is required',
  seller_add2: 'Street is required',
  seller_landmark: 'Landmark is required',
  zip: 'Zip should be valid',
  seller_city: 'City should be valid',
  seller_state: 'State should be valid',
  businessurl: 'Business URL should be valid',
  seller_mobile: 'Mobile No. be valid',
  seller_email: 'Email should be valid',
  seller_ifsc_code: 'IFSC code is required',
  seller_acc_num: 'Account Num is required'
};

export function matchOtherValidator(otherControlName: string) {

  let thisControl: FormControl;
  let otherControl: FormControl;

  return function matchOtherValidate(control: FormControl) {

    if (!control.parent) {
      return null;
    }

    // Initializing the validator.
    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as FormControl;
      if (!otherControl) {
        throw new Error('matchOtherValidator(): other control is not found in parent group');
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }

    if (!otherControl) {
      return null;
    }

    if (otherControl.value !== thisControl.value) {
      return {
        matchOther: true
      };
    }

    return null;

  };

}

export const regExps: { [key: string]: RegExp } = {
  password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
  number_: /^[789]\d{9}$/
};

/**
 * Collection of reusable error messages
 */
export const errorMessages: { [key: string]: string } = {
  fullName: 'Full name must be between 1 and 128 characters',
  email: 'Email must be a valid email address (username@domain)',
  confirmEmail: 'Email addresses must match',
  password: 'Password must be between 7 and 15 characters, and contain at least one number and special character',
  confirmPassword: 'Passwords must match',
  number_: 'Incorrect Mobile number'
};
