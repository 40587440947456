import { PluginMaster } from "./../../shared/enums/plugin.master";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID } from "@angular/core";
import { BasePlugin } from "../base.plugin";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { ToasterService } from "../../services/utility-services/toaster.service";
import { ForgetPassword } from "../../models/forgetPassword.model";
import { CustomValidators } from "../../shared/directives/customvalidator";
declare var $: any;

@Component({
  selector: "app-email-verify",
  templateUrl: "./email-verify.component.html",
  styleUrls: ["email-verify.component.scss"]
})

export class EmailVerifyComponent extends BasePlugin implements OnInit {
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  token: any;
  forgetPassword: ForgetPassword = new ForgetPassword();
  changeUserPasswordForm: FormGroup;
  id: any;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, componentManagerService: ComponentManagerService,
    private router: Router, private toasterService: ToasterService
  ) {
    super(componentManagerService);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
      console.log(this.token); // Print the parameter to the console. 
      if (!!this.token) {
        this.sendToken();
      } else {
        this.navigateTo('home');
      }
    });
    this.createForm();
  }

  setId() {
    return PluginMaster.EMAIL_VERIFY;
  }

  actionFromSubPlugin(params) { }
  createForm() {
    this.changeUserPasswordForm = this.formBuilder.group({
      newpasscode: ['', [Validators.required, Validators.minLength(8)]],
      confirmPasscode: ['', Validators.required]
    },
      { validator: CustomValidators.childrenEqual });
  }

  // isFieldValid(field: string) {
  //   return (
  //     !this.changeUserPasswordForm.get(field).valid && this.changeUserPasswordForm.get(field).touched
  //   );
  // }

  //#region Form Validation check
  // Highlights all the validations errors in a form
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  //#endregion

  sendToken() {
    this.callDataJunction('SEND_TOKEN', { TOKEN: this.token }).subscribe(res => {
      if (res.status) {
        if (res.data.cp == 0) {
          this.navigateTo('home');
        } else {
          this.id = res.data.user.ID;
          this.navigateTo('changedpassword');
        }
        this.toasterService.showToaster(res.message, 'toast-success');
      } else {
        this.navigateTo('home');
        this.toasterService.showToaster(res.message, 'toast-error');
      }
    });
  }

  onSubmit() {
    if (this.changeUserPasswordForm.valid) {
      console.log(this.forgetPassword);
      this.callDataJunction('FORGET_PASSWORD', { ...this.forgetPassword, ID: this.id, }).subscribe(res => {
        if (res.status) {
          console.log(res.message);
          this.toasterService.showToaster(res.message, 'toast-success');
          this.navigateTo('home');
        } else {
          console.log(res.message);
          this.toasterService.showToaster(res.message, 'toast-error');
        }
      });
    } else {
      this.validateAllFormFields(this.changeUserPasswordForm);
    }
  }
}