import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ComponentManagerService } from './services/component-manager.service';
import { isPlatformBrowser } from '@angular/common';
declare var $: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	private isBrowser: boolean = isPlatformBrowser(this.platformId);
	currentValue: any;
	status = true;
	constructor(@Inject(PLATFORM_ID) private platformId: Object, public router: Router, public componentManagerService: ComponentManagerService) {
		this.componentManagerService.getCookieDetails();
		if (this.componentManagerService.isMobile) {
			this.status = false;
		}
	}

	ngOnInit() {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.currentValue = event.url;
				if (this.isBrowser && this.currentValue.includes('/admin')) {
					$('.zsiq_floatmain').css('cssText', 'display:none !important');
					$('#wh-widget-send-button').css('cssText', 'display:none !important');
				}
			}
    });
    if (this.status) {
      setTimeout(() => {
        this.status = false;
      }, 3000);
    }
	}

	reciveStatus($event) {
		this.status = $event;
	}



}
