import { Title, Meta } from '@angular/platform-browser';
import { ComponentManagerService } from './../../services/component-manager.service';
import { SeoMaster } from './../../shared/enums/seo.master';
import { ContainerMaster } from './../../shared/enums/container.master';
import { Component, OnInit, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { BaseContainer } from './../base.container';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { SignupFormComponent } from '../../plugin/signup-form/signup-form.component';
import { ProdSubComponent } from '../../plugin/saleNote/prodSubYMM.component';
import { NullInjector } from '@angular/core/src/di/injector';
import { ToasterService } from '../../services/utility-services/toaster.service';

declare var Instamojo: any;
declare var $: any;
declare var window: any;
const prod = {
  single: {
    ID: 'PROD11',
    QTY: 1,
    productType: 'SOFTWARE',
    period: undefined,
    PROD_CONFIG_DISP: '#PROD_SUB_YFY',
    licenseType: 'NEW'
  },
  multiple: {
    ID: 'PROD10',
    QTY: 1,
    productType: 'SOFTWARE',
    period: undefined,
    PROD_CONFIG_DISP: '#PROD_SUB_YFY',
    licenseType: 'NEW'
  }
};

@Component({
  selector: 'app-tdsmanagement',
  templateUrl: './tdsmanagement.component.html',
  styleUrls: ['./tdsmanagement.component.scss']
})
export class TdsManagementComponent extends BaseContainer implements OnInit {
  Variant: any = [];
  payLink: string;
  buyOption: string;
  single = 'single';
  multiple = 'multiple';
  rates: any;
  status = true;
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  prodDetails = {};
  period: string;
  tdsDetails: any = [];
  finalPrice: any = [];
  variantName: any = [];
  subprodName: any = [];
  mprice: any;
  sprice: any;
  licenseType: string;
  cartArr: any = [];
  dropDownText = 'Select';
  // status: boolean = false;
  productPrice: any;
  @ViewChild(SignupFormComponent) public customerForm: SignupFormComponent;
  @ViewChild(ProdSubComponent) public prodPeriodForm: ProdSubComponent;
  newsprice: any;
  newmprice: any;
  renewsprice: any;
  renewmprice: any;
  tdsmanagementDetails: any;
  prodCheckoutArr = [];
  prodCheckoutObj = {
    QTY: 1,
    PID: null,
    PTYPE: null,
    SPID: null,
    VID: '',
    VAMT: null,
    VAR_NAME: null
  };
  prodObjCheckout: any;
  total: any;
  tdsProductid: any = [];
  prodObj = {};
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: any,
    meta: Meta,
    title: Title,
    componentManagerService: ComponentManagerService, private toasterService: ToasterService
  ) {
    super(meta, title, componentManagerService);
    // default selected buyOption
    this.buyOption = this.multiple;
    this.rates = {
      single:
        'https://www.instamojo.com/@taxprint/lebfcf68997354207a3ec8f6e99db9178/',
      multiple:
        'https://www.instamojo.com/@taxprint/l5cc0850c2a384ea5911fb5f300ab375a/'
    };
    window['that'] = this;
    this.getProductDetails();
  }

  ngOnInit() {
    // by default multiple user

    this.prodDetails = prod.multiple;
    this.getProductDetails();
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',this.doc.URL)
  }

  setId() {
    return ContainerMaster.TDS_MNGMNT;
  }
  setMetaTitle() {
    return SeoMaster.TDS_MNGMNT.title;
  }
  setMetaTags() {
    return [
      { name: 'keywords', content: SeoMaster.TDS_MNGMNT.keyword },
      { name: 'description', content: SeoMaster.TDS_MNGMNT.description },
      { name: 'og:title', content: SeoMaster.TDS_MNGMNT.ogtitle },
      { name: 'og:type', content: SeoMaster.TDS_MNGMNT.ogtype },
      { name: 'og_url', content: SeoMaster.TDS_MNGMNT.ogurl },
      { name: 'og:image:secure_url', content: SeoMaster.TDS_MNGMNT.ogimage },
      { name: 'og:description', content: SeoMaster.TDS_MNGMNT.ogdescription },
      { name: 'og:site_name', content: SeoMaster.TDS_MNGMNT.ogsitename }
    ];
  }
  actionFromPlugin(params) { }
  ngAfterViewInit() {
    // this.status=this.child.status;
    /* Configuring Handlers */
    Instamojo.configure({
      handlers: {
        onOpen: this.onOpenHandler,
        onClose: this.onCloseHandler,
        onSuccess: this.onPaymentSuccessHandler,
        onFailure: this.onPaymentFailureHandler
      }
    });
    if (this.isBrowser) {
      const that = this;
      $(window).bind('scroll', function () {
        if ($(window).scrollTop() > 150) {
          $('#tds').addClass(
            'fixed-heading navbar-fixed-section slideInDown');
          $('.fixed-heading h1').addClass('text-right');
          $('#tds img').removeClass('d-none');
          $('#tds img').css('height', '50px');
          $('#tds').css({ 'display': 'flex', 'padding': '0 120px' });
        } else {
          $('#tds').removeClass(
            'fixed-heading navbar-fixed-section slideInDown');
          $('#tds h1').removeClass('text-right');
          $('#tds img').addClass('d-none');
          $('#tds img').css('height', 'auto');
          $('#tds').css({ 'display': 'block', 'padding': '0' });
        }
      });

      if (!this.componentManagerService.isMobile) {
        $('#social-float').addClass('top-146');
        $('#social-float').addClass('fix-pos');
        $(document).scroll(function () {
          if ($('#social-float').offset() && $('#social-float').offset().top + $('#social-float').height() >=
            $('#footer').offset().top
          ) {
            $('#social-float').addClass('positionabs');
            $('#social-float').removeClass('fix-pos');
          }
          if ($(document).scrollTop() + window.innerHeight <
            $('#footer').offset().top
          ) {
            $('#social-float').addClass('fix-pos');
            $('#social-float').removeClass('positionabs');
            if ($(window).scrollTop() > 150) {
              $('#social-float').addClass('top-73');
              $('#social-float').removeClass('top-146');
            } else {
              $('#social-float').addClass('top-146');
              $('#social-float').removeClass('top-73');
            }
          }
        });
      }
    }
  }

  onOpenHandler() {
    // console.log("Payments Modal is Opened");
  }

  onCloseHandler() {
    // console.log("Payments Modal is Closed");
  }

  onPaymentSuccessHandler(response) {
    $.ajax({
      type: 'POST',
      cache: false,
      url: 'api/purchasePaymentResult',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({
        transID: window['transID'], pgPaymentID: response.paymentId, pgPayClientRes: response,
        paymentID: window['paymentID']
      }),
      success: function () {
        const transID = window['transID'];
        //window['transID'] = undefined;
        //window['paymentID'] = undefined;
        if (window['that'].isBrowser) {
          $('.modal-backdrop').css({ 'display': 'none' });
        }
        window['that'].navigateTo('thankyou', transID);
      }
    });


    if (response.status.toLowerCase() === 'success') {
    } else {
      alert('payment failed');
    }
  }

  onPaymentFailureHandler(error) {
    alert('Error in payment. Please try again');
  }

  processPayment(pgPayID, paymentDetailsJSONStr) {
    // call route of api/purchasePaymentResult
    /*pgPaymentID: pgPayID,
          pgPayClientRes: paymentDetailsJSONStr, cpsf:1, tc: 'PG', payLink: this.payLink*/
  }

  initPayment() {
    this.payLink = this.rates[this.buyOption];
    if (!this.payLink) {
      alert('Please contact on support number');
    } else {
      Instamojo.open(this.payLink);
    }
  }

  buyNow(params, qtyID: string) {
    if (this.isBrowser) {
      $('#signUp').modal('show');
      this.prodDetails['QTY'] = $(`#${qtyID}`).val();
      this.prodCheckoutObj['DELIVERY_MODE'] = 'IN_HAND';
      this.prodCheckoutObj['DISCOUNT'] = 0;
      // this.prodDetails['period'] = this.prodPeriodForm.duration; // this.period;
      this.prodDetails['licenseType'] = this.licenseType;
      this.customerForm.ngOnChanges();
    }
  }

  setPayID(event) {
    window['transID'] = event.SALE_ID;
    window['paymentID'] = event.paymentID;
  }


  setLicenseType(licenseType) {
    this.licenseType = licenseType;
  }
  reciveStatus($event) {
    this.status = $event;
  }
  getProductDetails() {
    this.callDataJunction('GET_PRODUCT_DETAILS', { ID: 6 }).subscribe(res => {
      this.tdsDetails = res.data;
      this.tdsmanagementDetails = res.data;
      const tdsObj = { new: [], renew: [], amc: [], none: [] };
      this.tdsDetails.forEach(e => {
        const dName = `${e['PRODUCT_NAME']} - ${e['SUB_PROD_NAME']} - ${e['VARIANT_NAME']}`;
        let obj: any;
        if (e['GROUP_TYPE'] === 'NEW') {
          // tslint:disable-next-line:max-line-length
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: dName, TYPE: e['GROUP_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.new.push(obj);
        } else if (e['GROUP_TYPE'] === 'RENEW') {
          // tslint:disable-next-line:max-line-length
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: dName, TYPE: e['GROUP_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.renew.push(obj);
        } else if (e['GROUP_TYPE'] === 'AMC') {
          // tslint:disable-next-line:max-line-length
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: dName, TYPE: e['GROUP_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.amc.push(obj);
        } else if (e['GROUP_TYPE'] === null) {
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: dName, TYPE: e['GROUP_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.none.push(obj);
        }
      });
      for (let t in tdsObj) {
        tdsObj[t].sort((a, b) => {
          return a.PROD_SEQ - b.PROD_SEQ;
        });
      }
      this.tdsDetails = tdsObj;
      if (!!this.tdsDetails['new'] && this.tdsDetails['new'].length > 0) {
        this.productPrice = this.tdsDetails['new'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['new'][0]['VID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['new'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.PID = this.tdsDetails['new'][0]['PID'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['new'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['new'][0]['SPID'];

      } else if (!!this.tdsDetails['renew'] && this.tdsDetails['renew'].length > 0) {
        this.productPrice = this.tdsDetails['renew'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['renew'][0]['VID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['renew'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.PID = this.tdsDetails['renew'][0]['PID'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['renew'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['renew'][0]['SPID'];

      } else if (!!this.tdsDetails['amc'] && this.tdsDetails['amc'].length > 0) {
        this.productPrice = this.tdsDetails['amc'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['amc'][0]['VID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['amc'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.PID = this.tdsDetails['amc'][0]['PID'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['amc'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['amc'][0]['SPID'];

      } else if (!!this.tdsDetails['none'] && this.tdsDetails['none'].length > 0) {
        this.productPrice = this.tdsDetails['none'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['none'][0]['VID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['none'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.PID = this.tdsDetails['none'][0]['PID'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['none'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['none'][0]['SPID'];

      }
    }, err => {
      console.log(err);
    });

  }
  getObjectKeys(obj) {
    return Object.keys(obj);
  }
  getProductValues() {
    const arr = this.tdsmanagementDetails.filter(x => x.VARIANT_ID === parseInt(this.prodCheckoutObj.VID));
    this.productPrice = arr[0]['FINAL_PRICE'].toFixed(2);
    this.productPrice = Math.round(this.productPrice);
    this.prodCheckoutObj.PID = arr[0]['PROD_ID'];
    this.prodCheckoutObj.SPID = arr[0]['SUB_PROD_ID'];
    this.prodCheckoutObj.VAMT = arr[0]['FINAL_PRICE'];
    this.prodCheckoutObj.PTYPE = arr[0]['PRODUCT_TYPE'];
    this.prodCheckoutObj.VAR_NAME = arr[0]['VARIANT_NAME'];
  }
  addCart() {
    this.prodCheckoutObj['SUBGROUPS'] = null;
    this.prodCheckoutObj['DELIVERY_MODE'] = 'IN_HAND';
    this.prodCheckoutObj['DISCOUNT'] = 0;
    this.prodCheckoutObj['TOTAL'] = (this.prodCheckoutObj.VAMT * this.prodCheckoutObj.QTY);
    const proObj = { ...this.prodCheckoutObj };
    this.componentManagerService.cartArr.push(proObj);
    this.componentManagerService.subTotalPrice();
    this.toasterService.showToaster(`${this.prodCheckoutObj.VAR_NAME} added to cart`, 'toast-success');
  }
}
