

//#region Import Calls
import { Output, EventEmitter, Input } from '@angular/core';
import { ComponentManagerService } from '../services/component-manager.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { PluginModel } from '../models/plugin.model';
//#endregion
export abstract class BasePlugin {
  //#region Properties
  pluginId: any;
  pluginModel: PluginModel = new PluginModel;
  pluginData: any = {};
  @Output() emitterOutput: EventEmitter<any> = new EventEmitter<any>();
  //#endregion

  //#region LifeCycle Events
  constructor(public componentManagerService: ComponentManagerService) {
    this.set();
  }
  //#endregion

  //#region Getter Setter for Plugin ID
  get() {
    return this.pluginId;
  }

  set() {
    this.pluginId = this.setId();
  }

  callDataJunction(action: string, data: any): Observable<any> {
    return this.componentManagerService.dataJunction(this.pluginId, action, data);
  }

  navigateTo(data: any, queryParams?: any) {
    let result;
    if (!!queryParams) {
      result = { data: data, queryParams: queryParams };
    } else {
      result = { data: data };
    }
    return this.componentManagerService.dataJunction(this.pluginId, 'NAVIGATION_ROUTE', result);
  }
  //#endregion

  //#region Emitter Action
  emitterAction(params: PluginModel) {
    this.emitterOutput.emit(params);
  }
  //#endregion

  showdataFromPlugin(params) {
    switch (params.action) {
      case 'OPEN_DIALOG':
        this.emitterAction(params);
        break;
      default:
        this.actionFromSubPlugin(params);
        break;
    }
  }

  //#region Form Validation check
  // Highlights all the validations errors in a form
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  //#endregion

  //#region Abstract Classes
  abstract setId(): string;
  abstract actionFromSubPlugin(params);
  //#endregion
}
