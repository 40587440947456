import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class CancelService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private cancelSaleUrl = 'api/cancelSale';
  private getCanceledSalesUrl = 'api/getCanceledSales';

  cancelSale(val: any) {
    return this.http.post(this.cancelSaleUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getDelivery'))
    );
  }
  getCanceledSales(val: any) {
    return this.http.post(this.getCanceledSalesUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getDelivery'))
    );
  }
  
}
