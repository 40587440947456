import { PluginMaster } from "./../../shared/enums/plugin.master";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { Component, OnInit, Inject, PLATFORM_ID, Output, EventEmitter, Input, OnChanges, ViewChild } from "@angular/core";
import { BasePlugin } from "../base.plugin";
import { isPlatformBrowser, PathLocationStrategy } from "@angular/common";
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
export interface mail {
  name: string;
}
/**
 * @title Chips with input
 */

@Component({
  selector: "app-prodsalenote-form",
  templateUrl: "./prodSaleNote.component.html",
  styleUrls: ["prodSaleNote.component.scss"]
})
export class ProdSaleNoteComponent extends BasePlugin implements OnInit, OnChanges {

  pluginMaster = PluginMaster;
  prodFormGroup: FormGroup;
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  @Input() product;
  @Input() status1;
  @Input() productData: any;
  @Output() onProdNoteChange = new EventEmitter();
  status: any;
  seal = {
    company: '',
    //address:'',
    city: ''
  };
  certificate = {
    company: '',
    CIN: '',
    address: '',
    city: '',
    pincode: '',
    stateName: '',
    shareType: '',
    faceValue: '',
    shareCertiDateStart: '',
    shareCertiDateEnd: ''
  };
  amc = {
    from: '',
    to: ''
  }
  stateList = [
    { stateCode: "Andhra Pradesh", stateName: "Andhra Pradesh" },
    { stateCode: "Arunachal Pradesh", stateName: "Arunachal Pradesh" },
    { stateCode: "Assam", stateName: "Assam" },
    { stateCode: "Bihar", stateName: "Bihar" },
    { stateCode: "Chhattisgarh", stateName: "Chhattisgarh" },
    { stateCode: "Chandigarh", stateName: "Chandigarh" },
    { stateCode: "Dadra and Nagar Haveli", stateName: "Dadra and Nagar Haveli" },
    { stateCode: "Daman and Diu", stateName: "Daman and Diu" },
    { stateCode: "Delhi", stateName: "Delhi" },
    { stateCode: "Goa", stateName: "Goa" },
    { stateCode: "Gujarat", stateName: "Gujarat" },
    { stateCode: "Haryana", stateName: "Haryana" },
    { stateCode: "Himachal Pradesh", stateName: "Himachal Pradesh" },
    { stateCode: "Jammu and Kashmir", stateName: "Jammu and Kashmir" },
    { stateCode: "Jharkhand", stateName: "Jharkhand" },
    { stateCode: "Karnataka", stateName: "Karnataka" },
    { stateCode: "Kerala", stateName: "Kerala" },
    { stateCode: "Madhya Pradesh", stateName: "Madhya Pradesh" },
    { stateCode: "Maharashtra", stateName: "Maharashtra" },
    { stateCode: "Manipur", stateName: "Manipur" },
    { stateCode: "Meghalaya", stateName: "Meghalaya" },
    { stateCode: "Mizoram", stateName: "Mizoram" },
    { stateCode: "Nagaland", stateName: "Nagaland" },
    { stateCode: "Orissa", stateName: "Orissa" },
    { stateCode: "Punjab", stateName: "Punjab" },
    { stateCode: "Pondicherry", stateName: "Pondicherry" },
    { stateCode: "Rajasthan", stateName: "Rajasthan" },
    { stateCode: "Sikkim", stateName: "Sikkim" },
    { stateCode: "Tamil Nadu", stateName: "Tamil Nadu" },
    { stateCode: "Telangana", stateName: "Telangana" },
    { stateCode: "Tripura", stateName: "Tripura" },
    { stateCode: "Uttar Pradesh", stateName: "Uttar Pradesh" },
    { stateCode: "Uttarakhand", stateName: "Uttarakhand" },
    { stateCode: "West Bengal", stateName: "West Bengal" }
  ];
  displayFormArr = [];
  constructor(public location: Location, public router: Router, public formBuilder: FormBuilder, @Inject(PLATFORM_ID) private platformId: Object, componentManagerService: ComponentManagerService
  ) {
    super(componentManagerService);
    this.createForm();
  }

  ngOnInit() {
    //this.createForm();
  }

  ngOnChanges() {
    if (!!this.productData) {
      if (this.productData.SUBGROUP === 'Seal') {
        this.prodFormGroup.get('companyNameSeal').setValidators([Validators.required]);
        this.prodFormGroup.get('companyCity').setValidators([Validators.required]);
      } else if (this.productData.SUBGROUP === 'Share Certificate') {
        this.prodFormGroup.get('certiStartDate').setValidators([Validators.required]);
        this.prodFormGroup.get('certiEndDate').setValidators([Validators.required]);
        this.prodFormGroup.get('faceValueCertificate').setValidators([Validators.required]);
        this.prodFormGroup.get('shareCertificate').setValidators([Validators.required]);
        this.prodFormGroup.get('stateCertificate').setValidators([Validators.required]);
        this.prodFormGroup.get('pincodeCertificate').setValidators([Validators.required]);
        this.prodFormGroup.get('cityCertificate').setValidators([Validators.required]);
        this.prodFormGroup.get('addrCertificate').setValidators([Validators.required]);
        this.prodFormGroup.get('cin').setValidators([Validators.required]);
        this.prodFormGroup.get('companyNameCertificate').setValidators([Validators.required]);
      } else if (this.productData.GROUP_TYPE === 'AMC' || this.productData.SUB_PROD_CONFIG_DISP === 'PROD_SUB_YMM') {
        this.prodFormGroup.get('amcFrom').setValidators([Validators.required]);
        this.prodFormGroup.get('amcTo').setValidators([Validators.required]);
      }
      this.prodFormGroup.updateValueAndValidity();
    }
  }

  setId() {
    return PluginMaster.PROD_IN_SALE_DETAIL;
  }

  actionFromSubPlugin(params) { }

  createForm() {
    this.prodFormGroup = this.formBuilder.group({
      companyNameSeal: [''],
      companyCity: [''],
      certiStartDate: [''],
      certiEndDate: [''],
      faceValueCertificate: [''],
      shareCertificate: [''],
      stateCertificate: [''],
      pincodeCertificate: [''],
      cityCertificate: [''],
      addrCertificate: [''],
      cin: [''],
      companyNameCertificate: [''],
      amcFrom: [''],
      amcTo: ['']
    });
  }

  isFieldValid1(field: string) {
    return !this.prodFormGroup.get(field).valid && this.prodFormGroup.get(field).touched;
  }
  isFormFieldValid(field: string) {
    if (this.prodFormGroup.get(field)) {
      return !this.prodFormGroup.get(field).valid && this.prodFormGroup.get(field).touched;
    } else {
      return false;
    }

  }

  onProdNoteUpdate(prodNoteObj: any) {
    if (!!prodNoteObj.certificate.shareCertiDateStart) {

      this.certificate.shareCertiDateEnd = prodNoteObj.certificate.shareCertiDateStart + 99;
      prodNoteObj.certificate.shareCertiDateEnd = this.certificate.shareCertiDateEnd;
    }
    this.onProdNoteChange.emit(prodNoteObj);
  }
}
