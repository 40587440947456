import { Title, Meta } from "@angular/platform-browser";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { SeoMaster } from "./../../shared/enums/seo.master";
import { ContainerMaster } from "./../../shared/enums/container.master";
import { Component, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { BaseContainer } from "./../base.container";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
declare var Instamojo: any;
declare var $: any;
declare var window: any;
const prod = {
  single: {
    ID: 'PROD32',
    QTY: 1,
    productType: 'SOFTWARE'
  }
}
@Component({
  selector: "app-pfexpress",
  templateUrl: "./pfexpress.component.html",
  styleUrls: ["pfexpress.component.scss"]
})
export class PfexpressComponent extends BaseContainer implements OnInit {
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  payLink: string;
  buyOption: string;
  single: string = "single";
  multiple: string = "multiple";
  prodDetails: any;
  rates: any;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: any,
    meta: Meta,
    title: Title,
    componentManagerService: ComponentManagerService
  ) {
    super(meta, title, componentManagerService);
    //default selected buyOption
    this.buyOption = this.single;
    this.rates = {
      single:
        "https://www.instamojo.com/@taxprint/l69db1f0cf2ee43ca914ce4212055c6cc/",
      multiple: ""
    };
    window['that'] = this;
  }
  ngOnInit() {
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',this.doc.URL)
   }

  setId() {
    return ContainerMaster.PFEXPRESS;
  }
  setMetaTitle() {
    return SeoMaster.PFEXPRESS.title;
  }
  setMetaTags() {
    return [
      { name: "keywords", content: SeoMaster.PFEXPRESS.keyword },
      { name: "description", content: SeoMaster.PFEXPRESS.description },
      { name: "og:title", content: SeoMaster.PFEXPRESS.ogtitle },
      { name: "og:type", content: SeoMaster.PFEXPRESS.ogtype },
      { name: "og_url", content: SeoMaster.PFEXPRESS.ogurl },
      { name: "og:image:secure_url", content: SeoMaster.PFEXPRESS.ogimage },
      { name: "og:description", content: SeoMaster.PFEXPRESS.ogdescription },
      { name: "og:site_name", content: SeoMaster.PFEXPRESS.ogsitename }
    ];
  }
  actionFromPlugin(params) { }

  ngAfterViewInit() {
    /* Configuring Handlers */
    Instamojo.configure({
      handlers: {
        onOpen: this.onOpenHandler,
        onClose: this.onCloseHandler,
        onSuccess: this.onPaymentSuccessHandler,
        onFailure: this.onPaymentFailureHandler
      }
    });
    if (this.isBrowser) {
      const that = this;
      $(window).bind("scroll", function () {
        if ($(window).scrollTop() > 150) {
          $("#pfexp").addClass(
            "fixed-heading navbar-fixed-section slideInDown");
          $('.fixed-heading h1').addClass('text-right');
          $('#pfexp img').removeClass("d-none");
          $("#pfexp img").css("height", "50px");
          $('#pfexp').css({ "display": "flex", "padding": "0 120px" });
        } else {
          $("#pfexp").removeClass(
            "fixed-heading navbar-fixed-section slideInDown");
          $('#pfexp h1').removeClass('text-right');
          $('#pfexp img').addClass("d-none");
          $("#pfexp img").css("height", "auto");
          $('#pfexp').css({ "display": "block", "padding": "0" });
        }
      });

      if (!this.componentManagerService.isMobile) {
        $("#social-float").addClass("top-146");
        $("#social-float").addClass("fix-pos");
        $(document).scroll(function () {
          if (
            $("#social-float").offset().top + $("#social-float").height() >=
            $("#footer").offset().top
          ) {
            $("#social-float").addClass("positionabs");
            $("#social-float").removeClass("fix-pos");
          }
          if (
            $(document).scrollTop() + window.innerHeight <
            $("#footer").offset().top
          ) {
            $("#social-float").addClass("fix-pos");
            $("#social-float").removeClass("positionabs");
            if ($(window).scrollTop() > 150) {
              $("#social-float").addClass("top-73");
              $("#social-float").removeClass("top-146");
            } else {
              $("#social-float").addClass("top-146");
              $("#social-float").removeClass("top-73");
            }
          }
        });
      }
    }
  }

  onOpenHandler() {
    console.log("Payments Modal is Opened");
  }

  onCloseHandler() {
    console.log("Payments Modal is Closed");
  }

  onPaymentSuccessHandler(response) {
    console.log("Payment Success Response", response);
    /*paymentId: "MOJO8a03005N48931427"
status: "success"*/
    $.ajax({
      type: "POST",
      cache: false,
      url: "api/purchasePaymentResult",
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({
        transID: window['transID'], pgPaymentID: response.paymentId, pgPayClientRes: response,
        paymentID: window['paymentID']
      }),
      success: function () {
        let transID = window['transID'];
        window['transID'] = undefined;
        window['paymentID'] = undefined;
        if (window['that'].isBrowser) {
          $('.modal-backdrop').css({ 'display': 'none' });
        }
        window['that'].navigateTo('thankyou', transID);
      }
    });
    
    if (response.status.toLowerCase() == "success") {
    } else {
      alert("payment failed");
    }
    //this.processPayment(response.paymentId||undefined, JSON.stringify(response));
  }

  onPaymentFailureHandler(error) {
    console.log("Payment Failure Response", error);
    alert("Error in payment");
    //alert(error);
    //this.processPayment(undefined , JSON.stringify(error));
  }

  processPayment(pgPayID, paymentDetailsJSONStr) {
    // call route of api/purchasePaymentResult
    /*pgPaymentID: pgPayID,
          pgPayClientRes: paymentDetailsJSONStr, cpsf:1, tc: 'PG', payLink: this.payLink*/
  }

  initPayment() {
    this.payLink = this.rates[this.buyOption];
    if (!this.payLink) {
      alert("Please contact on support number");
    } else {
      Instamojo.open(this.payLink);
    }
  }

  buyNow(params) {
    if (!this.componentManagerService.loggedIn && this.isBrowser) {
      if (params == 'single') {
        this.prodDetails = prod.single;
        $('#signUp').modal('show');
      }
    }
  }

  setPayID(event) {
    window['transID'] = event.tranID;
    window['paymentID'] = event.paymentID;
  }
}
