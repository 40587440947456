import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class NotificationService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private notificationUrl = 'api/notification';
  private getNotificationUrl = 'api/getNotification';
  private seenNotificationUrl = 'api/updateNotification';

  notification(val: any) {
    return this.http.post(this.notificationUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('notification'))
    );
  }
  getNotification(val: any) {
    return this.http.post(this.getNotificationUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getNotification'))
    );
  }

  seenNotification(val: any) {
    return this.http.post(this.seenNotificationUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('seenNotification'))
    );
  }

}
