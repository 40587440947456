export const ActionMaster = {
  NAVIGATION_ROUTE: { TYPE: 'ROUTE', SERVICE: 'ROUTE', FUNCTION: 'CALLROUTE' },
  LOGOUT: { TYPE: 'CLIENT', SERVICE: 'CLIENT', FUNCTION: 'LOGOUT' },
  ADMIN_LOGOUT: { TYPE: 'CLIENT', SERVICE: 'CLIENT', FUNCTION: 'ADMINLOGOUT' },
  CREATE_LEAD: { TYPE: 'SERVER', SERVICE: 'REGISTER', FUNCTION: 'CREATELEAD' },
  READ_ENQUIRY: { TYPE: 'SERVER', SERVICE: 'REGISTER', FUNCTION: 'READENQUIRY' },
  USER_REGISTER: { TYPE: 'SERVER', SERVICE: 'LOGIN', FUNCTION: 'USERREGISTER' },
  USER_LOGIN: { TYPE: 'SERVER', SERVICE: 'LOGIN', FUNCTION: 'USERLOGIN' },
  ADMIN_LOGIN: { TYPE: 'SERVER', SERVICE: 'LOGIN', FUNCTION: 'ADMINLOGIN' },
  GET_CUSTOMERS: { TYPE: 'SERVER', SERVICE: 'CUSTOMER', FUNCTION: 'GETCUSTOMERS' },
  GET_CUSTOMERS_BY_ID: { TYPE: 'SERVER', SERVICE: 'CUSTOMER', FUNCTION: 'GETCUSTOMERSBYID' },
  CHANGE_ADMIN_PASSWORD: { TYPE: 'SERVER', SERVICE: 'LOGIN', FUNCTION: 'CHANGEADMINPASSWORD' },
  CHANGE_USER_PASSWORD: { TYPE: 'SERVER', SERVICE: 'LOGIN', FUNCTION: 'CHANGEUSERPASSWORD' },
  GET_LEADS: { TYPE: 'SERVER', SERVICE: 'REPORT', FUNCTION: 'GETLEADS' },
  GET_LEADS_BY_DATE: { TYPE: 'SERVER', SERVICE: 'REPORT', FUNCTION: 'GETLEADSBYDATE' },
  CREATE_OFFLINE: { TYPE: 'SERVER', SERVICE: 'OFFLINE', FUNCTION: 'CREATEOFFLINE' },
  GET_ALL_KEYS: { TYPE: 'SERVER', SERVICE: 'KEYS', FUNCTION: 'GETALLKEYS' },
  GET_ACTIVE_KEYS: { TYPE: 'SERVER', SERVICE: 'KEYS', FUNCTION: 'GETACTIVEKEYS' },
  ADD_KEYS: { TYPE: 'SERVER', SERVICE: 'KEYS', FUNCTION: 'ADDKEYS' },
  BLOCKED_KEYS: { TYPE: 'SERVER', SERVICE: 'KEYS', FUNCTION: 'BLOCKEDKEYS' },
  GET_PRODUCTS: { TYPE: 'SERVER', SERVICE: 'KEYS', FUNCTION: 'GETPRODUCTS' },
  GET_USERS: { TYPE: 'SERVER', SERVICE: 'OFFLINE', FUNCTION: 'GETUSERS' },
  OFFLINE_SALES: { TYPE: 'SERVER', SERVICE: 'OFFLINE', FUNCTION: 'OFFLINESALES' },
  SET_ADMIN_LOGIN: { TYPE: 'GLOBAL', SERVICE: 'GLOBAL', FUNCTION: 'SETADMINLOGIN' },
  SET_LOGIN: { TYPE: 'GLOBAL', SERVICE: 'GLOBAL', FUNCTION: 'SETLOGIN' },
  CHECK_OUT: { TYPE: 'SERVER', SERVICE: 'PRODUCT', FUNCTION: 'CHECKOUT' },
  PROCESS_PAYMENT: { TYPE: 'SERVER', SERVICE: 'PAYMENT', FUNCTION: 'PROCESSPAYMENT' },
  GET_SALES: { TYPE: 'SERVER', SERVICE: 'REPORT', FUNCTION: 'GETSALES' },
  GET_ALL_SALES: { TYPE: 'SERVER', SERVICE: 'REPORT', FUNCTION: 'GETALLSALES' },
  GET_SALES_BY_DATE: { TYPE: 'SERVER', SERVICE: 'REPORT', FUNCTION: 'GETSALESBYDATE' },
  GET_SALES_BY_ID: { TYPE: 'SERVER', SERVICE: 'REPORT', FUNCTION: 'GETSALESBYID' },
  GET_DELIVERY: { TYPE: 'SERVER', SERVICE: 'DELIVERY', FUNCTION: 'GETDELIVERY' },
  SET_DELIVERY: { TYPE: 'SERVER', SERVICE: 'DELIVERY', FUNCTION: 'SETDELIVERY' },
  SEND_TOKEN: { TYPE: 'SERVER', SERVICE: 'LOGIN', FUNCTION: 'SENDTOKEN' },
  GET_USER_DATA: { TYPE: 'SERVER', SERVICE: 'USER', FUNCTION: 'GETUSERDATA' },
  UPDATE_USER: { TYPE: 'SERVER', SERVICE: 'USER', FUNCTION: 'UPDATEUSER' },
  GET_ORDER_BY_ID: { TYPE: 'SERVER', SERVICE: 'USER', FUNCTION: 'GETORDERBYID' },
  GET_LAST_TRANS_DATE: { TYPE: 'SERVER', SERVICE: 'USER', FUNCTION: 'GETLASTTRANSDATE' },
  NOTIFICATION: { TYPE: 'SERVER', SERVICE: 'NOTIFICATION', FUNCTION: 'NOTIFICATION' },
  GET_NOTIFICATION: { TYPE: 'SERVER', SERVICE: 'NOTIFICATION', FUNCTION: 'GETNOTIFICATION' },
  SEEN_NOTIFICATION: { TYPE: 'SERVER', SERVICE: 'NOTIFICATION', FUNCTION: 'SEENNOTIFICATION' },
  FORGET_PASSWORD_TOKEN: { TYPE: 'SERVER', SERVICE: 'LOGIN', FUNCTION: 'FORGETPASSWORDTOKEN' },
  FORGET_PASSWORD: { TYPE: 'SERVER', SERVICE: 'LOGIN', FUNCTION: 'FORGETPASSWORD' },
  GET_LEAD_BY_ID: { TYPE: 'SERVER', SERVICE: 'REPORT', FUNCTION: 'GETLEADBYID' },
  AVAILABLE_KEYS: { TYPE: 'SERVER', SERVICE: 'KEYS', FUNCTION: 'AVAILABLEKEYS' },
  ADMIN_FORGET_PASSWORD: { TYPE: 'SERVER', SERVICE: 'LOGIN', FUNCTION: 'ADMINFORGETPASSWORD' },
  ADD_PAYMENT: { TYPE: 'SERVER', SERVICE: 'ADDPAYMENT', FUNCTION: 'ADDPAYMENT' },
  GET_OPERATORS: { TYPE: 'SERVER', SERVICE: 'USER', FUNCTION: 'GETOPERATORS' },
  UPDATE_OPERATORS_SCREENS: { TYPE: 'SERVER', SERVICE: 'USER', FUNCTION: 'UPDATEOPERATORSSCREENS' },
  REGISTER_OPERATOR: { TYPE: 'SERVER', SERVICE: 'USER', FUNCTION: 'REGISTEROPERATOR' },
  UPLOAD_INVOICE_S3: { TYPE: 'SERVER', SERVICE: 'ORDER', FUNCTION: 'UPLOADINVOICES3' },
  SAVE_TRANSPORT_DETAILS: { TYPE: 'SERVER', SERVICE: 'SAVETRANSPORT', FUNCTION: 'SAVETRANSPORTDETAIL' },
  GET_ALL_PAYMENTS: { TYPE: 'SERVER', SERVICE: 'SAVETRANSPORT', FUNCTION: 'SAVETRANSPORTDETAIL' },
  GET_COMPLETED_PAYMENT: { TYPE: 'SERVER', SERVICE: 'PAYMENT', FUNCTION: 'LOADPAYMENTS' },
  GET_PAY_BY_ID: { TYPE: 'SERVER', SERVICE: 'PAYMENT', FUNCTION: 'GETPAYBYID' },
  GET_PENDING_PAYMENT: { TYPE: 'SERVER', SERVICE: 'PENDINGPAYMENT', FUNCTION: 'GETPENDINGPAYMENT' },
  GET_OPERATOR_SCREENS_BY_ID: { TYPE: 'SERVER', SERVICE: 'USER', FUNCTION: 'GETOPERATORSCREENSBYID' },
  UPDATE_OPERATOR_SCREENS_BY_ID: { TYPE: 'SERVER', SERVICE: 'USER', FUNCTION: 'UPDATEOPERATORSCREENSBYID' },
  GET_INVOICE_DETAILS: { TYPE: 'SERVER', SERVICE: 'ORDER', FUNCTION: 'GETINVOICEDETAILS' },
  SAVE_OFFLINE_PAYMENT: { TYPE: 'SERVER', SERVICE: 'PAYMENT', FUNCTION: 'SAVEOFFLINEPAYMENT' },
  GET_S3_DETAILS: { TYPE: 'SERVER', SERVICE: 'ORDER', FUNCTION: 'GETS3DETAILS' },
  UPDATE_INVOICE_URL: { TYPE: 'SERVER', SERVICE: 'ORDER', FUNCTION: 'UPDATEINVOICEURL' },
  GET_ORDER_DETAILS: { TYPE: 'SERVER', SERVICE: 'ORDER', FUNCTION: 'GETORDERDETAILS' },
  USER_FORGET_PASSWORD: { TYPE: 'SERVER', SERVICE: 'LOGIN', FUNCTION: 'USERFORGETPASSWORD' },
  GET_SPECIFIC_PRODUCTS: { TYPE: 'SERVER', SERVICE: 'PRODUCTS', FUNCTION: 'GETSPECIFICPRODUCTS' },
  GET_ALL_PRODUCTS: { TYPE: 'SERVER', SERVICE: 'PRODUCTS', FUNCTION: 'GETALLPRODUCTS' },
  GET_SUB_PRODUCTS: { TYPE: 'SERVER', SERVICE: 'PRODUCTS', FUNCTION: 'GETSUBPRODUCTS' },
  CREATE_SUB_PRODUCTS: { TYPE: 'SERVER', SERVICE: 'PRODUCTS', FUNCTION: 'CREATESUBPRODUCTS' },
  UPDATE_SUB_PRODUCTS: { TYPE: 'SERVER', SERVICE: 'PRODUCTS', FUNCTION: 'UPDATESUBPRODUCTS' },
  ADD_PRODUCTS: { TYPE: 'SERVER', SERVICE: 'PRODUCTS', FUNCTION: 'ADDPRODUCTS' },
  UPDATE_PRODUCTS: { TYPE: 'SERVER', SERVICE: 'PRODUCTS', FUNCTION: 'UPDATEPRODUCTS' },
  GET_SUB_PRODUCTS_VARIANTS: { TYPE: 'SERVER', SERVICE: 'PRODUCTS', FUNCTION: 'GETSUBPRODUCTSVARIANTS' },
  CREATE_SUB_PRODUCTS_VARIANTS: { TYPE: 'SERVER', SERVICE: 'PRODUCTS', FUNCTION: 'CREATESUBPRODUCTSVARIANTS' },
  UPDATE_SUB_PRODUCTS_PRICES: { TYPE: 'SERVER', SERVICE: 'PRODUCTS', FUNCTION: 'UPDATESUBPRODUCTSVARIANTS' },
  ADD_INVENTORY: { TYPE: 'SERVER', SERVICE: 'INVENTORY', FUNCTION: 'ADDINVENTORY' },
  GET_INVENTORY: { TYPE: 'SERVER', SERVICE: 'INVENTORY', FUNCTION: 'GETINVENTORY' },
  UPDATE_INVENTORY: { TYPE: 'SERVER', SERVICE: 'INVENTORY', FUNCTION: 'UPDATEINVENTORY' },
  UPDATE_OPERATOR_ACCESS: { TYPE: 'SERVER', SERVICE: 'OPERATOR', FUNCTION: 'UPDATEOPERATORACCESS' },
  GET_PRODUCT_DETAILS: { TYPE: 'SERVER', SERVICE: 'PRODUCTS', FUNCTION: 'GETPRODUCTDETAILS' },
  GET_STATIONARY_DETAILS: { TYPE: 'SERVER', SERVICE: 'PRODUCTS', FUNCTION: 'GETSTATIONARYDETAILS' },
  GET_All_PRODUCT_VARIANTS: { TYPE: 'SERVER', SERVICE: 'PRODUCTS', FUNCTION: 'GETAllPRODUCTVARIANTS' },
  GET_PAY_DETAILS: { TYPE: 'SERVER', SERVICE: 'PAYMENT', FUNCTION: 'GETPAYMENTDETAILS' },
  GET_PAYMENTSALE_DETAILS: { TYPE: 'SERVER', SERVICE: 'PAYMENT', FUNCTION: 'GETPAYMENTSALEDETAILS' },
  UPDATE_GST_LOCK: { TYPE: 'SERVER', SERVICE: 'GSTLOCK', FUNCTION: 'UPDATEGSTLOCK' },
  GET_GST_LOCK: { TYPE: 'SERVER', SERVICE: 'GSTLOCK', FUNCTION: 'GETGSTLOCK' },
  SALE_CANCELLATION: { TYPE: 'SERVER', SERVICE: 'SALECANCELLATION', FUNCTION: 'CANCELSALE' },
  EDIT_SALE: { TYPE: 'SERVER', SERVICE: 'REPORT', FUNCTION: 'EDITSALE' },
  REFUND_PAYMENT: { TYPE: 'SERVER', SERVICE: 'REFUND_PAYMENT_PROCESS', FUNCTION: 'REFUNDPAYMENT' },
  GET_CANCELLED_SALES: { TYPE: 'SERVER', SERVICE: 'OFFLINE', FUNCTION: 'CANCELLEDSALES' },
  ADMIN_SET_PASSWORD: { TYPE: 'SERVER', SERVICE: 'LOGIN', FUNCTION: 'ADMINSETPASSWORD' },
  DELETE_INVENTORY: { TYPE: 'SERVER', SERVICE: 'INVENTORY', FUNCTION: 'DELETEINVENTORY' },
  FETCH_PENDING_SALES: { TYPE: 'SERVER', SERVICE: 'SALES', FUNCTION: 'FETCHPENDINGSALES' },
  UPDATE_CHQ_DEP: { TYPE: 'SERVER', SERVICE: 'PAYMENT', FUNCTION: 'UPDATECHQDEP' },
  GET_DISTINCT_SOFT_KEY_REPO_NAMES: { TYPE: 'SERVER', SERVICE: 'KEYS', FUNCTION: 'GETDISTINCTSOFTKEYREPONAMES' },
  UNBLOCK_KEY: { TYPE: 'SERVER', SERVICE: 'KEYS', FUNCTION: 'UNBLOCKKEY' },
  GET_TRANSFERED_SALES: { TYPE: 'SERVER', SERVICE: 'REPORT', FUNCTION: 'GETTRANSFEREDSALES' },
  CANCEL_RECEIPT: { TYPE: 'SERVER', SERVICE: 'RECEIPTCANCEL', FUNCTION: 'CANCELRECEIPT' },
  EDIT_RECEIPT: { TYPE: 'SERVER', SERVICE: 'RECEIPTCANCEL', FUNCTION: 'EDITRECEIPT' },
  CONVERT_LEAD_SALE: { TYPE: 'SERVER', SERVICE: 'CONVERTLEADSALE', FUNCTION: 'CONVERTLEADSALE' },

  // shiprocket related api
  SHIPROCKET_REQUEST: { TYPE: 'SERVER', SERVICE: 'SHIPROCKETREQUEST', FUNCTION: 'SHIPROCKETREQUEST' }
};
