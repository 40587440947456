import { Title, Meta } from "@angular/platform-browser";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { SeoMaster } from "./../../shared/enums/seo.master";
import { ContainerMaster } from "./../../shared/enums/container.master";
import { Component, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { BaseContainer } from "./../base.container";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
declare var $: any;
declare var window: any;
@Component({
  selector: "app-contactus",
  templateUrl: "./contactus.component.html",
  styleUrls: ["./contactus.component.scss"]
})
export class ContactUsComponent extends BaseContainer implements OnInit {
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  status: boolean = true;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    meta: Meta,
    title: Title,
    componentManagerService: ComponentManagerService,
    @Inject(DOCUMENT) private doc: any
  ) {
    super(meta, title, componentManagerService);
  }

  ngOnInit() {
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',this.doc.URL)
  }

  setId() {
    return ContainerMaster.CONTACT_US;
  }
  setMetaTitle() {
    return SeoMaster.CONTACT_US.title;
  }
  setMetaTags() {
    return [
      { name: "keywords", content: SeoMaster.CONTACT_US.keyword },
      { name: "description", content: SeoMaster.CONTACT_US.description },
      { name: "og:title", content: SeoMaster.CONTACT_US.ogtitle },
      { name: "og:type", content: SeoMaster.CONTACT_US.ogtype },
      { name: "og_url", content: SeoMaster.CONTACT_US.ogurl },
      { name: "og:image:secure_url", content: SeoMaster.CONTACT_US.ogimage },
      { name: "og:description", content: SeoMaster.CONTACT_US.ogdescription },
      { name: "og:site_name", content: SeoMaster.CONTACT_US.ogsitename }
    ];
  }
  actionFromPlugin(params) {}
  ngAfterViewInit() {
   
    if (this.isBrowser) {
      if (!this.componentManagerService.isMobile) {
        $("#social-float").addClass("top-146");
        $("#social-float").addClass("fix-pos");
        $(document).scroll(function() {
          if (
            $("#social-float").offset().top + $("#social-float").height() >=
            $("#footer").offset().top
          ) {
            $("#social-float").addClass("positionabs");
            $("#social-float").removeClass("fix-pos");
          }
          if (
            $(document).scrollTop() + window.innerHeight <
            $("#footer").offset().top
          ) {
            $("#social-float").addClass("fix-pos");
            $("#social-float").removeClass("positionabs");
            if ($(window).scrollTop() > 150) {
              $("#social-float").addClass("top-73");
              $("#social-float").removeClass("top-146");
            } else {
              $("#social-float").addClass("top-146");
              $("#social-float").removeClass("top-73");
            }
          }
        });
      }
    }
  }
  clickEvent(){
    this.status = !this.status;
 }


 reciveStatus($event)
{
 this.status=$event;
}
}
