import { Title, Meta } from '@angular/platform-browser';
import { ComponentManagerService } from '../../services/component-manager.service';
import { SeoMaster } from '../../shared/enums/seo.master';
import { ContainerMaster } from '../../shared/enums/container.master';
import { Component, OnInit } from '@angular/core';
import { BaseContainer } from '../base.container';

@Component({
  selector: 'app-cloud-based-payroll',
  templateUrl: './cloud-based-payroll-component.html',
  styleUrls: ["./cloud-based-payroll-component.scss"]

})

export class CloudBasedPayrollComponent extends BaseContainer implements OnInit {
  status = true;
  constructor(meta: Meta, title: Title, componentManagerService: ComponentManagerService) {
    super(meta, title, componentManagerService);
  }
  ngOnInit() { }

  setId() {
    return ContainerMaster.CLOUDBASEDPAYROLL;
  }
  setMetaTitle() {
    return SeoMaster.CLOUDBASEDPAYROLL.title;
  }
  setMetaTags() {
    return ([

    ]);
  }
  actionFromPlugin(params) {
  }
  clickEvent() {
    this.status = !this.status;
  }


  reciveStatus(event) {
    this.status = event;
  }
}