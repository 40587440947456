import { PluginMaster } from "./../../shared/enums/plugin.master";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID } from "@angular/core";
import { BasePlugin } from "../base.plugin";
import { Router, NavigationEnd } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { Login } from "../../models/login.model";
import { ToasterService } from "../../services/utility-services/toaster.service";
declare var $: any;
declare var window: any;
declare var jQuery: any;
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["header.component.scss"]
})

export class HeaderComponent extends BasePlugin
  implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  login = new Login();
  forgotPswdForm: FormGroup;
  forgotemail: any;
  show = true;
  subTotal: any = 0;
  quantity: any;
  indexs: any;
  price: number;
  valueCurrent: any;
  showMe() {
    this.show = false;
  }
  showNavbar = true;
  isMob: any;
  route: any;
  currentValue: string;
  overlay = false;
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  showTopNavbar: boolean;
  hideSearch: boolean;
  subNavbar: boolean;
  flag = true;
  fieldErrorMsg;
  animateIn = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private formBuilder: FormBuilder,
    componentManagerService: ComponentManagerService,
    private router: Router,
    private toasterService: ToasterService
  ) {
    super(componentManagerService);
    this.isMob = this.componentManagerService.getDeviceDetails();
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentValue = event.url;
        //this.currentValue = this.currentValue.replace(/\\/g, '');
      }
    });
    if (this.isBrowser) {
      this.router.events.subscribe(event => {
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
    }
    // if (this.isBrowser) {
    //   (function ($) {
    //     $(document).ready(function () {
    //       var desktopViewed = $(document).width();
    //       //alert(desktopView); 

    //       if (desktopViewed > "768") {
    //         $(".navbar-nav li[data-toggle]").attr("data-toggle", "");
    //       } else {
    //         $(".navbar-nav li[data-toggle]").attr("data-toggle", "collapse");
    //       }
    //     });
    //   })(jQuery);
    // }
    // this.subTotalPrice();
    this.createForm();
    this.createForgotPswdForm();

  }

  setId() {
    return PluginMaster.HEADER;
  }

  actionFromSubPlugin(params) { }

  createForm() {
    this.loginForm = this.formBuilder.group({
      email: ["", [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(60),
        Validators.email
      ]
      ],
      password: ["", [Validators.required, Validators.minLength(8)]]
    });
  }

  isFieldValid(field: string) {
    if (field === 'forgotEmail') {
      return (
        !this.forgotPswdForm.get(field).valid && this.forgotPswdForm.get(field).touched
      );
    } else {
      return (
        !this.loginForm.get(field).valid && this.loginForm.get(field).touched
      );
    }
  }

  createForgotPswdForm() {
    this.forgotPswdForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          // Validators.minLength(1),
          // Validators.maxLength(60),
          Validators.email
        ]
      ]
    });
  }

  //#region Form Validation check
  // Highlights all the validations errors in a form
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  //#endregion

  onSubmit() {
    if (this.loginForm.valid) {
      // console.log(this.login);
      this.callDataJunction('USER_LOGIN', this.login).subscribe(res => {
        if (res.status) {
          this.componentManagerService.loggedIn = true;
          this.componentManagerService.user = res.data;
          this.toasterService.showToaster(res.message, 'toast-success');
          this.callDataJunction('SET_LOGIN', this.componentManagerService.user);
          if (this.isBrowser) {
            $('#exampleModalCenter').modal('hide')
          }
        } else {
          this.toasterService.showToaster(res.message, 'toast-error');
        }
      });
    } else {
      this.componentManagerService.loggedIn = false;
      this.validateAllFormFields(this.loginForm.value);
    }
  }

  onSend() {
    if (this.forgotPswdForm.valid) {
      this.callDataJunction('USER_FORGET_PASSWORD', this.forgotPswdForm.value).subscribe(res => {
        if (!!res && !!res.message) {
          this.toasterService.showToaster(res.message, 'toast-success');
          $('#exampleModalCenter').modal('hide')
        } else {
          this.toasterService.showToaster(res.message, 'toast-error');
        }


      });
    } else {
      this.validateAllFormFields(this.forgotPswdForm.value);
    }
  }

  toggleSidebar() {
    this.showNavbar = !this.showNavbar;
    this.overlay = !this.overlay;
  }
  ngAfterViewInit() {
    if (this.isBrowser) {
      const that = this;
      $(window).bind("scroll", function () {
        if ($(window).scrollTop() > 150) {
          if (that.currentValue === '/home' || that.currentValue === '/about-us' || that.currentValue === '/contact-us') {
            // that.animateIn = true;
            $("#header1").addClass("fixed-top navbar-fixed-section slideInDown");
            $(".navbar-brand img").addClass(
              "smallimg");
          } else {
            $(".navbar-brand img").addClass(
              "smallimg");
          }
        } else {
          $(".navbar-brand img").removeClass(
            "smallimg"
          );
          $("#header1").removeClass("fixed-top navbar-fixed-section slideInDown");
          that.animateIn = false;
        }
      });
      $(".toggle-password").click(function () {
        $(this).toggleClass("fa-eye fa-eye-slash");
        var input = $($(this).attr("toggle"));
        if (input.attr("type") == "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      });
    }
  }

  toggleNavbar() {
    $("#navbarTogglerDemo02").removeClass("show");
  }
  logout() {
    this.componentManagerService.dataJunction('CL', 'LOGOUT');
    if (this.isBrowser) {
      $('.bd-example-modal-sm').modal('hide')
    }
  }
  goCartNCheckout(params) {
    console.log(params);
    if (params === 'viewcart') {
      this.router.navigate(['/viewcart']);
      $('#cartModal').modal('hide');
    }
    else if (params === 'checkout') {
      this.router.navigate(['/checkout']);
      $('#cartModal').modal('hide');
    }
  }
  onChange(i) {
    this.componentManagerService.cartArr[i]['TOTAL'] = parseInt(this.componentManagerService.cartArr[i]['QTY']) * parseInt(this.componentManagerService.cartArr[i]['VAMT']);
    this.componentManagerService.subTotalPrice();
  }
}


// steave@gmail.com
// pAm1yiJMkw&K