//#region Import Calls
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { ShipRocketService } from './component-services/shipRocket.service';
import { ActionMaster } from '../shared/enums/action.master';
import { ComponentManagerModel } from './../models/container.model';
import { AuthenticationService } from './component-services/authenticationService.service';
import { CancelService } from './component-services/cancellation.service';
import { CRMService } from './component-services/crm.service';
import { CustomersService } from './component-services/customersService.service';
import { DeliveryService } from './component-services/deliveryService.service';
import { GstlockService } from './component-services/gstlockService.service';
import { InventoryService } from './component-services/inventory.service';
import { KeysService } from './component-services/keysService.service';
import { LeadService } from './component-services/leadService.service';
import { NotificationService } from './component-services/notificationService.service';
import { OfflineSalesService } from './component-services/offlineSalesService.service';
import { OperatorService } from './component-services/operatorService.service';
import { PaymentService } from './component-services/paymentService.service';
import { ProductService } from './component-services/product.service';
import { ReportService } from './component-services/reportService.service';
import { UserService } from './component-services/userService.service';
import { RouteManagerService } from './route-manager.service';

//#endregion


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class
  ComponentManagerService implements OnInit {
  loggedIn = false;
  adminLoggedIn = false;
  user: any;
  admin: any;
  title: any;
  displayScreen = {
    report: 0,
    payments: 0,
    offlinesales: 0,
    deliverystatus: 0,
    managekeys: 0,
    notification: 0,
    users: 0,
    operators: 0,
    changepassword: 0,
    productmanagement: 0,
    inventorymanagement: 0,
    gstlock: 0
  };
  operatorAccess: any = [];
  showProdDetail: any;
  cartArr = [];
  isLoading = false;
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  isMobile: boolean = this.getDeviceDetails();
  private componentManagerModel: ComponentManagerModel = new ComponentManagerModel();
  subTotal: any = 0;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    private cookieService: CookieService,
    private crmService: CRMService,
    private authenticationService: AuthenticationService,
    private customersService: CustomersService,
    private reportService: ReportService,
    private offlineSalesService: OfflineSalesService,
    private leadService: LeadService,
    private KeysService: KeysService,
    private paymentService: PaymentService,
    private deliveryService: DeliveryService,
    private userService: UserService,
    private notificationService: NotificationService,
    private routeManagerService: RouteManagerService,
    public router: Router,
    private productService: ProductService,
    private inventoryService: InventoryService,
    private operatorService: OperatorService,
    private gstlockService: GstlockService,
    private cancellationService: CancelService,
    private shipRocketService: ShipRocketService,


  ) {
  }

  ngOnInit() {
    this.getCookieDetails();
  }

  dataJunction(id: string, action: string, data?: any): Observable<any> {
    if (ActionMaster[action].TYPE === 'SERVER') {
      return this.callServerFunction(ActionMaster[action].FUNCTION, ActionMaster[action].SERVICE, data);
    } else if (ActionMaster[action].TYPE === 'UTILITY') {
      return this.callUtilityFunction(ActionMaster[action].FUNCTION, ActionMaster[action].SERVICE, data);
    } else if (ActionMaster[action].TYPE === 'GLOBAL') {
      this.callGlobalFunction(ActionMaster[action].FUNCTION, ActionMaster[action].SERVICE, data);
    } else if (ActionMaster[action].TYPE === 'ROUTE') {
      this.callRouteFunction(ActionMaster[action].FUNCTION, ActionMaster[action].SERVICE, data);
    } else if (ActionMaster[action].TYPE === 'CLIENT') {
      this.callClientFunction(ActionMaster[action].FUNCTION, ActionMaster[action].SERVICE, data);
    }
  }

  getDeviceDetails(): boolean {
    if (this.isBrowser) {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    } else {
      return false;
    }
  }

  callServerFunction(callname: string, serviceType: string, data: any): Observable<any> {
    this.componentManagerModel.params = data;
    switch (serviceType) {
      case 'REGISTER':
        switch (callname) {
          case 'CREATELEAD':
            return this.crmService.createLead(this.componentManagerModel);
            case 'READENQUIRY':
            return this.crmService.readEnquiry(this.componentManagerModel);
        }
        break;
      case 'LOGIN':
        switch (callname) {
          case 'USERLOGIN':
            return this.authenticationService.userLogin(this.componentManagerModel);
          case 'ADMINLOGIN':
            return this.authenticationService.adminLogin(this.componentManagerModel);
          case 'CHANGEADMINPASSWORD':
            return this.authenticationService.changeAdminPassword(this.componentManagerModel);
          case 'CHANGEUSERPASSWORD':
            return this.authenticationService.changeUserPassword(this.componentManagerModel);
          case 'USERREGISTER':
            return this.authenticationService.userRegister(this.componentManagerModel);
          case 'SENDTOKEN':
            return this.authenticationService.sendToken(this.componentManagerModel);
          case 'FORGETPASSWORDTOKEN':
            return this.authenticationService.forgetPasswordToken(this.componentManagerModel);
          case 'FORGETPASSWORD':
            return this.authenticationService.forgetPassword(this.componentManagerModel);
          case 'LOGOUT':
            this.loggedIn = false;
            this.resetCookieDetails();
            this.routeManagerService.callRoute('home');
          // case 'ADMINLOGOUT':
          //   this.adminLoggedIn = false;
          //   this.resetCookieDetails();
          //   this.routeManagerService.callRoute('/adminlogin');
          case 'ADMINFORGETPASSWORD':
            return this.authenticationService.adminForgetPassword(this.componentManagerModel);
          case 'USERFORGETPASSWORD':
            return this.authenticationService.userForgetPassword(this.componentManagerModel);
          case 'ADMINSETPASSWORD':
            return this.authenticationService.adminsSetPassword(this.componentManagerModel);
        }
        break;
      case 'CUSTOMER':
        switch (callname) {
          case 'GETCUSTOMERS':
            return this.customersService.getCustomers(this.componentManagerModel);
          case 'GETCUSTOMERSBYID':
            return this.customersService.getCustomersByID(this.componentManagerModel);
        }
        break;
      case 'PRODUCT':
        switch (callname) {
          case 'CHECKOUT':
            return this.leadService.checkOut(this.componentManagerModel);
        }
        break;

      case 'SAVETRANSPORT':
        switch (callname) {
          case 'SAVETRANSPORTDETAIL':
            return this.deliveryService.getTransport(this.componentManagerModel);
        }
        break;

      case 'PENDINGPAYMENT':
        switch (callname) {
          case 'GETPENDINGPAYMENT':
            return this.paymentService.pendingPayments(this.componentManagerModel);
        }
        break;

      case 'REPORT':
        switch (callname) {
          case 'GETLEADS':
            return this.reportService.getLeads(this.componentManagerModel);
          case 'GETLEADSBYDATE':
            return this.reportService.getLeadsByDate(this.componentManagerModel);
          case 'GETSALES':
            return this.reportService.getSales(this.componentManagerModel);
          case 'GETSALESBYDATE':
            return this.reportService.getSalesByDate(this.componentManagerModel);
          case 'GETSALESBYID':
            return this.reportService.getSalesByID(this.componentManagerModel);
          case 'GETLEADBYID':
            return this.reportService.getLeadByID(this.componentManagerModel);
          case 'EDITSALE':
            return this.reportService.editSale(this.componentManagerModel);
          case 'GETALLSALES':
            return this.reportService.getAllSales(this.componentManagerModel);
          case 'GETTRANSFEREDSALES':
            return this.offlineSalesService.fetchTransferedSales(this.componentManagerModel);
        }
        break;
      case 'OFFLINE':
        switch (callname) {
          // case 'GETREPORTS':
          //   return this.reportService.getReports(this.componentManagerModel);
          case 'CREATEOFFLINE':
            return this.offlineSalesService.createOfflineSales(this.componentManagerModel);
          case 'GETUSERS':
            return this.offlineSalesService.getUsers(this.componentManagerModel);
          case 'OFFLINESALES':
            return this.offlineSalesService.offlinesales(this.componentManagerModel);
          case 'CANCELLEDSALES':
            return this.cancellationService.getCanceledSales(this.componentManagerModel);
        }
        break;
      case 'KEYS':
        switch (callname) {
          case 'GETALLKEYS':
            return this.KeysService.getAllKeys(this.componentManagerModel);
          case 'GETACTIVEKEYS':
            return this.KeysService.getActiveKeys(this.componentManagerModel);
          case 'ADDKEYS':
            return this.KeysService.addKeys(this.componentManagerModel);
          case 'BLOCKEDKEYS':
            return this.KeysService.blockedKeys(this.componentManagerModel);
          case 'GETPRODUCTS':
            return this.KeysService.getProduct(this.componentManagerModel);
          case 'AVAILABLEKEYS':
            return this.KeysService.availableKeys(this.componentManagerModel);
          case 'GETDISTINCTSOFTKEYREPONAMES':
            return this.KeysService.getDistinctKeyNames(this.componentManagerModel);
          case 'UNBLOCKKEY':
            return this.KeysService.unblockKeys(this.componentManagerModel);
        }
        break;
      case 'PAYMENT':
        switch (callname) {
          case 'GETALLKEYS':
            return this.paymentService.paymentProcess(this.componentManagerModel);
          case 'LOADPAYMENTS':
            return this.paymentService.loadPayments(this.componentManagerModel);
          case 'GETPAYBYID':
            return this.paymentService.getPayByID(this.componentManagerModel);
          case 'SAVEOFFLINEPAYMENT':
            return this.paymentService.getOfflinePayment(this.componentManagerModel);
          case 'GETPAYMENTDETAILS':
            return this.paymentService.getPaymentDetails(this.componentManagerModel);
          case 'GETPAYMENTSALEDETAILS':
            return this.paymentService.getPaymentSaleDetails(this.componentManagerModel);
          case 'UPDATECHQDEP':
            return this.paymentService.updateChqDep(this.componentManagerModel)
        }
        break;

      case 'OFFLINEPAYMENT':
        switch (callname) {

        }
        break;

      case 'DELIVERY':
        switch (callname) {
          case 'GETDELIVERY':
            return this.deliveryService.getDelivery(this.componentManagerModel);
          case 'SETDELIVERY':
            return this.deliveryService.setDelivery(this.componentManagerModel);
        }
        break;
      case 'USER':
        switch (callname) {
          case 'GETUSERDATA':
            return this.userService.getUserData(this.componentManagerModel);
          case 'UPDATEUSER':
            return this.userService.updateUser(this.componentManagerModel);
          case 'GETORDERBYID':
            return this.userService.getOrderByID(this.componentManagerModel);
          case 'GETLASTTRANSDATE':
            return this.userService.getLastTransDate(this.componentManagerModel);
          case 'GETOPERATORS':
            return this.userService.getAllOperator(this.componentManagerModel);
          case 'UPDATEOPERATORSSCREENS':
            return this.userService.updateOperatorsScreens(this.componentManagerModel);
          case 'REGISTEROPERATOR':
            return this.userService.registerOperator(this.componentManagerModel);
          case 'GETOPERATORSCREENSBYID':
            return this.userService.getOperatorScreenByID(this.componentManagerModel);
          case 'UPDATEOPERATORSCREENSBYID':
            return this.userService.updateOperatorScreenByID(this.componentManagerModel);
        }
        break;
      case 'NOTIFICATION':
        switch (callname) {
          case 'NOTIFICATION':
            return this.notificationService.notification(this.componentManagerModel);
          case 'GETNOTIFICATION':
            return this.notificationService.getNotification(this.componentManagerModel);
          case 'SEENNOTIFICATION':
            return this.notificationService.seenNotification(this.componentManagerModel);
        }
        break;
      case 'ORDER':
        switch (callname) {
          case 'GETINVOICEDETAILS':
            return this.leadService.getInvoiceDetails(this.componentManagerModel);
          case 'UPLOADINVOICES3':
            return this.leadService.upload(this.componentManagerModel);
          case 'GETS3DETAILS':
            return this.leadService.getS3Details(this.componentManagerModel);
          case 'UPDATEINVOICEURL':
            return this.leadService.updateInvoiceURLHandler(this.componentManagerModel);
          case 'GETORDERDETAILS':
            return this.leadService.grtOrderDetails(this.componentManagerModel);

        }
        break;

      case 'PRODUCTS':
        switch (callname) {
          case 'GETSPECIFICPRODUCTS':
            return this.productService.getProducts(this.componentManagerModel);
          case 'GETALLPRODUCTS':
            return this.productService.getAllProduct(this.componentManagerModel);
          case 'GETSUBPRODUCTS':
            return this.productService.getsubproduct(this.componentManagerModel);
          case 'CREATESUBPRODUCTS':
            return this.productService.createsubproduct(this.componentManagerModel);
          case 'UPDATESUBPRODUCTS':
            return this.productService.updatesubproduct(this.componentManagerModel);
          case 'ADDPRODUCTS':
            return this.productService.addProduct(this.componentManagerModel);
          case 'UPDATEPRODUCTS':
            return this.productService.updateProduct(this.componentManagerModel);
          case 'GETSUBPRODUCTSVARIANTS':
            return this.productService.getsubproductvariants(this.componentManagerModel);
          case 'CREATESUBPRODUCTSVARIANTS':
            return this.productService.createsubproductvariants(this.componentManagerModel);
          case 'UPDATESUBPRODUCTSVARIANTS':
            return this.productService.updatesubproductvariants(this.componentManagerModel);
          case 'GETPRODUCTDETAILS':
            return this.productService.getProductDetails(this.componentManagerModel);
          case 'GETSTATIONARYDETAILS':
            return this.productService.getStationaryDetails(this.componentManagerModel);
          case 'GETAllPRODUCTVARIANTS':
            return this.productService.getAllProductVariants(this.componentManagerModel);
        }
        break;
      case 'INVENTORY':
        switch (callname) {
          case 'ADDINVENTORY':
            return this.inventoryService.addinventory(this.componentManagerModel);
          case 'GETINVENTORY':
            return this.inventoryService.getinventory(this.componentManagerModel);
          case 'UPDATEINVENTORY':
            return this.inventoryService.updateinventory(this.componentManagerModel);
          case 'DELETEINVENTORY':
            return this.inventoryService.deleteInventory(this.componentManagerModel);
        }
        break;
      case 'GSTLOCK':
        switch (callname) {
          case 'UPDATEGSTLOCK':
            return this.gstlockService.updateGstlock(this.componentManagerModel);
          case 'GETGSTLOCK':
            return this.gstlockService.getGstlock(this.componentManagerModel);


        }
        break;
      case 'OPERATOR':
        switch (callname) {
          case 'UPDATEOPERATORACCESS':
            return this.operatorService.updateScreensForUser(this.componentManagerModel);
        }
        break;
      case 'SALECANCELLATION':
        switch (callname) {
          case 'CANCELSALE':
            return this.cancellationService.cancelSale(this.componentManagerModel);
        }
        break;

      case 'REFUND_PAYMENT_PROCESS':
        switch (callname) {
          case 'REFUNDPAYMENT':
            return this.paymentService.refundPaymentProcess(this.componentManagerModel);
        }
        break;
      case 'SALES':
        switch (callname) {
          case 'FETCHPENDINGSALES':
            return this.offlineSalesService.fetchPendingSales(this.componentManagerModel);
        }
        break;
      case 'RECEIPTCANCEL':
        switch (callname) {
          case 'CANCELRECEIPT':
            return this.paymentService.paymentCancellation(this.componentManagerModel);
          case 'EDITRECEIPT':
            return this.paymentService.editPayment(this.componentManagerModel);
        }
        break;
      case 'CONVERTLEADSALE':
        switch (callname) {
          case 'CONVERTLEADSALE':
            return this.offlineSalesService.convertLeadToSale(this.componentManagerModel);
        }
        break;

      // shiprocket request
      case 'SHIPROCKETREQUEST':
        switch (callname) {
          case 'SHIPROCKETREQUEST':
            return this.shipRocketService.shipRocketRequest(this.componentManagerModel);
        }
        break;
      default:
        return;
    }
  }

  callUtilityFunction(callname: string, serviceType: string, data: any): Observable<any> {
    switch (serviceType) {
      case 'MOVEDATA':
        switch (callname) {
        }
        break;
      default:
        return;
    }
  }

  callRouteFunction(callname: string, serviceType: string, params: any) {
    switch (serviceType) {
      case 'ROUTE':
        switch (callname) {
          case 'CALLROUTE':
            if (!!params.queryParams) {
              this.routeManagerService.callRoute(params.data, params.queryParams);
            } else {
              this.routeManagerService.callRoute(params.data);
            }
            break;
        }
        break;
      default:
        return;
    }
  }

  callGlobalFunction(callname: string, serviceType: string, data: any) {
    switch (serviceType) {
      case 'GLOBAL':
        switch (callname) {
          case 'SETLOGIN':
            this.loggedIn = true;
            if (this.isBrowser && typeof (Storage) !== 'undefined') {
              localStorage.setItem('loggedIn', 'true');
              localStorage.setItem('UData', JSON.stringify(data));
              localStorage.setItem('UserType', 'customer');
            }
            break;
          case 'SETADMINLOGIN':
            this.adminLoggedIn = true;
            if (this.isBrowser && typeof (Storage) !== 'undefined') {
              localStorage.setItem('loggedIn', 'true');
              localStorage.setItem('AData', JSON.stringify(data));
              localStorage.setItem('UserType', 'admin');
            }
            break;
        }
    }
  }

  callClientFunction(callname: string, serviceType: string, data: any) {
    switch (serviceType) {
      case 'CLIENT':
        switch (callname) {
          case 'LOGOUT':
            this.loggedIn = false;
            this.resetCookieDetails();
            this.routeManagerService.callRoute('home');
            break;
          case 'ADMINLOGOUT':
            this.adminLoggedIn = false;
            this.resetCookieDetails();
            this.routeManagerService.callRoute('adminlogin');
        }
    }
  }

  getCookieDetails() {
    if (this.isBrowser) {
      const userFlag = localStorage.getItem('loggedIn');
      const usertype = localStorage.getItem('UserType');
      const id = this.cookieService.get('adminId');
      this.getScreenAccess(id).subscribe(res => {
        if (res.status) {
          this.operatorAccess = res.data;
          for (let i = 0; i < this.operatorAccess.length; i++) {

            this.operatorAccess[i]['SCREENACCESS'] = JSON.parse(this.operatorAccess[i]['SCREENACCESS']);
          }

        } else {
        }
      });
      if (userFlag) {
        if (usertype === 'customer') {
          this.loggedIn = true;
          this.user = localStorage.getItem('UData');
        } else if (usertype === 'admin') {
          this.adminLoggedIn = true;
          this.admin = JSON.parse(localStorage.getItem('AData'));
        }
      }
    }
  }

  getScreenAccess(admnId): Observable<any> {
    return this.dataJunction('00', 'GET_OPERATOR_SCREENS_BY_ID', { ID: admnId });
  }
  resetCookieDetails() {
    this.loggedIn = false;
    this.adminLoggedIn = false;
    this.user = undefined;
    this.admin = undefined;
    if (this.isBrowser) {
      localStorage.removeItem('AData');
      localStorage.removeItem('loggedIn');
      localStorage.removeItem('UData');
      localStorage.removeItem('UserType');

    }
  }
  subTotalPrice() {
    this.subTotal = 0;
    const cartLength = this.cartArr.length;
    for (let i = 0; i < cartLength; i++) {
      // tslint:disable-next-line:radix
      this.subTotal = parseInt(this.subTotal) + parseInt(this.cartArr[i]['TOTAL']);
    }
  }
  parseAmt(amt) {
    return parseFloat(amt).toFixed(2);
  }

  parseTotalAmt(amt) {
    return parseInt(amt).toFixed(2);
  }
}
