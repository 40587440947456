import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { tap, catchError } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class CustomersService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }
  private getCustomersUrl = 'api/customers';
  private getCustomersByIDUrl = 'api/getCustomerById';

  getCustomers(val: any) {
    return this.http.post(this.getCustomersUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getCustomers'))
    );
  }

  getCustomersByID(val: any) {
    return this.http.post(this.getCustomersByIDUrl, val, httpOptions).pipe(
      tap((val)),
      catchError(this.handleError('getCustomersByID'))
    );
  }

}
