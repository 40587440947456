import { Title, Meta } from "@angular/platform-browser";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { SeoMaster } from "./../../shared/enums/seo.master";
import { ContainerMaster } from "./../../shared/enums/container.master";
import { Component, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { BaseContainer } from "./../base.container";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
declare var $: any;
declare var window: any;
@Component({
  selector: "app-biometricmachine",
  templateUrl: "./biometricmachine.component.html",
  styleUrls: ["./biometricmachine.component.scss"]
})
export class BiometricMachineComponent extends BaseContainer implements OnInit {
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: any,
    meta: Meta,
    title: Title,
    componentManagerService: ComponentManagerService
  ) {
    super(meta, title, componentManagerService);
  }

  ngOnInit() {
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',this.doc.URL)
  }

  setId() {
    return ContainerMaster.BIOMETRIC;
  }
  setMetaTitle() {
    return SeoMaster.BIOMETRIC.title;
  }
  setMetaTags() {
    return [
      { name: "keywords", content: SeoMaster.BIOMETRIC.keyword },
      { name: "description", content: SeoMaster.BIOMETRIC.description },
      { name: "og:title", content: SeoMaster.BIOMETRIC.ogtitle },
      { name: "og:type", content: SeoMaster.BIOMETRIC.ogtype },
      { name: "og_url", content: SeoMaster.BIOMETRIC.ogurl },
      { name: "og:image:secure_url", content: SeoMaster.BIOMETRIC.ogimage },
      { name: "og:description", content: SeoMaster.BIOMETRIC.ogdescription },
      { name: "og:site_name", content: SeoMaster.BIOMETRIC.ogsitename }
    ];
  }
  actionFromPlugin(params) {}
  ngAfterViewInit() {
    if (this.isBrowser) {
      const that = this;
      $(window).bind("scroll", function() {
        if ($(window).scrollTop() > 150) {
          $("#bio").addClass(
            "fixed-heading navbar-fixed-section slideInDown");
          $('.fixed-heading h1').addClass('text-right');
          $('#bio img').removeClass("d-none");
          $("#bio img").css("height", "50px");
          $('#bio').css({"display": "flex", "padding": "0 120px"});
        } else {
          $("#bio").removeClass(
            "fixed-heading navbar-fixed-section slideInDown");
          $('#bio h1').removeClass('text-right');
          $('#bio img').addClass("d-none");
          $("#bio img").css("height", "auto");
          $('#bio').css({"display": "block", "padding": "0"});
        }
      });

      if (!this.componentManagerService.isMobile) {
        $("#social-float").addClass("top-146");
        $("#social-float").addClass("fix-pos");
        $(document).scroll(function() {
          if (
            $("#social-float").offset().top + $("#social-float").height() >=
            $("#footer").offset().top
          ) {
            $("#social-float").addClass("positionabs");
            $("#social-float").removeClass("fix-pos");
          }
          if (
            $(document).scrollTop() + window.innerHeight <
            $("#footer").offset().top
          ) {
            $("#social-float").addClass("fix-pos");
            $("#social-float").removeClass("positionabs");
            if ($(window).scrollTop() > 150) {
              $("#social-float").addClass("top-73");
              $("#social-float").removeClass("top-146");
            } else {
              $("#social-float").addClass("top-146");
              $("#social-float").removeClass("top-73");
            }
          }
        });
      }
    }
  }
}
