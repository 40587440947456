import { Title, Meta } from '@angular/platform-browser';
import { ComponentManagerService } from './../../services/component-manager.service';
import { SeoMaster } from './../../shared/enums/seo.master';
import { ContainerMaster } from './../../shared/enums/container.master';
import { Component, Inject, OnInit } from '@angular/core';
import { BaseContainer } from './../base.container';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ["./aboutus.component.scss"]

})

export class AboutUsComponent extends BaseContainer implements OnInit {

  status: boolean = true;
  constructor(@Inject(DOCUMENT) private doc: any, meta: Meta, title: Title, componentManagerService: ComponentManagerService) {
    super(meta, title, componentManagerService);
  }

  ngOnInit() { 
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',SeoMaster.ABOUT_US.canonical)
  }

  setId() {
    return ContainerMaster.ABOUT_US;
  }
  setMetaTitle() {
    return SeoMaster.ABOUT_US.title;
  }
  setMetaTags() {
    return ([
      { name: 'keywords', content: SeoMaster.ABOUT_US.keyword },
      { name: 'description', content: SeoMaster.ABOUT_US.description },
      { name: 'og:title', content: SeoMaster.ABOUT_US.ogtitle },
      { name: 'og:type', content: SeoMaster.ABOUT_US.ogtype },
      { name: 'og_url', content: SeoMaster.ABOUT_US.ogurl },
      { name: 'og:image:secure_url', content: SeoMaster.ABOUT_US.ogimage },
      { name: 'og:description', content: SeoMaster.ABOUT_US.ogdescription },
      { name: 'og:site_name', content: SeoMaster.ABOUT_US.ogsitename }
    ]);
  }
  actionFromPlugin(params) {
  }

  reciveStatus(event) {
    // console.log(event);
    this.status = event;
  }
}