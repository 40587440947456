import { Title, Meta } from '@angular/platform-browser';
import { ComponentManagerService } from './../../services/component-manager.service';
import { SeoMaster } from './../../shared/enums/seo.master';
import { ContainerMaster } from './../../shared/enums/container.master';
import { Component, Inject, OnInit } from '@angular/core';
import { BaseContainer } from './../base.container';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-gstsoftware',
  templateUrl: './gstsoftware.component.html'
})

export class GstsoftwareComponent extends BaseContainer implements OnInit {
  constructor(@Inject(DOCUMENT) private doc: any,
  meta: Meta, title: Title, componentManagerService: ComponentManagerService) {
      super(meta, title, componentManagerService);
  }

  ngOnInit() {
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',this.doc.URL)
   }

  setId() {
    return ContainerMaster.GST_SOFTWARE;
  }
  setMetaTitle() {
    return SeoMaster.GST_SOFTWARE.title;
  }
  setMetaTags() {
    return ([
      { name: 'keywords', content: SeoMaster.GST_SOFTWARE.keyword },
      { name: 'description', content: SeoMaster.GST_SOFTWARE.description },
      { name: 'og:title', content: SeoMaster.GST_SOFTWARE.ogtitle },
      { name: 'og:type', content: SeoMaster.GST_SOFTWARE.ogtype },
      { name: 'og_url', content: SeoMaster.GST_SOFTWARE.ogurl },
      { name: 'og:image:secure_url', content: SeoMaster.GST_SOFTWARE.ogimage },
      { name: 'og:description', content: SeoMaster.GST_SOFTWARE.ogdescription },
      { name: 'og:site_name', content: SeoMaster.GST_SOFTWARE.ogsitename }
    ]);
  }
  actionFromPlugin(params) {
  }
}