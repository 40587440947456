import { PluginMaster } from "./../../shared/enums/plugin.master";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { Component, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { BasePlugin } from "../base.plugin";
import { isPlatformBrowser } from "@angular/common";
declare var $:any;
declare var window: any;
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["footer.component.scss"]
})
export class FooterComponent extends BasePlugin implements OnInit {
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    componentManagerService: ComponentManagerService
  ) {
    super(componentManagerService);
  }

  ngOnInit() {
    if (this.isBrowser) {
      $(window).scroll(function() {
        if ($(this).scrollTop() >= 50) {
          // If page is scrolled more than 50px
          $("#return-to-top").fadeIn(200); // Fade in the arrow
        } else {
          $("#return-to-top").fadeOut(200); // Else fade out the arrow
        }
      });
      $("#return-to-top").click(function() {
        // When arrow is clicked
        $("body,html").animate(
          {
            scrollTop: 0 // Scroll to top of body
          },
          500
        );
      });
    }
  }

  setId() {
    return PluginMaster.FOOTER;
  }

  actionFromSubPlugin(params) {}
}
