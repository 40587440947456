import { PluginMaster } from "./../../shared/enums/plugin.master";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID } from "@angular/core";
import { BasePlugin } from "../base.plugin";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { ToasterService } from "../../services/utility-services/toaster.service";
import { CustomValidators } from "../../shared/directives/customvalidator";
declare var $: any;

@Component({
  selector: "app-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["forget-password.component.scss"]
})

export class ForgetPasswordComponent extends BasePlugin implements OnInit {
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  token: any;
  show = false;
  forgetPwd: FormGroup;
  forgetPasscode = {
    newPassword: '',
    confirmPassword: ''
  };
  userEmail: any;
  userID: any;
  tokenDate: any;


  constructor(@Inject(PLATFORM_ID) private platformId: Object, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, componentManagerService: ComponentManagerService,
    private router: Router, private toasterService: ToasterService
  ) {
    super(componentManagerService);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log('this.activatedRoute', this.activatedRoute);
      console.log('params', params);
      this.token = { ...params.keys, ...params };
    
      if (!!this.token) {
        this.sendToken();
        this.userID = !!this.token['uid']?  this.token['uid'] : '';
        this.userEmail = !!this.token['mail']?  this.token['mail'] : '';
      } else {
        this.navigateTo('home');
      }
    });
    this.createForm();
  }

  setId() {
    return PluginMaster.FORGET_PASSWORD;
  }

  createForm() {
    this.forgetPwd = this.formBuilder.group({
      newpasscode: ['', Validators.required],
      confirmPasscode: ['', Validators.required]
    });
  }

  actionFromSubPlugin(params) { }

  isFieldValid(field: string) {
    return (!this.forgetPwd.get(field).valid && this.forgetPwd.get(field).touched);
  }


  //#region Form Validation check
  // Highlights all the validations errors in a form
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  //#endregion

  onSubmit() {
    
    if (this.forgetPwd.valid) {
      let action = this.activatedRoute['url']['value'][1]['path'] === 'fp' ? 'ADMIN_SET_PASSWORD' : 'FORGET_PASSWORD'
      console.log('action', action);
      this.callDataJunction(action, { ...this.forgetPasscode, ID: this.userID, EMAIL: this.userEmail }).subscribe(res => {
        if (res.status) {
          this.toasterService.showToaster(res.message, 'toast-success');
          this.navigateTo('home');
        } else {
          this.toasterService.showToaster(res.message, 'toast-error');
        }
      });
    } else {
      this.validateAllFormFields(this.forgetPwd.value);
    }
  }

  sendToken() {
    this.callDataJunction('FORGET_PASSWORD_TOKEN', { TOKEN: this.token }).subscribe(res => {
      if (res.status) {
        this.show = true;
        this.userEmail = res.data.EMAIL;
        this.userID = res.data.ID;
        this.toasterService.showToaster(res.message, 'toast-success');
      } else {
        this.navigateTo('home');
        this.toasterService.showToaster(res.message, 'toast-error');
      }
    });
  }
}

// https://localhost:4200/transactional/confirm-your-taxprint-account/?token=eyJ0eXAiOiJKV1Qi.eyJrZXkiOi.eUiabuiKv