import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComponentManagerService } from '../../services/component-manager.service';
import { errorMessages, regExps } from '../../shared/directives/customvalidator';
import { BasePlugin } from '../base.plugin';
import { RouteManagerService } from './../../services/route-manager.service';
import { PluginMaster } from './../../shared/enums/plugin.master';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-stepperContent',
  templateUrl: './stepperContent.html',
  styleUrls: ['./stepperContent.html'],
})

export class StepperContentComponent extends BasePlugin implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  disabled = true;
  proceedDisabled = true;
  isLinear = false;
  errors = errorMessages;
  hide = false;

  @Input() DialogType: any;
  constructor(public componentManagerService: ComponentManagerService,
    private fb: FormBuilder, public routeManagerService: RouteManagerService) {
    super(componentManagerService);
  }

  ngOnInit() {
    this.validateForm();
  }
  validateForm() {
    this.firstFormGroup = this.fb.group({
      chck: ['', Validators.required]
    });

    this.fifthFormGroup = this.fb.group({
      shareName: ['', [Validators.required, Validators.minLength(1)]],
      shareEmail: ['', [Validators.required, Validators.email]],
      shareContact: ['', [Validators.required, Validators.pattern(regExps.number_)]],
      shareAdd1: ['', Validators.required],
      shareAdd2: ['', Validators.required],
      shareLandmark: [''],
      shareCity: ['', Validators.required],
      sharePin: ['', Validators.required],
      shareState: ['', Validators.required],
      shareGst: [''],
      billName: ['', [Validators.required, Validators.minLength(1)]],
      billEmail: ['', [Validators.required, Validators.email]],
      billContact: ['', [Validators.required, Validators.pattern(regExps.number_)]],
      billAdd1: ['', Validators.required],
      billAdd2: ['', Validators.required],
      billLandmark: [''],
      billCity: ['', Validators.required],
      billPin: ['', Validators.required],
      billState: ['', Validators.required],
    });
  }
  openform() {
    this.routeManagerService.callRoute('getStartedForm');
  }
  toggleHide() {
    this.hide = !this.hide;
  }
  toggle() {
    this.disabled = !this.disabled;
  }
  protoggle() {
    this.proceedDisabled = !this.proceedDisabled;
  }
  proceed(params1, params2) {
    console.log(params1);
    console.log(params2);
  }
  setId() {
    return PluginMaster.STEPPERCONTENT;
  }

  actionFromSubPlugin(params) {
  }
}
