import { Title, Meta } from "@angular/platform-browser";
import { ComponentManagerService } from "./../../services/component-manager.service";
import { SeoMaster } from "./../../shared/enums/seo.master";
import { ContainerMaster } from "./../../shared/enums/container.master";
import { Component, OnInit, Inject, PLATFORM_ID, ViewChild } from "@angular/core";
import { BaseContainer } from "./../base.container";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { SignupFormComponent } from "../../plugin/signup-form/signup-form.component";
import { ProdSubComponent } from "../../plugin/saleNote/prodSubYMM.component";
import { ToasterService } from "../../services/utility-services/toaster.service";
declare var Instamojo: any;
declare var $: any;
declare var window: any;
const prod = {
  single: {
    ID: 'PROD40',
    QTY: 1,
    productType: 'SOFTWARE',
    period: undefined,
    PROD_CONFIG_DISP: '#PROD_SUB_YFY',
    licenseType: 'NEW'
  },
  multiple: {
    ID: 'PROD39',
    QTY: 1,
    productType: 'SOFTWARE',
    period: undefined,
    PROD_CONFIG_DISP: '#PROD_SUB_YFY',
    licenseType: 'NEW'
  }
}

@Component({
  selector: "app-xbrl",
  templateUrl: "./xbrl.component.html",
  styleUrls: ["./xbrl.component.scss"]
})
export class XbrlComponent extends BaseContainer implements OnInit {
  [x: string]: any;
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  payLink: string;
  buyOption: string;
  single: string = "single";
  multiple: string = "multiple";
  rates: any;
  prodDetails: any;
  period: string;
  licenseType: string;
  tdsDetails: any = [];
  finalPrice: any = [];
  variantName: any = [];
  subprodName: any = [];
  mprice: any;
  sprice: any;
  tdsProductid: any = [];
  prodObj = {};
  dropDownText = 'Select';

  productPrice: any;
  @ViewChild(SignupFormComponent) public customerForm: SignupFormComponent;
  @ViewChild(ProdSubComponent) public prodPeriodForm: ProdSubComponent;
  newsprice: any;
  newmprice: any;
  renewsprice: any;
  renewmprice: any;
  xbrlDetails: any;
  prodCheckoutArr = [];
  status: boolean = true;
  prodCheckoutObj = {
    QTY: 1,
    PID: null,
    SPID: null,
    VID: '',
    VAMT: null,
    PTYPE: null,
    VAR_NAME: null,
    SUBGROUP: null,
    SUB_PROD_CONFIG_DISP: null,
    GROUP_TYPE: null
  };
  prodObjCheckout: any;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    meta: Meta,
    title: Title,
    componentManagerService: ComponentManagerService, 
    @Inject(DOCUMENT) private doc: any,
    private toasterService: ToasterService
  ) {
    super(meta, title, componentManagerService);
    //default selected buyOption
    this.buyOption = this.multiple;
    this.rates = {
      single:
        "https://www.instamojo.com/@taxprint/leb466fe4fa994e98bdbee8b498ac95a5/",
      multiple:
        "https://www.instamojo.com/@taxprint/lc99f3df3495c408c9224d3323f0a79ec/"
    };
    window['that'] = this;
  }

  ngOnInit() {
    //by default multiple user
    this.prodDetails = prod.multiple;
    this.getProductDetails();
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',this.doc.URL)
  }

  setId() {
    return ContainerMaster.XBRL;
  }
  setMetaTitle() {
    return SeoMaster.XBRL.title;
  }
  setMetaTags() {
    return [
      { name: "keywords", content: SeoMaster.XBRL.keyword },
      { name: "description", content: SeoMaster.XBRL.description },
      { name: "og:title", content: SeoMaster.XBRL.ogtitle },
      { name: "og:type", content: SeoMaster.XBRL.ogtype },
      { name: "og_url", content: SeoMaster.XBRL.ogurl },
      { name: "og:image:secure_url", content: SeoMaster.XBRL.ogimage },
      { name: "og:description", content: SeoMaster.XBRL.ogdescription },
      { name: "og:site_name", content: SeoMaster.XBRL.ogsitename }
    ];
  }
  actionFromPlugin(params) { }

  ngAfterViewInit() {
    /* Configuring Handlers */
    Instamojo.configure({
      handlers: {
        onOpen: this.onOpenHandler,
        onClose: this.onCloseHandler,
        onSuccess: this.onPaymentSuccessHandler,
        onFailure: this.onPaymentFailureHandler
      }
    });
    // if (this.isBrowser) {
    //   const that = this;
    //   $(window).bind("scroll", function () {
    //     if ($(window).scrollTop() > 150) {
    //       $("#xbrl").addClass(
    //         "fixed-heading navbar-fixed-section slideInDown");
    //       $('.fixed-heading h1').addClass('text-right');
    //       $('#xbrl img').removeClass("d-none");
    //       $("#xbrl img").css("height", "50px");
    //       $('#xbrl').css({ "display": "flex", "padding": "0 120px" });
    //     } else {
    //       $("#xbrl").removeClass(
    //         "fixed-heading navbar-fixed-section slideInDown");
    //       $('#xbrl h1').removeClass('text-right');
    //       $('#xbrl img').addClass("d-none");
    //       $("#xbrl img").css("height", "auto");
    //       $('#xbrl').css({ "display": "block", "padding": "0" });
    //     }
    //   });

    //   if (!this.componentManagerService.isMobile) {
    //     $("#social-float").addClass("top-146");
    //     $("#social-float").addClass("fix-pos");
    //     $(document).scroll(function () {
    //       if (
    //         $("#social-float").offset().top + $("#social-float").height() >=
    //         $("#footer").offset().top
    //       ) {
    //         $("#social-float").addClass("positionabs");
    //         $("#social-float").removeClass("fix-pos");
    //       }
    //       if (
    //         $(document).scrollTop() + window.innerHeight <
    //         $("#footer").offset().top
    //       ) {
    //         $("#social-float").addClass("fix-pos");
    //         $("#social-float").removeClass("positionabs");
    //         if ($(window).scrollTop() > 150) {
    //           $("#social-float").addClass("top-73");
    //           $("#social-float").removeClass("top-146");
    //         } else {
    //           $("#social-float").addClass("top-146");
    //           $("#social-float").removeClass("top-73");
    //         }
    //       }
    //     });
    //   }
    // }
  }

  onOpenHandler() {
    console.log("Payments Modal is Opened");
  }

  onCloseHandler() {
    console.log("Payments Modal is Closed");
  }

  onPaymentSuccessHandler(response) {
    console.log("Payment Success Response", response);
    $.ajax({
      type: "POST",
      cache: false,
      url: "api/purchasePaymentResult",
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({
        transID: window['transID'], pgPaymentID: response.paymentId, pgPayClientRes: response,
        paymentID: window['paymentID']
      }),
      success: function (response) {
        console.log('response');
        console.log(response);
        //window['transID'] = undefined;
        //window['paymentID'] = undefined;
        if (window['that'].isBrowser) {
          $('.modal-backdrop').css({ 'display': 'none' });
        }
        window['that'].navigateTo('thankyou', window['transID']);
      }
    });

    if (response.status.toLowerCase() == "success") {
    } else {
      alert("payment failed");
    }
    //this.processPayment(response.paymentId||undefined, JSON.stringify(response));
  }

  onPaymentFailureHandler(error) {
    console.log("Payment Failure Response", error);
    alert("Error in payment");
  }

  processPayment(pgPayID, paymentDetailsJSONStr) {
    // call route of api/purchasePaymentResult
    /*pgPaymentID: pgPayID,
          pgPayClientRes: paymentDetailsJSONStr, cpsf:1, tc: 'PG', payLink: this.payLink*/
  }

  initPayment() {
    this.payLink = this.rates[this.buyOption];
    if (!this.payLink) {
      alert("Please contact on support number");
    } else {
      Instamojo.open(this.payLink);
    }
  }

  buyNow(params, qtyID: string) {
    if (!this.componentManagerService.loggedIn && this.isBrowser) {
      if (params == 'single') {
        this.prodDetails = prod.single;
        $('#signUp').modal('show');
      } else {
        this.prodDetails = prod.multiple;
        $('#signUp').modal('show');
      }
      this.prodCheckoutObj['DELIVERY_MODE'] = 'IN_HAND';
      this.prodCheckoutObj['DISCOUNT'] = 0;
      this.prodDetails.QTY = $(`#${qtyID}`).val();
      //this.prodDetails.period = this.prodPeriodForm.duration;//this.period;
      this.customerForm.ngOnChanges();
      this.prodDetails.licenseType = this.licenseType;
    }
  }

  buyProduct() {

  }

  setPayID(event) {
    window['transID'] = event.SALE_ID;
    // window['transID'] = event.tranID;
    window['paymentID'] = event.paymentID;
  }

  setPeriod(period) {
    this.period = period;
  }

  setLicenseType(licenseType) {
    this.licenseType = licenseType;
  }
  reciveStatus($event) {
    this.status = $event;
  }
  getProductDetails() {
    this.callDataJunction('GET_PRODUCT_DETAILS', { ID: 17 }).subscribe(res => {
      this.tdsDetails = res.data;
      this.xbrlDetails = res.data;
      const tdsObj = { new: [], renew: [], amc: [], none: [] };
      this.tdsDetails.forEach(e => {
        let obj: any;
        if (e['GROUP_TYPE'] === 'NEW') {
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: e['VARIANT_NAME'], TYPE: e['GROUP_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.new.push(obj);
        } else if (e['GROUP_TYPE'] === 'RENEW') {
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: e['VARIANT_NAME'], TYPE: e['GROUP_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.renew.push(obj);
        } else if (e['GROUP_TYPE'] === 'AMC') {
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: e['VARIANT_NAME'], TYPE: e['GROUP_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.amc.push(obj);
        } else if (e['GROUP_TYPE'] === null) {
          obj = { PID: e['PROD_ID'], SPID: e['SUB_PROD_ID'], VID: e['VARIANT_ID'], PRICE: Math.round(e['FINAL_PRICE']), DISPLAY_NAME: e['VARIANT_NAME'], TYPE: e['GROUP_TYPE'], PROD_SEQ: e['PROD_SEQ'] };
          tdsObj.none.push(obj);
        }
      });
      for (let t in tdsObj) {
        tdsObj[t].sort((a, b) => {
          return a.PROD_SEQ - b.PROD_SEQ;
        });
      }
      this.tdsDetails = tdsObj;
      if (!!this.tdsDetails['new'] && this.tdsDetails['new'].length > 0) {
        this.productPrice = this.tdsDetails['new'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['new'][0]['VID'];
        this.prodCheckoutObj.PID = this.tdsDetails['new'][0]['PID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['new'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['new'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['new'][0]['SPID'];
        this.prodCheckoutObj.SUBGROUP = this.tdsDetails['new'][0]['SUB_GROUP'];
        this.prodCheckoutObj.SUB_PROD_CONFIG_DISP = this.tdsDetails['new'][0]['SUB_PROD_CONFIG_DISP'];
        this.prodCheckoutObj.GROUP_TYPE = this.tdsDetails['new'][0]['GROUP_TYPE'];
      } else if (!!this.tdsDetails['renew'] && this.tdsDetails['renew'].length > 0) {
        this.productPrice = this.tdsDetails['renew'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['renew'][0]['VID'];
        this.prodCheckoutObj.PID = this.tdsDetails['renew'][0]['PID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['renew'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['renew'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['renew'][0]['SPID'];
        this.prodCheckoutObj.SUBGROUP = this.tdsDetails['renew'][0]['SUB_GROUP'];
        this.prodCheckoutObj.SUB_PROD_CONFIG_DISP = this.tdsDetails['renew'][0]['SUB_PROD_CONFIG_DISP'];
        this.prodCheckoutObj.GROUP_TYPE = this.tdsDetails['renew'][0]['GROUP_TYPE'];
      } else if (!!this.tdsDetails['amc'] && this.tdsDetails['amc'].length > 0) {
        this.productPrice = this.tdsDetails['amc'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['amc'][0]['VID'];
        this.prodCheckoutObj.PID = this.tdsDetails['amc'][0]['PID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['amc'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['amc'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['amc'][0]['SPID'];
        this.prodCheckoutObj.SUBGROUP = this.tdsDetails['amc'][0]['SUB_GROUP'];
        this.prodCheckoutObj.SUB_PROD_CONFIG_DISP = this.tdsDetails['amc'][0]['SUB_PROD_CONFIG_DISP'];
        this.prodCheckoutObj.GROUP_TYPE = this.tdsDetails['amc'][0]['GROUP_TYPE'];
      } else if (!!this.tdsDetails['none'] && this.tdsDetails['none'].length > 0) {
        this.productPrice = this.tdsDetails['none'][0]['PRICE'];
        this.prodCheckoutObj.VID = this.tdsDetails['none'][0]['VID'];
        this.prodCheckoutObj.PID = this.tdsDetails['none'][0]['PID'];
        this.prodCheckoutObj.VAR_NAME = this.tdsDetails['none'][0]['DISPLAY_NAME'];
        this.prodCheckoutObj.VAMT = this.tdsDetails['none'][0]['PRICE'];
        this.prodCheckoutObj.SPID = this.tdsDetails['none'][0]['SPID'];
        this.prodCheckoutObj.SUBGROUP = this.tdsDetails['none'][0]['SUB_GROUP'];
        this.prodCheckoutObj.SUB_PROD_CONFIG_DISP = this.tdsDetails['none'][0]['SUB_PROD_CONFIG_DISP'];
        this.prodCheckoutObj.GROUP_TYPE = this.tdsDetails['none'][0]['GROUP_TYPE'];
      }
      this.getProductValues();

    }, err => {
      console.log(err);
    });

  }
  getObjectKeys(obj) {
    return Object.keys(obj);
  }
  onChangeVariant(event) {
    this.newsprice = this.prodObj[event.target.value]['xbrlnewsingleuser'];
    this.newmprice = this.prodObj[event.target.value]['xbrlnewmultiuser'];
    this.renewsprice = this.prodObj[event.target.value]['xbrlrenewsingleuser'];
    this.renewmprice = this.prodObj[event.target.value]['xbrlrenewmultiuser'];
  }
  getProductValues() {
    const arr = this.xbrlDetails.filter(x => x.VARIANT_ID === parseFloat(this.prodCheckoutObj.VID));
    this.dropDownText = arr[0]['GROUP_TYPE'].toLowerCase();
    this.productPrice = arr[0]['FINAL_PRICE'].toFixed(2);
    this.productPrice = Math.round(this.productPrice);
    this.prodCheckoutObj.PID = arr[0]['PROD_ID'];
    this.prodCheckoutObj.SPID = arr[0]['SUB_PROD_ID'];
    this.prodCheckoutObj.VAMT = arr[0]['FINAL_PRICE'];
    this.prodCheckoutObj.PTYPE = arr[0]['PRODUCT_TYPE'];
    this.prodCheckoutObj.VAR_NAME = arr[0]['VARIANT_NAME'];
    this.prodCheckoutObj.SUBGROUP = arr[0]['SUB_GROUP'];
    this.prodCheckoutObj.SUB_PROD_CONFIG_DISP = arr[0]['SUB_PROD_CONFIG_DISP'];
    this.prodCheckoutObj.GROUP_TYPE = arr[0]['GROUP_TYPE'];
  }
  addCart() {
    // tslint:disable-next-line:radix
    this.prodCheckoutObj['SUBGROUPS'] = null;
    this.prodCheckoutObj['DELIVERY_MODE'] = 'IN_HAND';
    this.prodCheckoutObj['DISCOUNT'] = 0;
    this.prodCheckoutObj['TOTAL'] = (this.prodCheckoutObj.VAMT * this.prodCheckoutObj.QTY);
    const proObj = { ...this.prodCheckoutObj };
    this.componentManagerService.cartArr.push(proObj);
    this.componentManagerService.subTotalPrice();
    this.toasterService.showToaster(`${this.prodCheckoutObj.VAR_NAME} added to cart`, 'toast-success');
  }
}
