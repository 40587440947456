export class Login {
  ID: number;
  LOGIN_TYPE: string;
  EMAIL: string;
  PASSCODE: string;
  TOKEN: string;
  ACTIVE: number;

  constructor() {
    this.LOGIN_TYPE = 'EMAIL';
  }
}

